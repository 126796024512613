import {combineReducers} from "redux";
import {
    User,
    orderReturns,
    phoneNumberLogin,
    phoneNumberVerify,
    //Phone Verify on Carts 
    phoneVerify,   
    //   
    registerUser,
    logoutUser,
    resetPasswords,
    verifyPasswords,
    getCategories,
    getCategoryProducts,
    getSingleProduct,
    getProducts,
    FilterProducts,
    getRelatedProducts,
    getWishLists,
    addWishLists,
    addCarts,
    customCarts,
    getCarts,
    getCartCounts,
    updateCarts,
    removeCarts,
    productSpecifications,
    searchs,
    hotDeals,
    buyNows,
    getBanner,
    getProfiles,
    updateProfiles,
    addUserAddress,
    deleteUserAddress,
    getUserAddress,
    getOrders,
    getOrdersDetails,
    addReviews,
    getAllReviews,
    deleteReviews,
    myReviews,
    getReviews,
    checkouts,
    payNows,
    faqs,
    bulkOrderEnquiry,
    getWelcomeMessages,
    getOrderReturns,
    getReturnDetails
    

} from "./Reducer";

export default combineReducers({
    User,
    phoneNumberLogin,
    phoneNumberVerify,
    phoneVerify,
    registerUser,
    logoutUser,
    resetPasswords,
    verifyPasswords, 
    getAllReviews,
    getCategories,
    getCategoryProducts,
    getSingleProduct,
    getProducts,
    FilterProducts,
    getRelatedProducts,
    getWishLists,
    addWishLists,
    addCarts,
    customCarts,
    getCarts,
    getCartCounts,
    updateCarts,
    removeCarts,
    productSpecifications,
    searchs,
    hotDeals,
    buyNows,
    getBanner,
    getProfiles,
    updateProfiles,
    addUserAddress,
    deleteUserAddress,
    getUserAddress,
    getOrders,
    getOrdersDetails,
    addReviews,
    deleteReviews,
    myReviews,
    getReviews,
    checkouts,
    payNows,
    faqs,
    bulkOrderEnquiry,
    getWelcomeMessages,
    orderReturns,
    getOrderReturns,
    getReturnDetails


});