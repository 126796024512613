import homeStyle from "../Components/Home.module.css";

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

import plus from "../Assets/plus.png";
import minus from "../Assets/minus.png";

import {
  getProducts,
  getCategoryProducts,
  getSingleProduct,
  addCarts,
  addWishLists,
  FilterProducts,
} from "../Redux/Action";

const Product = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // some constants
  const Token = localStorage.getItem("AuthTok");

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#ff3e3e",
  };

  // state declarations
  const [load, setLoad] = useState(true);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [changeVariant, setChangeVariant] = useState(0);
  const [change, setChange] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [message, setMessage] = useState("");
  const [sorting, setSorting] = useState("");
  const [render, setRender] = useState(false);

  const [productData, setProductData] = useState();
  const [priceFilterValue, setPriceFilterValue] = useState([300, 800]);

  //
  //
  //

  const [cartId, getCartId] = useState({
    variantColor: "",
    variantId: "",
    units: 0,
  });

  // store fetches
  const category = useSelector(({ getCategories }) => getCategories.payload);
  // const product = useSelector(({ getProducts }) => getProducts.payload);

  const Product = useSelector(
    ({ getSingleProduct }) => getSingleProduct.payload
  );
  const wish = useSelector(({ addWishLists }) => addWishLists.payload);
  const carts = useSelector(({ addCarts }) => addCarts.payload);
  const productspecs = useSelector(
    ({ productSpecifications }) => productSpecifications.payload
  );

  //uitils

  const Truncate = (string, number) => {
    if (!string) {
      return null;
    }
    if (string.length <= number) {
      return string;
    }
    return string.slice(0, number) + "...";
  };
  function numberWithCommas(x) {
    return x?.toString()?.split(",")[0]?.length > 3
      ? x
          ?.toString()
          ?.substring(0, x?.toString()?.split(".")[0]?.length - 3)
          ?.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x?.toString()?.substring(x?.toString()?.split(".")[0]?.length - 3)
      : x?.toString();
  }

  //handles

  const SingleProduct = (proId) => {
    dispatch(getSingleProduct({ productId: proId })).then((res) =>
      setLoad(false)
    );
  };

  const handleClick = () => {
    cartId.variantId = Product[changeVariant].id;
    cartId.units = quantity;
    cartId.variantColor = JSON.parse(Product[changeVariant].variantColor)[
      change
    ];
    dispatch(addCarts(cartId)).then((res) => {});
  };

  const priceFilterValueHandler = (event, newValue, activeThumb) => {
    const minDistance = 10;

    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 5000 - minDistance);
        setPriceFilterValue([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setPriceFilterValue([clamped - minDistance, clamped]);
      }
    } else {
      setPriceFilterValue(newValue);
    }
  };
  const submitPriceFilter = (e) => {
    const priceRange = priceFilterValue.join().split(",");

    dispatch(
      FilterProducts({
        priceRange,
        filter: true,
      })
    )
      .then((res) => {
        if (res.data.data === "No Products Found..!") {
          toast.warning("No product found on this filter !");
        } else {
          setProductData(res?.data?.data);
        }
      })
      .catch((err) => {});
  };
  const handleCategoryFilter = (id) => {
    const category = {
      categoryId: id.toString(),
    };
    dispatch(getCategoryProducts(category))
      .then((res) => {
        if (res.data.data === "No Products Found..!") {
          setMessage("No product found on this category !");
        } else {
          setProductData(res?.data?.data);
        }
      })
      .catch((err) => {});
  };
  const handleProductFilter = (e) => {
    //
    switch (e.target.value) {
      case "1":
        dispatch(
          FilterProducts({
            sort: 1,
          })
        ).then((res) => {
          setProductData(res?.data?.data);
        });
        break;
      case "2":
        dispatch(
          FilterProducts({
            sort: 2,
          })
        ).then((res) => {
          setProductData(res?.data?.data);
        });
        break;
      case "3":
        dispatch(
          FilterProducts({
            sort: 3,
          })
        ).then((res) => {
          setProductData(res?.data?.data);
        });
        break;
      case "4":
        dispatch(
          FilterProducts({
            sort: 4,
          })
        ).then((res) => {
          setProductData(res?.data?.data);
        });
        break;

      default:
        break;
    }
  };

  const handleFilter1 = (e) => {
    dispatch(
      FilterProducts({
        ratings:
          window.$("#amount2").val() === ""
            ? ["1", "5"]
            : window.$("#amount2").val().split("-"),
        filter: true,
      })
    )
      .then((res) => {
        if (res.data.data === "No Products Found..!") {
          setMessage("No product found on this filter !");
          dispatch(getProducts());
        } else {
          setProductData(res?.data?.data);
        }
      })
      .catch((err) => {});
  };
  function formatAsPercent(num) {
    return new Intl.NumberFormat("default", {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(num / 100);
  }

  // Effects on mount
  //
  //
  //
  //

  // getting products onload
  useEffect(() => {
    dispatch(getProducts())
      .then((res) => {
        setProductData(res?.data?.data);
        setLoad(false);
      })
      .catch(() => {});
  }, [dispatch]);

  // to load somewhat js lines from template
  useEffect(() => {
    setTimeout(() => {
      window.$(".qtybutton").on("click", function () {
        var $button = window.$(this);
        var oldValue = $button.parent().find("input").val();
        if ($button.hasClass("inc")) {
          var newVal = parseFloat(oldValue) + 1;
        } else {
          // Don't allow decrementing below zero
          if (oldValue > 1) {
            var newVal = parseFloat(oldValue) - 1;
          } else {
            newVal = 1;
          }
        }
        $button.parent().find("input").val(newVal);
      });

      /*----------------------------------------*/
      /*  Shop Grid Activation
      /*----------------------------------------*/

      window.$(".shop_toolbar_btn > button").on("click", function (e) {
        e.preventDefault();

        window.$(".shop_toolbar_btn > button").removeClass("active");
        window.$(this).addClass("active");

        var parentsDiv = window.$(".shop_wrapper");
        var viewMode = window.$(this).data("role");

        parentsDiv
          .removeClass("grid_3 grid_4 grid_5 grid_list")
          .addClass(viewMode);

        if (viewMode == "grid_3") {
          parentsDiv
            .children()
            .addClass("col-lg-4 col-md-4 col-sm-6")
            .removeClass("col-lg-3 col-cust-5 col-12");
        }

        if (viewMode == "grid_4") {
          parentsDiv
            .children()
            .addClass("col-xl-3 col-lg-4 col-md-4 col-sm-6")
            .removeClass("col-lg-4 col-cust-5 col-12");
        }

        if (viewMode == "grid_list") {
          parentsDiv
            .children()
            .addClass("col-12")
            .removeClass(
              "col-xl-3 col-lg-3 col-lg-4 col-md-6 col-md-4 col-sm-6 col-cust-5"
            );
        }
      });
    }, 1000);
  }, []);

  return load ? (
    <div className="row">
      <div className="col-lg-12">
        <div
          className="sweet-loading"
          style={{ margin: "193px", display: "flex" }}
        >
          <ClipLoader cssOverride={override} />
        </div>
      </div>
    </div>
  ) : (
    <div>
      <ToastContainer />

      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active"> Shop Sidebar</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-margin">
        <div className="container">
          <div className="row ">
            {/* left side */}
            <div className="col-lg-3 col-12">
              <aside className="sidebar_widget mt-10 mt-lg-0">
                <div className="widget_inner">
                  {/* price filter */}
                  <div className="widget-list mb-10">
                    <h3 className="widget-title mb-6">Price Filter</h3>
                    <Box sx={{ width: 190 }}>
                      <Slider
                        getAriaLabel={() => "Minimum distance shift"}
                        value={priceFilterValue}
                        onChange={priceFilterValueHandler}
                        valueLabelDisplay="auto"
                        disableSwap
                        min={100}
                        max={10000}
                        sx={{
                          color: "#00c4cc",
                        }}
                      />
                    </Box>
                    <button
                      style={{
                        border: "1px solid #55555569",
                        borderRadius: "3px",
                        background: "none",
                        color: "black",
                        fontFamily: "Inter",
                      }}
                      type="button"
                      onClick={submitPriceFilter}
                    >
                      Apply
                    </button>
                  </div>

                  {/* categories start */}
                  <div
                    className="widget-list mb-10"
                    style={{ backgroundColor: "white" }}
                  >
                    <h3 className="widget-title mb-6">Categories</h3>
                    <div className="sidebar-body">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        {Array?.isArray(category) && category?.length !== 0
                          ? category?.map((data, i) => (
                              <span
                                className={`${homeStyle.filterCategory}`}
                                onClick={() => handleCategoryFilter(data?.id)}
                              >
                                {data?.categoryName}
                              </span>
                            ))
                          : false}
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
            {/* right side */}
            <div className="col-lg-9 col-12">
              <div className="shop_toolbar_wrapper flex-column flex-md-row pb-10 mb-n4">
                <div className="shop-top-bar-left mb-4">
                  <div className="shop_toolbar_btn">
                    <button
                      data-role="grid_3"
                      type="button"
                      className="active btn-grid-3"
                      title="Grid"
                    >
                      <i className="fa fa-th"></i>
                    </button>
                    <button
                      data-role="grid_list"
                      type="button"
                      className="btn-list"
                      title="List"
                    >
                      <i className="fa fa-list"></i>
                    </button>
                  </div>
                </div>
                <div className="shop-top-bar-right mb-4">
                  {/*  short filter */}
                  <h4 className="title me-2">Sort By: </h4>
                  <div className="shop-short-by">
                    <select
                      onChange={handleProductFilter}
                      className="nice-select"
                      aria-label=".form-select-sm example"
                    >
                      <option disabled hidden>
                        Default Sorting
                      </option>
                      <option value="1">Sort by Price High - Low</option>
                      <option value="2">Sort by Price Low - High</option>
                      <option value="3">Sort by A-Z</option>
                      <option value="4">Sort by Z-A</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* products */}

              <div className="row shop_wrapper grid_3">
                {Array?.isArray(productData) && productData.length !== 0 ? (
                  productData
                    ?.filter((Y) => Y?.discountPrice >= 1)
                    .map((data, i) => (
                      <div
                        key={i}
                        className="col-lg-4 col-md-4 col-sm-6 product  p-3  "
                      >
                        <div className="product-inner">
                          <div className="thumb">
                            <Link to={"/productDetails/" + data.id}>
                              <img
                                src={
                                  process.env.REACT_APP_URL + data.productImage
                                }
                                style={{ background: "##fff" }}
                                alt="#"
                              />
                            </Link>
                            <span className="badges">
                              <span className="sale">
                                {" "}
                                {formatAsPercent(
                                  ((data.actualPrice - data.discountPrice) /
                                    data.actualPrice) *
                                    100
                                )}{" "}
                                OFF
                              </span>
                            </span>
                            <div className="add-cart-btn">
                              <span
                                className="action quickview btn btn-whited btn-hover-primary text-capitalize add-to-cart"
                                onClick={() => SingleProduct(data?.id)}
                                data-bs-toggle="modal"
                                data-bs-target="#quick-view"
                              >
                                Quick View
                              </span>
                            </div>
                          </div>
                          <div className="content">
                            <h5
                              className="title"
                              style={{ textTransform: "capitalize" }}
                            >
                              {" "}
                              <Link to={"/productDetails/" + data.id}>
                                {data.productName}
                              </Link>
                            </h5>

                            <div
                              style={{
                                display: "flex",

                                justifyContent: "space-evenly",
                              }}
                            >
                              <span className="price">
                                <span className="new">
                                  {" "}
                                  ₹{numberWithCommas(data?.discountPrice)}
                                </span>
                                <span className="old">
                                  {" "}
                                  ₹{numberWithCommas(data?.actualPrice)}
                                </span>
                              </span>

                              {/* <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p> */}
                              <div className="">
                                <a
                                  title=""
                                  className=""
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    dispatch(
                                      addWishLists({
                                        productId: data?.id,
                                      })
                                    ).then((res) => {
                                      toast(res.data);
                                      setRender(!render);
                                      window.location.href = "/product";
                                    });
                                  }}
                                >
                                  {data?.favourites == 0 ? (
                                    <i
                                      className="bx bx-heart"
                                      style={{ fontSize: "26px" }}
                                    ></i>
                                  ) : (
                                    <i
                                      style={{
                                        fontSize: "26px",
                                        color: "#FE036A",
                                      }}
                                      className="bx bxs-heart"
                                    ></i>
                                  )}
                                </a>
                              </div>
                            </div>

                            <p style={{ textAlign: "justify" }}>
                              {" "}
                              <b
                                style={{
                                  fontWeight: "600",
                                  textAlign: "justify",
                                }}
                              >
                                Description :{" "}
                              </b>
                              {Truncate(data?.productDescription, 240)}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  <h3
                    style={{ margin: "0 auto", width: "fit-content" }}
                    className="container"
                  >
                    No Product Found
                  </h3>
                )}
              </div>
              <div className="shop_toolbar_wrapper mt-10 mb-n4"></div>
            </div>
          </div>
        </div>
      </div>

      {/* model */}
      <div
        className="modalquickview modal fade"
        id="quick-view"
        tabIndex="-1"
        aria-labelledby="quick-view"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {loader ? (
              <div
                className="sweet-loading"
                style={{ margin: "193px", display: "flex" }}
              >
                <ClipLoader loading={loading} cssOverride={override} />
              </div>
            ) : (
              <div className="container">
                <button className="btn close" data-bs-dismiss="modal">
                  ×
                </button>

                {Array.isArray(Product) && Product.length > 0 ? (
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="modal-product-carousel">
                        <div className="swiper-container">
                          <div className="swiper-wrapper">
                            <a className="swiper-slide" href="#">
                              <img
                                className="w-100"
                                src={
                                  process.env.REACT_APP_URL +
                                  JSON?.parse(
                                    Product[changeVariant]?.variantImage
                                  )[change]
                                }
                                alt="Product"
                                width={424}
                                height={424}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12 overflow-hidden position-relative">
                      <div className="product-summery position-relative">
                        <h5
                          className="product-head mb-3"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Link to={"/product"}>
                            {Product[changeVariant].productName}
                          </Link>
                        </h5>

                        <div className="price-box mb-2">
                          <span className="regular-price">
                            {" "}
                            ₹
                            {numberWithCommas(
                              JSON?.parse(
                                Product[changeVariant]?.discountPrice
                              )[change]
                            )}
                          </span>
                          <span className="old-price">
                            <del>
                              {" "}
                              ₹{" "}
                              {numberWithCommas(
                                JSON?.parse(
                                  Product[changeVariant]?.actualPrice
                                )[change]
                              )}
                            </del>
                          </span>
                        </div>
                        {Array?.isArray(productData) && productData?.length != 0
                          ? productData
                              .filter(
                                (F) =>
                                  F?.id === Product[changeVariant].productId
                              )
                              .map((pro, i) => (
                                <p
                                  key={i}
                                  style={{
                                    textAlign: "justify",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {" "}
                                  <b style={{ fontWeight: "600" }}>
                                    Description :{" "}
                                  </b>
                                  {Truncate(pro?.productDescription, 240)}
                                </p>
                              ))
                          : false}

                        <div className="quantity d-flex align-items-center mb-5">
                          <span className="me-2">
                            <strong>Qty: </strong>
                          </span>
                          <div className="cart-plus-minus">
                            <input
                              className="cart-plus-minus-box"
                              onChange={(e) => setQuantity(e?.target.value)}
                              value={quantity}
                              type="text"
                              disabled
                            />
                            <div
                              className="dec qtybutton"
                              onClick={() => {
                                return quantity > 1
                                  ? setQuantity(quantity - 1)
                                  : false;
                              }}
                            >
                              <img
                                src={minus}
                                alt=""
                                height="12px"
                                width="12px"
                              />
                            </div>
                            <div
                              className="inc qtybutton"
                              onClick={() => {
                                setQuantity(quantity + 1);
                              }}
                            >
                              <img
                                src={plus}
                                alt=""
                                height="12px"
                                width="12px"
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          {carts ? (
                            <div
                              style={{
                                marginTop: "20px",
                                marginBottom: "-20px",
                              }}
                              className="alert alert-warning alert-dismissible fade show mb-5"
                              role="alert"
                              mt-3
                            >
                              {carts === "Already Added to Cart" ||
                              carts === "Added to Cart" ? (
                                <link to="/cart">
                                  {" "}
                                  {carts} -{" "}
                                  <span style={{ textDecoration: "underline" }}>
                                    {" "}
                                    Go to Cart
                                  </span>{" "}
                                </link>
                              ) : (
                                carts
                              )}
                              <button
                                type="button"
                                className="btn-close"
                                //   onClick={()=>dispatch({type:addCart.add.success,data:false})}
                                data-dismiss="alert"
                              ></button>
                            </div>
                          ) : (
                            false
                          )}
                        </div>
                        <div>
                          {wish ? (
                            <div
                              style={{
                                marginTop: "20px",
                                marginBottom: "-20px",
                              }}
                              className="alert alert-warning alert-dismissible fade show mb-5"
                              role="alert"
                              mt-3
                            >
                              {wish}
                              <button
                                type="button"
                                className="btn-close"
                                data-dismiss="alert1"
                                //   onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                                aria-label="Close"
                              ></button>
                            </div>
                          ) : (
                            false
                          )}
                        </div>

                        <div className="cart-btn mb-4">
                          <div className="add-to_cart">
                            <a
                              className="btn btn-dark btn-hover-primary"
                              onClick={() => {
                                cartId.variantId = Product[changeVariant].id;
                                cartId.units = quantity;
                                cartId.variantColor = JSON.parse(
                                  Product[changeVariant].variantColor
                                )[change];
                                dispatch(addCarts(cartId)).then((res) => {
                                  toast(res.data.data);
                                });
                              }}
                            >
                              Add to cart
                            </a>
                          </div>
                        </div>

                        <span>
                          <a
                            href={
                              "/productDetails/" +
                              Product[changeVariant]?.productId
                            }
                            style={{
                              fontSize: "18px",
                              textDecoration: "underline",
                            }}
                          >
                            View More Details
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  false
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* model end */}
    </div>
  );
};

export default Product;
