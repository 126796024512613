import React, { useState, useEffect } from 'react'
import { getOrders } from "../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import orderimage from "../Assets/orders.svg";


const OrdersList = () => {

    const dispatch = useDispatch();
    const [load, setLoad] = useState(true);

    useEffect(() => {
        dispatch(getOrders())
            .then((res) => setLoad(false))
            .catch((res) => setLoad(false));
    }, []);
    const order = useSelector(({ getOrders }) => getOrders.payload);

    const styles = {
        textAlign: "center",
        color: "black",
    };


  return (
    <div>

<div className="table-responsive">
                <table className="table">
                    {Array.isArray(order) && order.length !== 0
                        ?
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Order Id</th>
                                <th>Order Image</th>
                                <th>Order Date</th>
                                <th>Order Status</th>
                                <th>Order Details</th>
                            </tr>
                        </thead>
                        : false}
                    <tbody>
                        {Array.isArray(order) && order.length !== 0
                            ? order?.map((data, i) => (
                                <tr key={i}>
                                    <td style={{ verticalAlign: "middle",    textAlign: "center" }}>{i + 1}</td>
                                    <td style={{ verticalAlign: "middle",textAlign: "center" }}>
                                        <Link
                                            to={"/orderDetails/" + data.orderId}
                                            style={{ textTransform: "capitalize" }}>
                                            {data.orderId}</Link>
                              </td>
                                    <td ><Link to={"/orderDetails/" + data.orderId}>
                                        <img src={// process.env.REACT_APP_BASE_URL+ 
                                            process.env.REACT_APP_URL + data.variantImages[0]} alt="" style={{ height: "85px", width: "auto" }} />
                                    </Link></td>
                                    <td style={{ verticalAlign: "middle" ,textAlign: "center"}}>
                                        <Link to={"/orderDetails/" + data.orderId}
                                            style={{ textTransform: "capitalize", }}>
                                            {data?.timestamp}
                                        </Link>
                                    </td>
                                    <td style={{ verticalAlign: "middle",textAlign: "center" }}>
                                        <Link to={"/orderDetails/" + data.orderId}
                                            style={{ textTransform: "capitalize", }}>
                                            {data?.orderStatus}
                                        </Link>
                                    </td>
                                    <td style={{ verticalAlign: "middle",textAlign: "center" }}> <span className="badge bg-success"><Link to={"/orderdetails/" + data.orderId} style={{ color: "white"}}>Track</Link></span></td>
                                </tr>
                            ))
                            : <center><img src={orderimage} className='mt-5 mb-5 pb-5 pt-5' style={{ width: "25%" }} />
                                <h3>   No Orders Found</h3>  </center>}
                    </tbody>
                </table>
            </div>



    </div>
  )
}

export default OrdersList