import DOMPurify from "dompurify";

import React, {
  useState,
  useEffect,
  lazy,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import cusStyle from "./customStyle.module.css";
import { QuantitySettingBtn } from "../Common/QuantityBtn";

import { addCart, addWishList } from "../Redux/ActionType";
import {
  getSingleProduct,
  getRelatedProducts,
  addWishLists,
  addCarts,
  productSpecifications,
  getReviews,
  buyNows,
} from "../Redux/Action";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ClipLoader from "react-spinners/ClipLoader";
import ReactImageMagnify from "react-image-magnify";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import plus from "../Assets/plus.png";
import minus from "../Assets/minus.png";
import { FaStar, FaRegStar } from "react-icons/fa";

const Token = localStorage.getItem("AuthTok");

// component starts
const ProductDetails = () => {
  //declaratons
  const dispatch = useDispatch();
  const { id: proid } = useParams();

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#ff3e3e",
  };

  //states

  //v states

  const [pid, setPid] = useState({
    productId: proid,
  });
  const [cartId, setCartId] = useState({
    variantColor: "",
    variantId: "",
    units: 0,
  });

  // variantSelectedColor
  const [variantSelectedColor, setVariantSelectedColor] = useState(0);

  const [selectedProductVariant, setSelectedProductVariant] = useState(0);

  const [render, setRender] = useState(true);
  const [quantity, setQuantity] = useState(undefined);
  const [load, setLoad] = useState(true);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);

  //store fetches

  const singleProduct = useSelector(
    ({ getSingleProduct }) => getSingleProduct.payload
  );

  const productspecs = useSelector(
    ({ productSpecifications }) => productSpecifications.payload
  );
  const rProducts = useSelector(
    ({ getRelatedProducts }) => getRelatedProducts.payload
  );
  const review = useSelector(({ getReviews }) => getReviews.payload);

  const wish = useSelector(({ addWishLists }) => addWishLists.payload);
  const carts = useSelector(({ addCarts }) => addCarts.payload);

  //utils
  function numberWithCommas(x) {
    if (x) {
      return x.toString().split(",")[0].length > 3
        ? x
            .toString()
            .substring(0, x.toString().split(".")[0].length - 3)
            .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
            "," +
            x.toString().substring(x.toString().split(".")[0].length - 3)
        : x.toString();
    } else {
      return 0;
    }

    // return x
  }
  const capsFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const Truncate = (string, number) => {
    if (!string) {
      return null;
    }
    if (string.length <= number) {
      return string;
    }
    return string.slice(0, number) + "...";
  };
  function formatAsPercent(num) {
    return new Intl.NumberFormat("default", {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(num / 100);
  }

  function capitalize(s) {
    return s.toUpperCase();
  }

  //handlers
  const getQuantity = useMemo(
    () => (quant) => {
      setQuantity(quant);
    },
    []
  );

  useEffect(() => {
    if (Array.isArray(singleProduct) && singleProduct.length > 0) {
      const variantColor = JSON.parse(
        singleProduct[selectedProductVariant].variantColor
      )[variantSelectedColor];
      const variantId = singleProduct[selectedProductVariant].id;
      const units = quantity;

      setCartId((pre) => ({ ...pre, variantColor, variantId, units }));
    }
  }, [quantity, selectedProductVariant, variantSelectedColor, singleProduct]);

  const handleAddToCart = () => {
    dispatch(addCarts(cartId)).then((res) => {});
  };

  const Clicktobuy = () => {
    dispatch(buyNows(cartId)).then((res) => {
      var config = {
        root: "",
        style: {
          bodyBackgroundColor: "#fafafb",
          bodyColor: "",
          themeBackgroundColor: "#0FB8C9",
          themeColor: "#ffffff",
          headerBackgroundColor: "#284055",
          headerColor: "#ffffff",
          errorColor: "",
          successColor: "",
          card: {
            padding: "",
            backgroundColor: "",
          },
        },
        data: {
          orderId: res.data.data.orderId,
          token: res.data.data.txnToken,
          tokenType: "TXN_TOKEN",
          amount: res.data.data.discountPrice /* update amount */,
        },
        payMode: {
          labels: {},
          filter: {
            exclude: [],
          },
          order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
        },
        website: "WEBSTAGING",
        flow: "DEFAULT",
        merchant: {
          mid: "sKxlKi69872184882034",
          redirect: false,
        },
        handler: {
          transactionStatus: function transactionStatus(paymentStatus) {},
          notifyMerchant: function notifyMerchant(eventName, data) {},
        },
      };
      if (window.Paytm && window.Paytm.CheckoutJS) {
        window.Paytm.CheckoutJS.init(config)
          .then(function onSuccess() {
            window.Paytm.CheckoutJS.invoke();
          })
          .catch(function onError(error) {});
      }
    });
  };

  const SingleProduct = (proId) => {
    setPid({ productId: proId });
    dispatch(getSingleProduct({ productId: proId })).then((res) => {
      setLoad(false);
    });
  };

  const handleSelectedProductVariant = (currentVariant) => {
    setSelectedProductVariant(currentVariant);
  };

  //effects initial fetching,

  useEffect(() => {
    if (Token) dispatch(getRelatedProducts());

    Promise.allSettled([
      dispatch(getSingleProduct(pid)),
      dispatch(productSpecifications(pid)),

      dispatch(getReviews(pid)),
    ]).then((res) => {
      setLoad(false);
      setRender(true);
    });
  }, []);

  useEffect(() => {
    window.setTimeout(function () {
      dispatch({ type: addCart.add.success });

      window
        .$(".alert")
        .fadeTo(500, 0)
        .slideUp(500, function () {
          window.$(this).remove();
        });
    }, 6000);

    window.setTimeout(function () {
      dispatch({ type: addWishList.wishList.success });
      window
        .$(".alert1")
        .fadeTo(500, 0)
        .slideUp(500, function () {
          window.$(this).remove();
        });
    }, 6000);
  }, [carts, wish]);

  //loading some js from template
  useEffect(() => {
    setTimeout(() => {
      window.$(".qtybutton").on("click", function () {
        var $button = window.$(this);
        var oldValue = $button.parent().find("input").val();
        if ($button.hasClass("inc")) {
          var newVal = parseFloat(oldValue) + 1;
        } else {
          // Don't allow decrementing below zero
          if (oldValue > 1) {
            var newVal = parseFloat(oldValue) - 1;
          } else {
            newVal = 1;
          }
        }
        $button.parent().find("input").val(newVal);
      });

      window.$(".qtybutton").on("click", function () {
        var $button = window.$(this);
        var oldValue = $button.parent().find("input").val();
        if ($button.hasClass("inc")) {
          var newVal = parseFloat(oldValue) + 1;
        } else {
          // Don't allow decrementing below zero
          if (oldValue > 1) {
            var newVal = parseFloat(oldValue) - 1;
          } else {
            newVal = 1;
          }
        }
        $button.parent().find("input").val(newVal);
      });
    }, 2000);
  }, []);

  // review card
  function ReviewCard({ data }) {
    let cusImg = data?.customerImage;
    let cusName = data?.customerName;
    let rating = data?.rating;
    let date = data?.createdAt;
    let review = data?.review;

    return (
      <div
        className="r-card"
        style={{
          marginTop: "15px",
          padding: "15px",
          display: "flex",
          flexDirection: "column",
          borderRadius: "3px",
          borderTop: "0.5px solid rgba(0,0,0,0.1)",
        }}
      >
        <div
          className="r-head"
          style={{
            height: "max-content",
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <img
            src={cusImg}
            style={{ display: "inline-block", width: "30px", height: "30px" }}
            alt=""
          />
          <h2 style={{ fontSize: "17px", margin: "0px" }} className="name">
            {capsFirst(cusName)}
          </h2>
        </div>

        <div className="r-body">
          <p
            style={{
              maxHeight: "30px",
              display: "inline-flex",
              gap: "5px",
              margin: "0px",
            }}
          >
            <span style={{ display: "inline-flex", gap: "5px" }}>
              {Array?.from({ length: 5 }, (data, i) =>
                i < rating ? (
                  <span key={i}>
                    <FaStar
                      style={{
                        color: "#DD3D05",
                      }}
                    />
                  </span>
                ) : (
                  <span key={i}>
                    <FaRegStar
                      style={{
                        color: "#DD3D05",
                      }}
                    />
                  </span>
                )
              )}
            </span>

            <span style={{ margin: "2px" }}> {rating} out of 5</span>
          </p>
          <p style={{ margin: "0px", color: "#000", marginBottom: "5px" }}>
            Reviewed {date}
          </p>
          <p style={{ margin: "0px" }}>{capsFirst(review)}</p>
        </div>
      </div>
    );
  }
  // rating in reviews cmp
  function RatingProgressBars(bars) {
    return bars.map((bar, index) => {
      return (
        <div
          key={index}
          className="ratingBar"
          style={{
            lineHeight: "1",
            margin: "0 auto",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            maxWidth: "250px",

            padding: "3px 0",
            gap: "5px",
          }}
        >
          <span
            style={{ display: "inline-flex", alignItems: "center", gap: "4px" }}
          >
            {bar.rating}
            <FaStar
              style={{
                color: "#666",
              }}
            />
          </span>

          <div
            style={{
              minWidth: "200px",
              maxWidth: "210px",
              overflow: "hidden",
              borderRadius: "10px",
              backgroundColor: "#f1f1f1",
            }}
          >
            <div
              style={{
                width: `${bar.percentage}%`,
                backgroundColor: "#fec109",
                padding: "5px 0",
              }}
            ></div>
          </div>
          <span>{bar.usersCount}</span>
        </div>
      );
    });
  }
  function ProgresBarBuilder({ data }) {
    const barDetails = [];

    for (let i = 5; i > 0; i--) {
      let order = ["verypoor", "poor", "good", "best", "excellent"];
      let usersCount = (data?.noOfRatings / 100) * data[order[i - 1]];

      const bar = {
        rating: i.toString(),
        usersCount,
        percentage: data[order[i - 1]],
      };

      barDetails.push(bar);
    }

    return RatingProgressBars(barDetails);
  }

  // components return
  return load ? (
    <div className="row">
      <div className="col-lg-12">
        <div
          className="sweet-loading"
          style={{ margin: "193px", display: "flex" }}
        >
          <ClipLoader cssOverride={override} />
        </div>
      </div>
    </div>
  ) : (
    <div>
      <ToastContainer />

      {/* Bread Crumb */}
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active">Product Details</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Product Showcase Container */}
      <div className="section section-margin-top">
        <div className="container">
          {Array.isArray(singleProduct) && singleProduct.length > 0 ? (
            <div className="row">
              {/* left side */}
              <div className="col-lg-5" style={{ zIndex: "999" }}>
                {/* top */}
                <div>
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        src:
                          process.env.REACT_APP_URL +
                          JSON?.parse(
                            singleProduct[selectedProductVariant]?.variantImage
                          )[variantSelectedColor],

                        isFluidWidth: true,
                        image: lazy,
                      },
                      largeImage: {
                        src:
                          process.env.REACT_APP_URL +
                          JSON?.parse(
                            singleProduct[selectedProductVariant]?.variantImage
                          )[variantSelectedColor],
                        width: 1200,
                        height: 1200,
                        zindex: "9999",
                      },
                      enlargedImageContainerDimensions: {
                        width: "100%",
                        height: "100%",
                        zindex: "999",
                      },
                      isHintEnabled: true,
                      shouldHideHintAfterFirstActivation: true,
                    }}
                  />
                </div>
                {/* bottom */}
                <div>
                  <div className={`${cusStyle.variantColorContainer}`}>
                    {singleProduct?.map((variant, i) => {
                      return (
                        <div className={`${cusStyle.variantColorImg}`} key={i}>
                          <img
                            src={
                              process.env.REACT_APP_URL +
                              JSON?.parse(variant?.variantImage)[0]
                            }
                            onClick={() => {
                              handleSelectedProductVariant(i);
                            }}
                            // a copy of previous
                            // onClick={() => {
                            //   setVariantSelectedColor(i);
                            // }}
                            alt=""
                          />
                        </div>
                      );
                    })}
                  </div>
                  {/* a copy of previous */}
                  {/* <div className={`${cusStyle.variantColorContainer}`}>
                    {JSON?.parse(
                      singleProduct[selectedProductVariant].variantImage
                    )?.map((data, i) => (
                      <div className={`${cusStyle.variantColorImg}`} key={i}>
                        <img
                          src={process.env.REACT_APP_URL + data}
                          onClick={() => {
                            setVariantSelectedColor(i);
                          }}
                          alt=""
                        />
                      </div>
                    ))}
                  </div> */}
                </div>
              </div>
              {/* right side  */}
              <div className="col-lg-7" id="magnify">
                <div className="product-summery position-relative">
                  {/* name */}
                  <div className="product-head mb-3">
                    <h2
                      className="product-title"
                      style={{ textTransform: "capitalize" }}
                    >
                      {Array.isArray(singleProduct)
                        ? singleProduct[variantSelectedColor].productName
                        : ""}
                    </h2>
                  </div>
                  {/* reviews */}
                  <div style={{ display: "flex" }}>
                    {Array?.from({ length: 5 }, (data, i) =>
                      i < review?.reviewData?.overallRatings ? (
                        <span style={{ margin: "2px" }}>
                          <FaStar
                            style={{
                              color: "#DD3D05",
                            }}
                          />
                        </span>
                      ) : (
                        <span style={{ margin: "2px" }}>
                          <FaRegStar
                            style={{
                              color: "#DD3D05",
                            }}
                          />
                        </span>
                      )
                    )}
                    <div
                      style={{
                        marginLeft: "12px",
                        margin: "4px",
                      }}
                      type="button"
                      className="product-review-show"
                    >
                      {" "}
                      {review?.reviewData?.overallRatings} Reviews
                    </div>
                  </div>
                  {/* Variant Selector */}
                  <div className="product-details-qty-list">
                    <div style={{ display: "flex" }}>
                      <div className={cusStyle.variantSelectorContainer}>
                        {Array.isArray(singleProduct) &&
                          singleProduct.map((variant, index) => {
                            return (
                              <span
                                key={index}
                                onClick={() => {
                                  setVariantSelectedColor(0);
                                  handleSelectedProductVariant(index);
                                }}
                                className={`${cusStyle.variantLable} ${
                                  selectedProductVariant === index
                                    ? cusStyle.selected
                                    : " "
                                }`}
                              >
                                {capitalize(variant.variantName)}
                              </span>
                            );
                          })}
                      </div>
                    </div>
                    {/* Variant Color Selector */}
                    <div style={{ display: "flex" }}>
                      <div className={cusStyle.variantSelectorContainer}>
                        {Array.isArray(singleProduct) &&
                          JSON?.parse(
                            singleProduct[selectedProductVariant].variantColor
                          ).map((color, index) => {
                            return (
                              <span
                                key={index}
                                style={{ backgroundColor: `${color}` }}
                                className={`${cusStyle.variantColorSelector} ${
                                  variantSelectedColor === index
                                    ? cusStyle.colorSelected
                                    : " "
                                }`}
                                onClick={() => {
                                  setVariantSelectedColor(index);
                                }}
                              ></span>
                            );
                          })}
                      </div>
                    </div>
                  </div>

                  {/*  price field */}
                  <div className="price-box mb-2">
                    <span className="regular-price">
                      ₹
                      {numberWithCommas(
                        JSON?.parse(
                          singleProduct[selectedProductVariant]?.discountPrice
                        )[variantSelectedColor]
                      )}
                    </span>
                    <span className="old-price">
                      <del>
                        ₹{" "}
                        {numberWithCommas(
                          JSON?.parse(
                            singleProduct[selectedProductVariant]?.actualPrice
                          )[variantSelectedColor]
                        )}
                      </del>
                    </span>
                  </div>
                  {/* description */}
                  <p style={{ textAlign: "justify" }}>
                    {" "}
                    <b
                      style={{
                        fontWeight: "600",
                        textAlign: "justify",
                      }}
                    >
                      Description :{" "}
                    </b>
                    {Truncate(productspecs?.productDescription, 240)}
                  </p>

                  {/* quantity  */}
                  <div className="quantity d-flex align-items-center mb-5">
                    <span className="me-2">
                      <strong>Qty: </strong>
                    </span>
                    <QuantitySettingBtn getQuantity={getQuantity} />
                  </div>

                  {/*Go to Cart Button */}
                  <div>
                    {carts ? (
                      <div
                        style={{ marginTop: "20px", marginBottom: "-20px" }}
                        className="alert alert-warning alert-dismissible fade show mb-5"
                        role="alert"
                        mt-3
                      >
                        {carts === "Already Added to Cart" ||
                        carts === "Added to Cart" ? (
                          <Link to="/cart">
                            {" "}
                            {carts} -{" "}
                            <span style={{ textDecoration: "underline" }}>
                              {" "}
                              Go to Cart
                            </span>{" "}
                          </Link>
                        ) : (
                          carts
                        )}
                        <button
                          type="button"
                          className="btn-close"
                          data-dismiss="alert"
                        ></button>
                      </div>
                    ) : (
                      false
                    )}
                  </div>

                  {/* cart added message */}
                  <div>
                    {wish ? (
                      <div
                        style={{ marginTop: "20px", marginBottom: "-20px" }}
                        className="alert alert-warning alert-dismissible fade show mb-5"
                        role="alert"
                        mt-3
                      >
                        {wish}
                        <button
                          type="button"
                          className="btn-close"
                          data-dismiss="alert1"
                          //   onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                          aria-label="Close"
                        ></button>
                      </div>
                    ) : (
                      false
                    )}
                  </div>

                  {/* Add to Cart , buy now */}
                  <div className="cart-btn mb-4" style={{ display: "flex" }}>
                    <div
                      className="add-to_cart"
                      style={{ marginRight: " 10px" }}
                    >
                      {Token ? (
                        <span
                          href=""
                          className="btn btn-dark btn-hover-primary"
                          title="Add to Cart"
                          onClick={handleAddToCart}
                          data-bs-toggle="modal"
                          data-bs-target="#add_to_cart_modal"
                          style={{ outline: "0", boxShadow: "0px 0px 0px 0px" }}
                        >
                          <span>Add To Cart</span>
                        </span>
                      ) : (
                        <Link
                          data-bs-toggle="modal"
                          style={{ color: "white" }}
                          to={"/login"}
                          className="btn btn-dark btn-hover-primary"
                          onClick={handleAddToCart}
                        >
                          Add to Cart
                          <i
                            style={{ marginLeft: "5px", fontSize: "15px" }}
                            className="fa fa-shopping-cart"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      )}
                    </div>

                    <div
                      className="btn btn-dark btn-hover-primary"
                      onClick={Clicktobuy}
                    >
                      BUY NOW
                    </div>
                  </div>

                  {/* wishlist btn */}
                  <div className="actions border-bottom mb-4 pb-4">
                    <span
                      title="Wishlist"
                      onClick={() => {
                        setLoad(true);
                        dispatch(
                          addWishLists({
                            productId:
                              singleProduct[variantSelectedColor].productId,
                          })
                        ).then((res) => {
                          toast(res.data);
                          setLoad(false);
                        });
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#liton_wishlist_modal"
                      style={{ cursor: "pointer" }}
                      className="btn-wishlist"
                    >
                      {singleProduct[variantSelectedColor]?.favourites === 0 ? (
                        <i
                          className="bx bx-heart"
                          style={{ fontSize: "40px" }}
                        ></i>
                      ) : (
                        <i
                          style={{ fontSize: "40px", color: "#FE036A" }}
                          className="bx bxs-heart"
                        ></i>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p style={{ width: "fit-content", margin: "0 auto" }}>
              <b style={{ color: "black" }}>No Product Found!</b>
            </p>
            // no product found message
          )}

          {/* Product Details Container start */}
          {Array?.isArray(singleProduct) && singleProduct.length > 0 ? (
            <div className="row section-margin">
              <div className="col-lg-12 single-product-tab">
                {/* tabs headings */}
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      href="#connect-1"
                      role="tab"
                      aria-selected="true"
                    >
                      Description
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      href="#connect-2"
                      role="tab"
                      aria-selected="false"
                    >
                      More Info
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      href="#connect-3"
                      role="tab"
                      aria-selected="false"
                    >
                      Specification
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="review-tab"
                      data-bs-toggle="tab"
                      href="#connect-4"
                      role="tab"
                      aria-selected="false"
                    >
                      Reviews
                    </a>
                  </li>
                </ul>

                {/* tabs contents */}
                <div className="tab-content mb-text" id="myTabContent">
                  {/* Description */}
                  <div
                    className="tab-pane fade show active"
                    id="connect-1"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="desc-content p-3">
                      <p style={{ textAlign: "justify" }}>
                        {productspecs?.productDescription ? (
                          capsFirst(productspecs?.productDescription)
                        ) : (
                          <h3
                            className="pt-2 pb-0"
                            style={{
                              fontSize: "18px",
                              color: "#777",
                              textAlign: "center",
                            }}
                          >
                            Oops!!! No Product Description added yet to show
                          </h3>
                        )}
                      </p>
                    </div>
                  </div>
                  {/* More Info */}
                  <div
                    className="tab-pane fade"
                    id="connect-2"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="product_tab_content border p-3">
                      <div className="single-review d-flex mb-4">
                        {productspecs?.moreInfo ? (
                          <p
                            className={`${cusStyle.moreInfo}`}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                productspecs?.moreInfo
                              ),
                            }}
                          ></p>
                        ) : (
                          <p>
                            <h3
                              className="pt-2 pb-0"
                              style={{
                                fontSize: "18px",
                                color: "#777",
                                textAlign: "center",
                              }}
                            >
                              Oops!!! No "More Info" Added yet to show
                            </h3>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Specification */}
                  <div
                    className="tab-pane fade"
                    id="connect-3"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="size-tab table-responsive-lg p-3">
                      {JSON.parse(productspecs?.productSpecification).length >
                      0 ? (
                        <div>
                          <table
                            style={{ border: "1px solid black" }}
                            className="w-100"
                          >
                            <tbody style={{ border: "1px solid black" }}>
                              {JSON?.parse(
                                productspecs?.productSpecification
                              )?.map((SP, i) => (
                                <tr
                                  key={i}
                                  style={{ border: "1px solid black" }}
                                >
                                  <td
                                    className="width1 "
                                    style={{
                                      border: "1px solid black",
                                      fontWeight: "600",
                                      padding: "5px",
                                      color: "#000",
                                    }}
                                  >
                                    {capsFirst(SP?.productTitle)}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid black",
                                      fontWeight: "500",
                                      padding: "5px",
                                    }}
                                  >
                                    {" "}
                                    {capsFirst(SP?.productAnswer)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <h3
                          className="pt-2 pb-0"
                          style={{
                            fontSize: "18px",
                            color: "#777",
                            textAlign: "center",
                          }}
                        >
                          Oops!!! No Specification Added yet to show
                        </h3>
                      )}
                    </div>
                  </div>

                  {/* User Reviews */}
                  <div
                    className="tab-pane fade"
                    id="connect-4"
                    role="tabpanel"
                    aria-labelledby="review-tab"
                  >
                    <div className="size-tab table-responsive-lg p-3">
                      <div className="row">
                        {/* review header */}
                        <div className="col-12">
                          {Array.isArray(review?.reviews) ? (
                            <div
                              className="row justify-content-center"
                              style={{ textAlign: "center" }}
                            >
                              <h2
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "400",
                                  lineHeight: "1",
                                  marginBottom: "20px",
                                }}
                              >
                                Rating Overview
                              </h2>

                              {/*4.0 / 5  out of rating */}
                              <p
                                style={{
                                  fontWeight: "900",
                                  lineHeight: "1",
                                  color: "#000",
                                  margin: "0px",
                                  fontSize: "40px",
                                }}
                              >
                                {review?.reviewData?.overallRatings}
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  / 5
                                </span>
                              </p>

                              {/*stars iterator  */}

                              <p
                                style={{
                                  display: "inline-flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "0px",
                                  marginBottom: "20px",
                                }}
                              >
                                <span
                                  style={{ display: "inline-flex", gap: "5px" }}
                                >
                                  {Array?.from({ length: 5 }, (data, i) =>
                                    i < review?.reviewData.overallRatings ? (
                                      <span key={i}>
                                        <FaStar
                                          style={{
                                            color: "#DD3D05",
                                          }}
                                        />
                                      </span>
                                    ) : (
                                      <span key={i}>
                                        <FaRegStar
                                          style={{
                                            color: "#DD3D05",
                                          }}
                                        />
                                      </span>
                                    )
                                  )}
                                </span>

                                <span style={{ fontWeight: "500" }}>
                                  {review?.reviewData.noOfRatings} ratings
                                </span>
                              </p>

                              {/* rating progress bars */}
                              <div className="col-7">
                                <ProgresBarBuilder data={review?.reviewData} />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        {/* review body, user given reviews */}
                        <div className="col-12">
                          {Array?.isArray(review?.reviews) &&
                          review?.reviews?.length > 0 ? (
                            <div className="row">
                              {review?.reviews?.map((R, i) => (
                                <ReviewCard key={i} data={R} />
                              ))}
                            </div>
                          ) : (
                            <h3
                              className="pt-2 pb-0"
                              style={{
                                fontSize: "18px",
                                color: "#777",
                                textAlign: "center",
                              }}
                            >
                              Oops!!! No Reviews yet to show
                            </h3>
                          )}
                        </div>
                        {/* reviwe body ends */}
                      </div>
                    </div>

                    {/*  */}
                  </div>
                  {/* User Reviews End */}
                </div>
              </div>
            </div>
          ) : (
            false
          )}
          {/* Product Details Container end */}
        </div>
      </div>

      {/* Related Products */}
      <div className="section section-margin mt-0 position-relative">
        <div className="container">
          {/* title */}
          <div className="row mb-lg-8 mb-md-6 mb-4">
            <div className="col-lg col-12">
              <div className="section-title mb-0 text-center">
                {Array.isArray(rProducts) && Array.length !== 0 ? (
                  <h2 className="title mb-2">Related Products</h2>
                ) : (
                  false
                )}
              </div>
            </div>
          </div>
          {/* cards */}
          <div className="row">
            {Array.isArray(rProducts) && Array.length !== 0
              ? rProducts
                  .filter((data) => data.discountPrice >= 0)
                  .map((data, i) => (
                    <div key={i} className="col-lg-3 col-sm-6 col-md-6  ">
                      <div className="product-carousel arrow-outside-container">
                        <div className="swiper-container">
                          <div className="product-wrapper">
                            <div className="product">
                              {/* image */}
                              <div className="thumb">
                                <a
                                  className="d-block image"
                                  href={"/productDetails/" + data.id}
                                >
                                  <img
                                    alt=""
                                    className="fit-image"
                                    src={
                                      process.env.REACT_APP_URL +
                                      data.productImage
                                    }
                                  />
                                </a>

                                {/* disc percentage */}
                                <span className="badges">
                                  <span className="sale">
                                    {" "}
                                    {formatAsPercent(
                                      ((data.actualPrice - data.discountPrice) /
                                        data.actualPrice) *
                                        100
                                    )}{" "}
                                    OFF
                                  </span>
                                </span>
                                <div className="actions"></div>
                                {/* hover cta */}
                                <div className="add-cart-btn">
                                  <span
                                    href="#"
                                    className="btn btn-whited btn-hover-primary text-capitalize add-to-cart"
                                    data-bs-toggle="modal"
                                    onClick={() => SingleProduct(data?.id)}
                                    data-bs-target="#quick-view"
                                  >
                                    Quick View
                                  </span>
                                </div>
                              </div>
                              {/* description */}
                              <div className="content">
                                <h5 className="title">
                                  {" "}
                                  <a
                                    href={
                                      "/productDetails/" +
                                      singleProduct[variantSelectedColor]
                                        ?.productId
                                    }
                                  >
                                    {data.productName}
                                  </a>
                                </h5>

                                <div
                                  style={{
                                    display: "flex",

                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <span className="price">
                                    <span className="new">
                                      {" "}
                                      ₹{numberWithCommas(data?.discountPrice)}
                                    </span>
                                    <span className="old">
                                      {" "}
                                      ₹{numberWithCommas(data?.actualPrice)}
                                    </span>
                                  </span>

                                  {/* wishlist */}
                                  <div>
                                    <span
                                      title="add to wishlist"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        dispatch(
                                          addWishLists({
                                            productId: data?.id,
                                          })
                                        ).then((res) => {
                                          window.location.href =
                                            "/productdetails/" + proid;
                                          toast(res.data);
                                          setRender(render);
                                        });
                                      }}
                                    >
                                      {data?.favourites === 0 ? (
                                        <i
                                          className="bx bx-heart"
                                          style={{ fontSize: "26px" }}
                                        ></i>
                                      ) : (
                                        <i
                                          style={{
                                            fontSize: "26px",
                                            color: "#FE036A",
                                          }}
                                          className="bx bxs-heart"
                                        ></i>
                                      )}
                                    </span>
                                  </div>
                                  {/* end */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
              : false}
          </div>
        </div>
      </div>

      {/* modal */}
      <div
        className="modalquickview modal fade"
        id="quick-view"
        tabIndex="-1"
        aria-labelledby="quick-view"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {loader ? (
              <div
                className="sweet-loading"
                style={{ margin: "193px", display: "flex" }}
              >
                <ClipLoader loading={loading} cssOverride={override} />
              </div>
            ) : (
              <div className="container">
                <button className="btn close" data-bs-dismiss="modal">
                  ×
                </button>

                {Array.isArray(singleProduct) && singleProduct.length > 0 ? (
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="modal-product-carousel">
                        <div className="swiper-container">
                          <div className="swiper-wrapper">
                            <a className="swiper-slide" href="#">
                              <img
                                className="w-100"
                                src={
                                  process.env.REACT_APP_URL +
                                  JSON?.parse(
                                    singleProduct[variantSelectedColor]
                                      ?.variantImage
                                  )[variantSelectedColor]
                                }
                                alt="Product"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12 overflow-hidden position-relative">
                      <div className="product-summery position-relative">
                        <h5
                          className="product-head mb-3"
                          style={{ textTransform: "capitalize" }}
                        >
                          <a
                            href={
                              "/productDetails/" +
                              singleProduct[variantSelectedColor]?.productId
                            }
                          >
                            {singleProduct[variantSelectedColor].productName}
                          </a>
                        </h5>

                        <div className="price-box mb-2">
                          <span className="regular-price">
                            {" "}
                            ₹
                            {numberWithCommas(
                              JSON?.parse(
                                singleProduct[variantSelectedColor]
                                  ?.discountPrice
                              )[variantSelectedColor]
                            )}
                          </span>
                          <span className="old-price">
                            <del>
                              {" "}
                              ₹{" "}
                              {numberWithCommas(
                                JSON?.parse(
                                  singleProduct[variantSelectedColor]
                                    ?.actualPrice
                                )[variantSelectedColor]
                              )}
                            </del>
                          </span>
                        </div>

                        <p
                          className="desc-content mb-5"
                          style={{
                            textAlign: "justify",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {Truncate(productspecs?.productDescription, 240)}
                        </p>

                        <div className="quantity d-flex align-items-center mb-5">
                          <span className="me-2">
                            <strong>Qty: </strong>
                          </span>

                          {/* <QuantitySettingBtn getQuantity={getQuantity} /> */}
                        </div>

                        <div className="cart-btn mb-4">
                          <div className="add-to_cart">
                            <a
                              className="btn btn-dark btn-hover-primary"
                              onClick={() => {
                                cartId.variantId =
                                  singleProduct[variantSelectedColor].id;
                                cartId.units = quantity;
                                cartId.variantColor = JSON.parse(
                                  singleProduct[variantSelectedColor]
                                    .variantColor
                                )[variantSelectedColor];
                                dispatch(addCarts(cartId)).then((res) => {
                                  toast(res.data.data);
                                });
                              }}
                            >
                              Add to cart
                            </a>
                          </div>
                        </div>

                        <span>
                          <a
                            href={
                              "/productDetails/" +
                              singleProduct[variantSelectedColor]?.productId
                            }
                            style={{
                              fontSize: "18px",
                              textDecoration: "underline",
                            }}
                          >
                            View More Details
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  false
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
