import React, { useState } from "react";
import { authenticateLogin, registerUser, logoutUser } from "../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
// import { AiOutlineHome } from 'react-icons/ai'

const Login = () => {
  const dispatch = useDispatch();
  const [passworderror, setPassworderror] = useState("");

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.password.length > 3) {
      dispatch(authenticateLogin(user))
        .then((res) => {
          if (res.data.data === "Wrong Email / Password. Try A  !") {
            setPassworderror(res.data.data);
            setTimeout(() => {
              setPassworderror("");
            }, 3000);
          } else {
            window.location.href = "/";
          }
        })
        .catch((err) => {
          setPassworderror(err.response.data.error.message);
        });
    } else {
      setPassworderror("Password Must Be  Greater Than 3 Letters");
    }
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  // const handleClickcouont = () => {
  //   setCount(!count);
  // };
  // const logout = () => {
  //   dispatch(logoutUser());
  //   localStorage.removeItem("AuthTok");

  //   window.location.href = "/";
  // };

  const [userDetails, setUserDetails] = useState({
    userName: "",
    email: "",
    password: "",
    phone: "",
    dob: "",
    gender: "",
    fcmToken: "wertyuio45frtgyh",
  });

  const [err, setErr] = useState("");
  const [errL, setErrL] = useState("");
  const Swal = require("sweetalert2");
  // const handleRegisterSubmit = (e) => {
  //   e.preventDefault();
  //   e.currentTarget.reset();

  //   if (userDetails.phone.length !== 10) {
  //     setErr("Mobile Number Should be 10");
  //     setTimeout(() => {
  //       setErrL("");
  //     }, 3000);
  //   } else if (userDetails.password.length < 5) {
  //     setErrL("Minimum Password Length Required");
  //     setTimeout(() => {
  //       setErrL("");
  //     }, 5000);
  //   } else {
  //     dispatch(registerUser(userDetails))

  //   }
  // };
  // const handleRegisterChange = (e) => {
  //   setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  // };
  return (
    <div>
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active"> Login</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-margin">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 m-auto">
              <div className="login-wrapper">
                <div className="section-content text-center mb-5">
                  <h2 className="title mb-2">Login</h2>
                  <p className="desc-content">
                    Please login using account detail below.
                  </p>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="single-input-item mb-3">
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      placeholder="Enter your Email"
                    />
                  </div>

                  <div className="single-input-item mb-3">
                    <input
                      type="password"
                      name="password"
                      onChange={handleChange}
                      placeholder="Enter your Password"
                    />
                  </div>

                  <span>{errL} </span>
                  {passworderror ? (
                    <div
                      className="error-text"
                      style={{
                        color: "red",
                        color: "red",
                        width: "299px",
                        background: "blanchedalmond",
                        fontSize: "14px",
                        display: "block",
                      }}
                    >
                      {passworderror}
                    </div>
                  ) : (
                    false
                  )}

                  <div className="single-input-item   mb-3">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="login-reg-form-meta d-flex align-items-center justify-content-between">
                        <Link
                          to={"/forgotpassword"}
                          className="forget-pwd mb-3"
                        >
                          Forgot Password?
                        </Link>
                      </div>

                      <div className="lost-password">
                        <Link style={{ textDecoration: "none" }} to="/register">
                          Create Account
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="single-input-item mb-3">
                    <button
                      className="btn btn btn-dark btn-hover-primary rounded-0"
                      type="submit"
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
