import React, { useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { authenticateLogin, registerUser } from "../Redux/Action";
import { useDispatch } from "react-redux";
import moment from "moment";

import { countryCodes } from "../helperComponents/data";

import cusStyle from "../Components/customStyle.module.css";

const Register = () => {
  const today = moment();
  const [passworderror, setPassworderror] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [countryCode, setcountryCode] = useState(91);

  //register user

  const [userDetails, setUserDetails] = useState({
    userName: "",
    email: "",
    password: "",
    phone: "",
    dob: "",
    fcmToken: "wertyuio45frtgyh",
  });

  // const [err, setErr] = useState("");
  // const [errL, setErrL] = useState("");
  const Swal = require("sweetalert2");

  const handleRegisterSubmit = (e) => {
    e.preventDefault();

    const finalData = {
      ...userDetails,
      phone: "+" + countryCode + userDetails.phone,
    };

    dispatch(registerUser(finalData))
      .then((res) => {
        Swal.fire({
          icon: "success",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: res.data.data,
        }).then(() => {
          navigate("/login", { replace: true });
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: err.response.data.error.message,
        });
      });
    // e.currentTarget.reset();
  };
  const handleRegisterChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  //confirm password check start
  const [confirmPassword, setconfirmPassword] = useState(false);
  function retypeConfirm(e) {
    if (e.target.value.length !== 0 && e.target.value !== userDetails.password)
      return setconfirmPassword(true);
    setconfirmPassword(false);
  }
  //confirm password check end

  const dateField = useRef();

  return (
    <div>
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active"> Register Page</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-margin">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 m-auto">
              <div className="register-wrapper">
                <div className="section-content text-center mb-5">
                  <h2 className="title mb-2">Create Account</h2>
                  <p className="desc-content">
                    Register your Bhumi Doris account
                  </p>
                </div>
                <form
                  onSubmit={
                    // setUserDetails(
                    //   (pre) => (pre.phone = countryCode + pre.phone)
                    // );

                    handleRegisterSubmit
                  }
                >
                  <div className="single-input-item mb-3">
                    <input
                      required
                      type="text"
                      onChange={handleRegisterChange}
                      name="userName"
                      placeholder="Name"
                    />
                  </div>
                  <div className="single-input-item mb-3">
                    <input
                      type="email"
                      placeholder="Email"
                      onChange={handleRegisterChange}
                      name="email"
                      required
                    />
                  </div>

                  {/* <div className="single-input-item mb-3">
                    <input
                      type="tel"
                      maxlength="10"
                      name="phone"
                      required
                      onChange={handleRegisterChange}
                      placeholder="Phone Number"
                    />
                  </div> */}
                  <div className="single-input-item mb-3">
                    <div className="row ">
                      <div className="col-4">
                        <select
                          defaultValue={countryCode}
                          className={`${cusStyle.selectStyle}`}
                          onChange={(e) => {
                            setcountryCode(e.target.value);
                          }}
                          name="countryCode"
                        >
                          <option hidden></option>
                          {countryCodes?.map((code) => {
                            return (
                              <option value={code.toString()}>+{code}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-8">
                        <input
                          type="tel"
                          name="phone"
                          required
                          onChange={handleRegisterChange}
                          placeholder="Phone Number"
                          pattern="^[0-9]*$"
                          title="only digits"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="single-input-item mb-3">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",

                        justifyContent: "space-around",
                      }}
                    >
                      <input
                        type="password"
                        name="password"
                        onChange={handleRegisterChange}
                        required
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%]).{8,16}$"
                        title="Must contain at least one number, uppercase, lowercase, special symbol among @#$%, and at least 8 to 16 characters"
                        placeholder="Password"
                        onError={(e) => {}}
                        style={{ borderRight: "none" }}
                      />
                    </div>
                  </div>

                  <div className="single-input-item mb-3">
                    <input
                      type="password"
                      onChange={retypeConfirm}
                      required
                      placeholder="Confirm Password"
                    />
                    <span style={{ color: "#dc3545e0" }}>
                      {" "}
                      {confirmPassword &&
                        "Confirm Password & Password not matched"}
                    </span>
                  </div>

                  <div className="single-input-item mb-3">
                    <input
                      type="text"
                      max={today.format("YYYY-MM-DD")}
                      ref={dateField}
                      name="dob"
                      required
                      onChange={handleRegisterChange}
                      onFocus={() => {
                        dateField.current.type = "date";
                      }}
                      onBlur={() => {
                        dateField.current.type = "text";
                      }}
                      placeholder="Date of Birth"
                    />
                  </div>

                  <div className="single-input-item mb-3">
                    <button
                      type="submit"
                      className="btn btn btn-dark btn-hover-primary rounded-0"
                    >
                      Register
                    </button>
                  </div>
                </form>
                <p>
                  Already have an account?
                  <span
                    onClick={() => {
                      navigate("/login");
                    }}
                    style={{
                      cursor: "pointer",
                      color: "#000",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    Login
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
