import React, { useState, useEffect } from "react";

import { getUserAddress, deleteUserAddress } from "../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

const UpdateAddress = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const Cid = id;
  const [load, setLoad] = useState(true);
  const [user, setUser] = useState({
    image: "",
    username: "",
    alaisname: "",
    dob: "",
    sex: "",
  });
  const Address = useSelector(({ getUserAddress }) => getUserAddress.payload);
  const [address, setAddress] = useState({
    state: "",
    city: "",
    street: "",
    landmark: "",
    zipcode: "",
    primary: "",
    district: "",
    addressType: "",
  });

  useEffect(() => {
    dispatch(getUserAddress()).then((res) => {
      res.data.data
        .filter((F) => F.id === Cid)
        .forEach((A) => {
          setAddress({
            state: A.state,
            city: A.city,
            street: A.street,
            landmark: A.landmark,
            zipcode: A.zipcode,
            primary: A.primary,
            district: A.district,
            addressType: A.addressType,
          });
        });
    });
  }, []);

  const handleChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      deleteUserAddress({ ...address, action: "edit", addressId: Cid })
    ).then((res) => {
      if (res.data.data === "Update Success")
        window.location.href = "/myaccount";
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <main className="main-content">
        <div className="section">
          <div className="breadcrumb-area bg-primary">
            <div className="container">
              <div className="breadcrumb-content">
                <ul>
                  <li>
                    <Link to={"/"}>
                      <i className="fa fa-home"></i>{" "}
                    </Link>
                  </li>
                  <li className="active">update Address</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row py-5"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="col-lg-8 ">
            <div className="checkout-billing-details-wrap">
              <h2 className="title">Add Address</h2>
              <div className="billing-form-wrap">
                <form action="#" onSubmit={handleSubmit}>
                  <div className="row ">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label for="f_name">
                          Street{" "}
                          <abbr className="required" title="required">
                            *
                          </abbr>
                        </label>
                        <input
                          type="text"
                          name="street"
                          value={address.street}
                          onChange={handleChange}
                          placeholder="Street"
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label for="l_name">
                          City{" "}
                          <abbr className="required" title="required">
                            *
                          </abbr>
                        </label>
                        <input
                          type="text"
                          name="city"
                          value={address.city}
                          onChange={handleChange}
                          placeholder="City"
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label for="com_name">
                          District{" "}
                          <abbr className="required" title="required">
                            *
                          </abbr>
                        </label>
                        <input
                          type="text"
                          name="district"
                          value={address.district}
                          onChange={handleChange}
                          placeholder="District"
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label for="street-address">
                          Landmark
                          <abbr className="required" title="required">
                            *
                          </abbr>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="landmark"
                          value={address.landmark}
                          onChange={handleChange}
                          required
                          placeholder="Landmark"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label for="town">
                          State{" "}
                          <abbr className="required" title="required">
                            *
                          </abbr>
                        </label>
                        <input
                          type="text"
                          name="state"
                          value={address.state}
                          onChange={handleChange}
                          placeholder="State"
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label for="pz-code">
                          {" "}
                          zipcode{" "}
                          <abbr className="required" title="required">
                            *
                          </abbr>{" "}
                        </label>
                        <input
                          type="number"
                          name="zipcode"
                          value={address.zipcode}
                          onChange={handleChange}
                          placeholder="Zipcode"
                          required
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="country">
                          Address Type
                          <abbr className="required" title="required">
                            *
                          </abbr>
                        </label>
                        <select
                          className="form-select"
                          value={address.addressType}
                          onChange={handleChange}
                          name="addressType"
                          aria-label="Default select example"
                          style={{
                            marginLeft: "0px",
                            height: "49px",
                            marginBottom: "15px",
                          }}
                        >
                          <option hidden> Choose Address</option>
                          <option value="home">Home </option>
                          <option value="office">Office</option>
                          <option value="other">Others</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="country">
                          Primary Address
                          <abbr className="required" title="required">
                            *
                          </abbr>
                        </label>
                        <select
                          className="form-select"
                          value={address.primary}
                          onChange={handleChange}
                          name="primary"
                          aria-label="Default select example"
                          style={{
                            marginLeft: "0px",
                            height: "49px",
                            marginBottom: "15px",
                          }}
                          // style={{maxWidth:"529px",marginLeft:"0px",height:"49px",marginBottom:"15px"}}
                        >
                          <option hidden> Is Primary</option>
                          <option value="yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </div>
                    <div
                      className="myaccount-content"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "30px 30px 15px",
                        border: "none",
                      }}
                    >
                      <button
                        className="btn btn btn-dark btn-hover-primary"
                        type="submit"
                        style={{
                          outline: "0",
                          marginBottom: "25px",
                          letterSpacing: "0.1rem",
                        }}
                      >
                        Add Address{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UpdateAddress;
