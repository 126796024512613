import homeStyle from "../Components/Home.module.css";

import React, { useEffect, useState, useContext } from "react";
import { ActivePageContext } from "./ActivePage";

import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { MdLogout } from "react-icons/md";
import { FaRegTimesCircle } from "react-icons/fa";

import {
  getCarts,
  getCategories,
  updateCarts,
  removeCarts,
  checkOuts,
  payNows,
  getWelcomeMessages,
} from "../Redux/Action";

import { useNavigate, Link } from "react-router-dom";
import { logoutUser, getCategoryProducts } from "../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import logo from "../Assets/logo.svg";

const Header = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [load, setLoad] = useState(true);
  const [quantity, setQuantity] = useState("");
  const [render, setRender] = useState(false);
  const getCart = useSelector(({ getCarts }) => getCarts.payload);
  const jquery = window.$;

  const wish = useSelector(({ getWishLists }) => getWishLists.payload);
  const Count = useSelector(({ getCartCounts }) => getCartCounts.payload);

  const welcomeInfo = useSelector(
    ({ getWelcomeMessages }) => getWelcomeMessages.payload
  );

  const Token = localStorage.getItem("AuthTok");

  useEffect(() => {
    setTimeout(() => {
      // search
      window.$(".header-action-btn-search").on("click", function () {
        window.$("body").addClass("fix");
        window.$(".offcanvas-search").addClass("open");
      });

      window.$(".offcanvas-btn-close,.body-overlay").on("click", function () {
        window.$("body").removeClass("fix");
        window.$(".offcanvas-search").removeClass("open");
      });
      // search end

      // mobile menu
      window.window.$(".header-action-btn-cart").on("click", function () {
        window.$("body").addClass("fix");
        window.$(".cart-offcanvas-wrapper").addClass("open");
      });

      window
        .$(".offcanvas-btn-close,.offcanvas-overlay")
        .on("click", function () {
          window.$("body").removeClass("fix");
          window.$(".cart-offcanvas-wrapper").removeClass("open");
        });

      // shop grid activation

      window.$(".shop_toolbar_btn > button").on("click", function (e) {
        e.preventDefault();

        window.$(".shop_toolbar_btn > button").removeClass("active");
        window.$(this).addClass("active");

        var parentsDiv = window.$(".shop_wrapper");
        var viewMode = window.$(this).data("role");

        parentsDiv
          .removeClass("grid_3 grid_4 grid_5 grid_list")
          .addClass(viewMode);

        if (viewMode == "grid_3") {
          parentsDiv
            .children()
            .addClass("col-lg-4 col-md-4 col-sm-6")
            .removeClass("col-lg-3 col-cust-5 col-12");
        }

        if (viewMode == "grid_4") {
          parentsDiv
            .children()
            .addClass("col-xl-3 col-lg-4 col-md-4 col-sm-6")
            .removeClass("col-lg-4 col-cust-5 col-12");
        }

        if (viewMode == "grid_list") {
          parentsDiv
            .children()
            .addClass("col-12")
            .removeClass(
              "col-xl-3 col-lg-3 col-lg-4 col-md-6 col-md-4 col-sm-6 col-cust-5"
            );
        }
      });
      // // mobile menu
      window.$(".header-action-btn-menu").on("click", function () {
        window.$("body").addClass("fix");
        window.$(".mobile-menu-wrapper").addClass("open");
      });

      window
        .$(".offcanvas-btn-close,.offcanvas-overlay")
        .on("click", function () {
          window.$("body").removeClass("fix");
          window.$(".mobile-menu-wrapper").removeClass("open");
        });

      /*----------------------------------------*/
      /* Toggle Function Active
            /*----------------------------------------*/

      // showlogin toggle
      window.$("#showlogin").on("click", function () {
        window.$("#checkout-login").slideToggle(500);
      });
      // showlogin toggle
      window.$("#showcoupon").on("click", function () {
        window.$("#checkout_coupon").slideToggle(500);
      });
      // showlogin toggle
      window.$("#cbox").on("click", function () {
        window.$("#cbox-info").slideToggle(500);
      });

      // Ship box toggle
      window.$("#ship-box").on("click", function () {
        window.$("#ship-box-info").slideToggle(1000);
      });

      /*----------------------------------------*/
      /*  Cart Plus Minus Button
	/*----------------------------------------*/

      window.$(".qtybutton").on("click", function () {
        var $button = window.$(this);
        var oldValue = $button.parent().find("input").val();
        if ($button.hasClass("inc")) {
          var newVal = parseFloat(oldValue) + 1;
        } else {
          // Don't allow decrementing below zero
          if (oldValue > 1) {
            var newVal = parseFloat(oldValue) - 1;
          } else {
            newVal = 1;
          }
        }
        $button.parent().find("input").val(newVal);
      });

      function scrollToTop() {
        var $scrollUp = window.$("#scroll-top"),
          $lastScrollTop = 0,
          $window = window.$(window);

        $window.on("scroll", function () {
          var st = window.$(this).scrollTop();
          if (st > $lastScrollTop) {
            $scrollUp.removeClass("show");
          } else {
            if ($window.scrollTop() > 200) {
              $scrollUp.addClass("show");
            } else {
              $scrollUp.removeClass("show");
            }
          }
          $lastScrollTop = st;
        });

        $scrollUp.on("click", function (evt) {
          window.$("html, body").animate({ scrollTop: 0 }, 600);
          evt.preventDefault();
        });
      }
    }, 100);
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    navigate(`${"/searchProducts/" + search}`);
  };

  const category = useSelector(({ getCategories }) => getCategories.payload);
  const catshop = (id) => {
    dispatch(getCategoryProducts({ categoryId: id + "" })).then((res) =>
      setLoad(false)
    );
    navigate("/categoryShop/" + id);
  };

  useEffect(() => {}, [getCart]);

  useEffect(() => {
    dispatch(getCarts())
      .then((res) => setLoad(false))
      .catch((res) => setLoad(false));
  }, [render]);

  useEffect(() => {
    dispatch(getCategories()).then((res) => setLoad(false));

    dispatch(getWelcomeMessages()).then((res) => {
      setLoad(false);
    });
  }, []);

  useEffect(() => {
    if (getCart?.cart) {
      setQuantity(
        getCart?.cart?.map((A, index) => <span key={index}>{A.units}</span>)
      );
    }
  }, [getCart]);

  //logout user
  const logout = () => {
    dispatch(logoutUser());
    localStorage.removeItem("AuthTok");
    window.location.href = "/";
  };

  const checkOut = () => {
    const Swal = require("sweetalert2");

    dispatch(checkOuts()).then((res) => {
      var config = {
        root: "",
        style: {
          bodyBackgroundColor: "#fafafb",
          bodyColor: "",
          themeBackgroundColor: "#0FB8C9",
          themeColor: "#ffffff",
          headerBackgroundColor: "#284055",
          headerColor: "#ffffff",
          errorColor: "",
          successColor: "",
          card: {
            padding: "",
            backgroundColor: "",
          },
        },
        data: {
          orderId: res.data.data.orderId,
          token: res.data.data.txnToken,
          tokenType: "TXN_TOKEN",
          amount: res.data.data.discountPrice /* update amount */,
        },
        payMode: {
          labels: {},
          filter: {
            exclude: [],
          },
          order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
        },
        website: "WEBSTAGING",
        flow: "DEFAULT",
        merchant: {
          mid: "sKxlKi69872184882034",
          redirect: false,
        },
        handler: {
          transactionStatus: function transactionStatus(paymentStatus) {
            dispatch(payNows(paymentStatus));

            window.location.href = "/ordersuccess";
          },
          notifyMerchant: function notifyMerchant(eventName, data) {},
        },
      };
      if (window.Paytm && window.Paytm.CheckoutJS) {
        window.Paytm.CheckoutJS.init(config)

          .then(function onSuccess() {
            window.Paytm.CheckoutJS.invoke();
          })
          .catch(function onError(error) {});
      }
    });
  };
  const token = localStorage.getItem("AuthTok");

  const [welcomeTopTextShow, setWelcomeTopTextShow] = useState(true);

  // active page in nav
  const pageName = useContext(ActivePageContext).value;

  return (
    <div>
      <div className="header section ">
        <div className="header-bottom">
          <div className="header-sticky fixed-top">
            {/* header top */}
            <div
              className={`${homeStyle.offerHeaderTop} `}
              style={{ display: `${welcomeTopTextShow ? "flex" : "none"}` }}
            >
              <p style={{ margin: "0px" }}>{welcomeInfo?.message}</p>
              <span
                onClick={() => {
                  setWelcomeTopTextShow(!welcomeTopTextShow);
                }}
              >
                <FaRegTimesCircle />
              </span>
            </div>
            {/* header bottom */}
            <div className="container ">
              <div className="row align-items-center position-relative">
                <div className="col-md-6 col-lg-3 col-xl-2 col-6">
                  <div className="header-logo">
                    <a
                      className="widget-logo"
                      href="/"
                      style={{
                        width: "20px",
                        height: "30px",
                      }}
                    >
                      <img src={logo} alt="Site Logo" />
                    </a>
                  </div>
                </div>

                <div className="col-lg-6 d-none d-lg-block">
                  <div className="main-menu">
                    <ul>
                      <li className="has-children">
                        <Link
                          className={`${pageName === "home" ? "active" : ""}`}
                          to={"/"}
                        >
                          Home
                        </Link>
                      </li>
                      <li className="has-children">
                        <Link
                          className={`${
                            pageName === "category" ? "active" : ""
                          }`}
                          to={"/category"}
                        >
                          Categories <i className="fa fa-angle-down"></i>
                        </Link>

                        <ul className="sub-menu">
                          {Array?.isArray(category) && category?.length !== 0
                            ? category?.map((data, index) => (
                                <li key={index + 1}>
                                  <a
                                    style={{
                                      textTransform: "capitalize",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => catshop(data?.id)}
                                  >
                                    {data?.categoryName}
                                  </a>
                                </li>
                              ))
                            : false}
                        </ul>
                      </li>

                      <li className="has-children position-static">
                        <Link
                          className={`${pageName === "shop" ? "active" : ""}`}
                          to={"/product"}
                        >
                          Shop
                        </Link>
                      </li>

                      <li>
                        <Link
                          className={`${pageName === "about" ? "active" : ""}`}
                          to={"/about"}
                        >
                          About
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${
                            pageName === "contact" ? "active" : ""
                          }`}
                          to={"/contact"}
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3 col-xl-4 col-6 justify-content-end">
                  <div className="header-actions">
                    <span
                      style={{ cursor: "pointer" }}
                      className="header-action-btn header-action-btn-search d-none d-lg-block"
                    >
                      {" "}
                      <AiOutlineSearch
                        style={{ height: "24px", width: "24px" }}
                      />
                    </span>

                    <div className="dropdown-user  d-lg-block">
                      <Link
                        to={Token ? "/myaccount" : "/login"}
                        className="header-action-btn"
                      >
                        <AiOutlineUser
                          style={{ height: "24px", width: "24px" }}
                        />
                      </Link>
                    </div>

                    <Link
                      to={Token ? "/wishlist" : "/login"}
                      className="header-action-btn header-action-btn-wishlist"
                    >
                      <AiOutlineHeart
                        style={{ height: "24px", width: "24px" }}
                      />
                    </Link>

                    <Link
                      to={Token ? "/cart" : "/login"}
                      className="header-action-btn header-action-btn-cart"
                    >
                      <AiOutlineShoppingCart
                        style={{ height: "24px", width: "24px" }}
                      />

                      {Count?.count > 0 ? (
                        <sup className={`${homeStyle.cartCount} `}>
                          {Count.count}
                        </sup>
                      ) : (
                        <sup className={`${homeStyle.cartCount} `}> 0</sup>
                      )}
                    </Link>

                    {Token ? (
                      <Link
                        to={"/"}
                        className="header-action-btn header-action-btn-cart"
                      >
                        <MdLogout
                          onClick={logout}
                          style={{ height: "24px", width: "24px" }}
                        />
                      </Link>
                    ) : (
                      false
                    )}

                    <span
                      style={{ cursor: "pointer" }}
                      className="header-action-btn header-action-btn-menu d-lg-none d-md-block"
                    >
                      <i className="fa fa-bars"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Search Modal start*/}
      <div className="offcanvas-search">
        <div className="offcanvas-search-inner">
          <div className="offcanvas-btn-close">
            <i className="pe-7s-close"></i>
          </div>

          <form className="offcanvas-search-form" onSubmit={handleClick}>
            <input
              type="text"
              placeholder="Search Product..."
              onChange={(e) => setSearch(e.target.value)}
              className="offcanvas-search-input"
              // required
              // title="please enter query to look for"
            />

            <button
              className="btn btn-dark btn-hover-primary m-4 offcanvas-btn-close"
              style={{
                fontSize: "19px",
              }}
              type="submit"
            >
              {" "}
              search
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Header;
