import React, { useState, useEffect } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getProfiles, updateProfiles } from "../Redux/Action";

const UpdateProfile = () => {
  const [load, setLoad] = useState(true);
  const [user, setUser] = useState({
    username: "",
    dob: "",
    email: "",
    gender: "",
  });
  const [prevImg, setPrevImg] = useState("../images/profilepictrue.png");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(user).map(([key, value]) => formData.append(key, value));
    dispatch(updateProfiles(formData))
      .then((res) => {
        setUser({
          image: "",
          username: "",
          phone: "",
          dob: "",
          sex: "",
          gender: "",
          email: "",
        });

        Swal.fire({
          icon: "success",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: res.data.data,
        }).then(() => {
          navigate("/myaccount", { replace: true });
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: err.response.data.error.message,
        });
      });
  };
  useEffect(() => {
    dispatch(getProfiles()).then(
      (res) => {
        setUser({
          ...user,
          username: res.data.data.userName,
          phone: res.data.data.phone,
          dob: res.data.data.dob,
          gender: res.data.data.gender,
          email: res.data.data.email,
        });
        if (res.data.data.profilePic) setPrevImg(res.data.data.profilePic);
      },
      dispatch(getProfiles()).then((res) => setLoad(false))
    );
  }, []);

  return (
    <div>
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active">update profile</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-margin">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="myaccount-page-wrapper">
                <div className="row">
                  <div className="col-lg-2 col-md-4"></div>

                  <div className="col-lg-10 col-md-8">
                    <div className="myaccount-content">
                      {/* <h3 className="title">Account Details</h3>  */}
                      <div className="account-details-form">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="single-input-item mb-3">
                                <label
                                  for="first-name"
                                  className="required mb-1"
                                >
                                  Username *
                                </label>
                                <input
                                  type="text"
                                  id="first-name"
                                  name="username"
                                  value={user.username}
                                  onChange={handleChange}
                                  placeholder="Username "
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="single-input-item mb-3">
                                <label
                                  for="last-name"
                                  className="required mb-1"
                                >
                                  Email *
                                </label>
                                <input
                                  disabled
                                  type="text"
                                  onChange={handleChange}
                                  value={user.email}
                                  name="email"
                                  id="last-name"
                                  placeholder="Email  "
                                />
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="single-input-item mb-3">
                                <label
                                  for="last-name"
                                  className="required mb-1 "
                                >
                                  Phone number*{" "}
                                </label>
                                <input
                                  type="text"
                                  id="last-name"
                                  value={user.phone}
                                  name="phone"
                                  onChange={handleChange}
                                  disabled
                                  placeholder="Phone number "
                                />
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="single-input-item mb-3">
                                <label
                                  for="first-name"
                                  className="required mb-1"
                                >
                                  DOB *
                                </label>
                                <input
                                  type="date"
                                  id="first-name"
                                  value={user.dob}
                                  name="dob"
                                  onChange={handleChange}
                                  placeholder="DOB "
                                />
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="single-input-item mb-3">
                                <label
                                  for="last-name"
                                  className="required mb-1"
                                >
                                  Gender *
                                </label>
                                <input
                                  type="text"
                                  id="last-name"
                                  name="gender"
                                  value={user.gender}
                                  onChange={handleChange}
                                  placeholder="Gender "
                                />
                              </div>
                            </div>
                          </div>
                          <div className="single-input-item single-item-button">
                            <button
                              className="btn btn btn-dark btn-hover-primary rounded-0"
                              type="submit"
                            >
                              Update Profile
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProfile;
