import React, { useEffect, useState } from "react";
import { bulkOrderEnquiry } from "../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
const Contact = () => {
  const dispatch = useDispatch();
  const [orderError, setOrderError] = useState("");
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Swal = require("sweetalert2");
  const navigate = useNavigate();

  const bulkOrderChange = (e) => {
    setOrderData({ ...orderData, [e.target.name]: e.target.value });
  };

  // useEffect(() => {
  //     dispatch(bulkOrderEnquiry(orderData))
  // }, [])

  const bulkOrderSubmit = (e) => {
    e.preventDefault();
    dispatch(bulkOrderEnquiry(orderData))
      .then((res) => {
        Swal.fire({
          icon: "success",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: res.data.data,
        });
      })
      .catch((err) => {});

    // .then((res) => {
    // //    console.log (res ,"test")
    //     alert("test")
    //     Swal.fire({
    //         icon: "success",
    //         timer: 4000,
    //         text: "Enqueries Submitted Succcessfully!",
    //     }).then(() => {
    //         navigate("", { replace: true });
    //     })
    // })
    // .catch((err) => {
    //
    //     Swal.fire({
    //         icon: "error",
    //         timer: 4000,
    //         text: "Something Went Wrong!",
    //     }).then(() => {
    //         navigate("", { replace: true });
    //     })
    //     //   setOrderError("Email Id and Password Are Invalid");
    // });
  };

  return (
    <div>
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active"> Contact us</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="section"  style={{display:"block!important"}}> */}

      <div className="section section-margin">
        <div className="container">
          <div className="row mb-n10">
            <div className="col-12 col-lg-6 mb-10 order-2 order-lg-1">
              <div className="contact-title pb-3">
                <h2 className="title">Contact For Wholesale </h2>
              </div>

              <div className="contact-form-wrapper contact-form">
                <form
                  action="assets/php/toyqo.php"
                  id="contact-form"
                  method="post"
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-area mb-4">
                            <input
                              className="input-item"
                              placeholder="Enter your  Name"
                              onChange={bulkOrderChange}
                              required
                              type="text"
                              name="companyName"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-area mb-4">
                            <input
                              className="input-item"
                              placeholder="Enter Your Email"
                              onChange={bulkOrderChange}
                              required
                              type="email"
                              name="companyEmail"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="input-area mb-4">
                            <input
                              className="input-item"
                              name="companyContact"
                              onChange={bulkOrderChange}
                              placeholder="Enter Your Contact No"
                              required
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="input-area mb-8">
                            <textarea
                              cols="30"
                              rows="5"
                              placeholder="Enter Your Message"
                              onChange={bulkOrderChange}
                              style={{ borderColor: "#d9d6d9" }}
                              required
                              className="textarea-item"
                              name="message"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            onClick={bulkOrderSubmit}
                            id="submit"
                            name="submit"
                            className="btn btn-secondary button-hover-primary"
                          >
                            Send A Message
                          </button>
                        </div>
                        <p className="col-8 form-message mb-0"></p>
                      </div>
                    </div>
                  </div>
                </form>
                <p className="form-messege"></p>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-10 order-1 order-lg-2">
              <div className="contact-title ">
                <h2 className="title">Contact Us</h2>
              </div>

              <div className="contact-content">
                {/* <p >Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram anteposuerit litterarum formas human.</p> */}
                <p className="fs-15">
                  We would love to hear from you. Write in with your feedback,
                  suggestions, complaints ... or even a story you'd like to
                  share
                </p>
                <p>For general queries you can mail</p>
                <address className="contact-block">
                  <ul className="contact-links">
                    <li
                      style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      <i style={{ color: "black" }} className="pe-7s-home"></i>{" "}
                      <span style={{ color: "black" }}>
                        583,59/2A1,Katcheri street, Velur road, Valaraigate,
                        Tiruchengode-637211
                      </span>{" "}
                    </li>
                    <li
                      style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      <i className="pe-7s-mail" style={{ color: "black" }}></i>
                      <a
                        href="mailto:info@bhumidoris.com"
                        style={{ color: "black" }}
                      >
                        info@bhumidoris.com
                      </a>
                    </li>
                    <li
                      style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      <i className="pe-7s-call" style={{ color: "black" }}></i>
                      <a href="tel: +919842757058" style={{ color: "black" }}>
                        {" "}
                        +91 98427 57058
                      </a>
                    </li>
                  </ul>

                  {/* <ul>

                                        <li ><i className="fa fa-phone"></i> <a href="tel:123-123-456-789"> +91 98427 57058</a></li>
                                        <li ><i className="fa fa-envelope-o"></i> <a href="mailto:info@bhumidoris.com">info@bhumidoris.com</a></li>
                                    </ul> */}
                </address>

                {/* <div className="working-time" >
                                    <h6 className="title">Working Hours</h6>
                                    <p>Monday – Saturday:08AM – 22PM</p>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="google-map-area w-100">
        <iframe
          className="contact-map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3911.550139356299!2d77.8892453!3d11.3675329!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba9610cbc90eb7f%3A0xc93c2ddccf6781ac!2sBhumi%20Doris!5e0!3m2!1sen!2sin!4v1669965656444!5m2!1sen!2sin"
        ></iframe>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Contact;
