import homeStyle from "./Home.module.css";

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCategories } from "../Redux/Action";

import ClipLoader from "react-spinners/ClipLoader";

const Categories = () => {
  const category = useSelector(({ getCategories }) => getCategories.payload);
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const catshop = (id) => {
    navigate("/categoryShop/" + id);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getCategories()).then((res) => {
      setLoad(false);
    });
  }, []);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#ff3e3e",
  };
  return load ? (
    <div className="row">
      <div className="col-lg-12">
        <div
          className="sweet-loading"
          style={{ margin: "193px", display: "flex" }}
        >
          <ClipLoader cssOverride={override} />
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active"> Categories</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-padding">
        <div className="container">
          {/* list start */}
          <div className={`row g-10 ${homeStyle.mobileOnlyCenter}`}>
            {/* list items to iterate */}

            {Array?.isArray(category) && category?.length !== 0 ? (
              category?.map((data, i) => (
                <div key={i} className="col-8 col-sm-6 col-md-4 col-lg-3 ">
                  <div className="single-product-banner">
                    <div className="banner-img">
                      <span
                        onClick={() => catshop(data?.id)}
                        className="product-category-item"
                      >
                        <img
                          src={process.env.REACT_APP_URL + data?.categoryImage}
                          style={{
                            background: "##fff",
                            marginBottom: "10px",
                            border: "1px solid rgba(0,196,204,0.1)",
                            padding: "3px",
                          }}
                          alt={data?.categoryName}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                          }}
                        />
                      </span>
                    </div>
                    <div className="single-banner-content">
                      <h4 className="title">
                        <a
                          onClick={() => catshop(data?.id)}
                          style={{
                            cursor: "pointer",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {data?.categoryName}
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h5 className="container text-center">No Category Found</h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
