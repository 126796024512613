import { AiOutlineHome } from "react-icons/ai";

import cusStyle from "./customStyle.module.css";
import { Steps, ConfigProvider } from "antd";

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import { getOrdersDetails } from "../Redux/Action";
import orderimage from "../Assets/orders.svg";

import OrderTracking from "./OrderTracking";
import ReviewContainer from "./ReviewContainer";

const OrderDetails = () => {
  const [show, setShow] = useState(false);
  let navigate = useNavigate();
  const { id } = useParams();
  const order = useSelector(({ getOrders }) => getOrders.payload);
  const [reviewIndex, setReviewIndex] = useState(0);
  const [getid, setGetid] = useState({
    orderId: id,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrdersDetails(getid));
  }, []);

  const getDetails = useSelector(
    ({ getOrdersDetails }) => getOrdersDetails.payload
  );

  const orderInfo = useSelector(({ getOrdersDetails }) => {
    let time = getOrdersDetails?.payload?.orderInfo?.updatedAt;
    let orderStatus = getOrdersDetails?.payload?.orderInfo?.orderStatus;
    let current;
    let trackingId =
      getOrdersDetails?.payload?.orderInfo?.trackingInfo?.split(",")[0];
    let shipmentPartner =
      getOrdersDetails?.payload?.orderInfo?.trackingInfo?.split(",")[1];

    switch (orderStatus) {
      case "accepted": {
        current = 0;
        break;
      }
      case "packaging": {
        current = 1;
        break;
      }
      case "dispatched": {
        current = 2;
        break;
      }
      case "delivered": {
        current = 3;
        break;
      }

      default: {
      }
    }

    return { time, current, trackingId, shipmentPartner };
  });

  //   const [first, setfirst] = useState(second);

  //   const description = "This is a description.";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="section" style={{ paddingTop: "35px" }}>
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active">Order Details</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <section className="my-account-area section-space">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {Array.isArray(getDetails?.productInfo) &&
              getDetails?.productInfo?.length !== 0 ? (
                <div className="tab-content" id="nav-tabContent">
                  {/* Order Current Status */}
                  <div style={{ marginBottom: "100px", marginTop: "100px" }}>
                    {/* <OrderTracking /> */}

                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#00c4cc",
                        },
                      }}
                    >
                      <Steps
                        current={orderInfo?.current}
                        items={[
                          {
                            title: "Accepted",
                            description:
                              orderInfo?.current === 0 ? orderInfo?.time : "",
                          },
                          {
                            title: "Packaging",
                            description:
                              orderInfo?.current === 1 ? orderInfo?.time : "",
                          },
                          {
                            title: "Dispatched",
                            description:
                              orderInfo?.current === 2 ? orderInfo?.time : "",
                          },
                          {
                            title: "Delivered",
                            description:
                              orderInfo?.current === 3 ? orderInfo?.time : "",
                          },
                        ]}
                      />
                    </ConfigProvider>
                    {/* shipping Details */}
                    <div className={`${cusStyle.shippingDetailsContainer}`}>
                      <p>
                        <span>Shipment Tracking Id :</span>{" "}
                        {orderInfo?.trackingId || "-"}
                      </p>
                      <p>
                        <span>Shipment Partner:</span>{" "}
                        {orderInfo?.shipmentPartner || "-"}
                      </p>
                    </div>
                  </div>

                  {/* Orders */}
                  <div
                    className="myaccount-content"
                    style={{ marginBottom: "100px", marginTop: "100px" }}
                  >
                    <h3>Orders</h3>
                    <div className="myaccount-table table-responsive text-center">
                      <table className="table table-bordered">
                        {Array.isArray(getDetails?.productInfo) ? (
                          <thead className="thead-light">
                            <tr>
                              <th>S.No</th>
                              <th>Product</th>
                              <th>Name</th>
                              <th>Unit </th>
                              <th>Total</th>
                              <th>Review</th>
                            </tr>
                          </thead>
                        ) : (
                          false
                        )}
                        <tbody>
                          {Array.isArray(getDetails?.productInfo) &&
                          getDetails?.productInfo?.length !== 0
                            ? getDetails?.productInfo?.map((data, i) => (
                                <tr key={i}>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {i + 1}
                                  </td>

                                  <td>
                                    <img
                                      src={
                                        process.env.REACT_APP_URL +
                                        data.variantImage
                                      }
                                      alt=""
                                      style={{
                                        width: "auto",
                                        height: "70px",
                                        borderRadius: "3px",
                                      }}
                                    />
                                  </td>

                                  <td style={{ verticalAlign: "middle" }}>
                                    <p>
                                      <span
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {data.productName}
                                      </span>
                                    </p>
                                  </td>

                                  <td style={{ verticalAlign: "middle" }}>
                                    <p>
                                      <span
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {data.units}
                                      </span>
                                    </p>
                                  </td>

                                  <td style={{ verticalAlign: "middle" }}>
                                    <p>
                                      <span
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        ₹ {data.totalPrice}
                                      </span>
                                    </p>
                                  </td>

                                  <td
                                    style={{
                                      width: "auto",
                                      height: "30px",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {data.isReviewed == 0 ? (
                                      <span
                                        style={{
                                          background: "#01C4CC",
                                          border: "#01C4CC",
                                        }}
                                        className="btn btn-dark"
                                        onClick={() => {
                                          setReviewIndex(i);
                                          if (show === i) setShow("");
                                          else setShow(i);
                                        }}
                                      >
                                        {show === i
                                          ? "Close Review"
                                          : "Write Review"}
                                      </span>
                                    ) : (
                                      false
                                    )}
                                    {data.isReviewed === 1 ? (
                                      <span className="btn btn-dark">
                                        {" "}
                                        <a
                                          href="/reviewtotal"
                                          style={{ color: "white" }}
                                        >
                                          {" "}
                                          Go To Reviews
                                        </a>
                                      </span>
                                    ) : (
                                      false
                                    )}
                                  </td>
                                </tr>
                              ))
                            : ""}
                        </tbody>
                      </table>
                      {show || show === 0 ? (
                        <ReviewContainer
                          order={getDetails?.productInfo?.[show]}
                          reviewIndex={reviewIndex}
                        />
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ textAlign: "center", padding: "50px 0px" }}>
                  <img
                    alt=""
                    src={orderimage}
                    className="mt-5 mb-5 pb-5 pt-5"
                    style={{ width: "25%" }}
                  />
                  <h3> No Orders Found</h3>{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OrderDetails;
