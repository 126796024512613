import React from 'react'

const ReturnExchange = () => {
  return (
    <div>

      <div className="container">
      <div className="row">
          <div className="col">

      <h3 className='text-center py-5'>SHIPPING POLICY
</h3>
<h6>1. General</h6>
<p>Subject to stock availability. We try to maintain accurate stock counts on our website but from time-to-time there may be a stock discrepancy and we will not be able to fulfill all your items at time of purchase. In this instance, we will fulfill the available products to you, and contact you about whether you would prefer to await restocking of the backordered item or if you would prefer for us to process a refund.</p>

<h6>2. Shipping Costs</h6>
<p>Shipping costs are calculated during checkout based on weight, dimensions and destination of the items in the order. Payment for shipping will be collected with the purchase.This price will be the final price for shipping cost to the customer.</p>
<h6>3. Shipping & Timelines</h6>
<p>This depends on the type of shipping method chosen at checkout and location. We dispatch our products within 2 to 3 days from the order date, from Monday to Friday (excluding Indian public holidays), and it takes another 1 to 10 working days for the order to reach you based on your location. As soon as we ship your order, we will send you a notification by email and also share the courier tracking details so you know when to expect it. The expected timelines based on Shipping Method and Location are</p>
<p>Transit Time DomesticallyIn general, domestic shipments are in transit for 2 - 7 days</p>

<p>Transit time internationallyGenerally, orders shipped internationally are in transit for 4 - 10 days. This varies greatly depending on the courier service.</p>

<p>Any import duties, custom duties or taxes in relation to the shipment that may be liable in the country of final delivery will be borne by the customer.</p>

<h6>4. Change of Delivery Address</h6>

<p>
Orders placed before 10 AM - IST (UTC+05:30) will be dispatched the same day, otherwise, within the next business day.Our warehouse operates on Monday - Saturday during standard business hours, except on national holidays at which time the warehouse will be closed. In these instances, we take steps to ensure shipment delays will be kept to a minimum.
</p>
<p>For change of delivery address requests, we are able to change the address at any time before the order has been dispatched.</p>

<h6>5. Items Out Of Stock</h6>
<p>If an item is out of stock, we will dispatch the in-stock items immediately and send the remaining items once they return to stock.</p>
<h6></h6>











</div>
    </div>
</div>
      </div>
  )
}

export default ReturnExchange