import { AiOutlineHome } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import { getProfiles } from "../Redux/Action";
import Order from "./OrdersList";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { authenticateLogin, registerUser, logoutUser } from "../Redux/Action";

const Orders = () => {
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfiles())
      .then((res) => {
        setLoad(false);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active">Order List</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="login-register-area pb-100" s>
        <div className="container">
          <div
            className="row"
            style={{ marginTop: "70px", marginBottom: "70px" }}
          >
            <div className="col-lg-8 col-md-12 offset-lg-2 py-5">
              <div className="tab-content py-5">
                <Order />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
