import React, { useState, useEffect } from "react";
import {
  authenticateLogin,
  registerUser,
  logoutUser,
  getUserAddress,
  deleteUserAddress,
  checkOuts,
  payNows,
  getCarts,
} from "../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import css2 from '../assets/img/icons/Asset 3.svg'
import { AiOutlineHome } from "react-icons/ai";

const Checkout = () => {
  const [error, setError] = useState("");
  const [load, setLoad] = useState(true);
  const [orderId, setOrderId] = useState("");
  const [checkoutError, setCheckoutError] = useState("");
  const [paymentLoad, setPaymentLoad] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const navigate = useNavigate();
  const [addressId, setAddressId] = useState("");
  const [deliveryType, setDeliveryType] = useState("");
  const [payment, setPayment] = useState({
    addressId: "",
    deliveryType: "PAYNOW",
  });
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const [render, setRender] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    dispatch(getUserAddress());
  }, []);

  useEffect(() => {
    dispatch(getCarts())
      .then((res) => setLoad(false))
      .catch((res) => setLoad(false));
  }, [render]);

  const Address = useSelector(({ getUserAddress }) => getUserAddress.payload);
  const getCart = useSelector(({ getCarts }) => getCarts.payload);

  // const handleAddress = (e) => {
  //   setAddressId({  [e.target.name]: e.target.value });
  // };
  // const handleDelivery = (e) => {
  //   setDeliveryType({  [e.target.name]: e.target.value });
  // };
  const handlePayment = (e) => {
    setPayment({ ...payment, [e.target.name]: e.target.value });
  };

  const checkOut = () => {
    setPaymentLoad(true);
    const Swal = require("sweetalert2");
    if (
      payment.addressId === "" ||
      payment.addressId === undefined ||
      payment.addressId === null
    ) {
      setPaymentLoad(false);

      setTimeout(() => {
        setError("");
      }, 3000);
    }
    // else if (payment.deliveryType === "") {
    //   setPaymentLoad(false);
    //   setError("Payment Method is Required");
    //   setTimeout(() => {
    //     setError("");
    //   }, 3000);
    // }
    else {
      dispatch(checkOuts())
        .then((res) => {
          // setOrderId(res.data.data.orderId)

          // if (payment.deliveryType === "") {
          //   setPaymentLoad(false);

          //   dispatch(payNows({ ...payment, orderId: res.data.data.orderId, deliveryType:"PAYNOW" })).then((res) => {

          //     window.location.href = "/orderSuccess"

          //   }).catch((res) => {
          //     setPaymentLoad(false);
          //     window.location.reload();
          //   })
          // }
          // else {

          // if()
          setPaymentLoad(false);
          var config = {
            root: "",
            style: {
              bodyBackgroundColor: "#fafafb",
              bodyColor: "",
              themeBackgroundColor: "#0FB8C9",
              themeColor: "#ffffff",
              headerBackgroundColor: "#284055",
              headerColor: "#ffffff",
              errorColor: "",
              successColor: "",
              card: {
                padding: "",
                backgroundColor: "",
              },
            },
            data: {
              orderId: res.data.data.orderId,
              token: res.data.data.txnToken,
              tokenType: "TXN_TOKEN",
              amount: res.data.data.price /* update amount */,
            },
            payMode: {
              labels: {},
              filter: {
                exclude: [],
              },
              order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
            },
            website: "WEBSTAGING",
            flow: "DEFAULT",
            merchant: {
              mid: "KLFRUs76973220697209",
              redirect: false,
            },
            handler: {
              transactionStatus: function transactionStatus(paymentStatus) {
                if (paymentStatus.STATUS === "TXN_SUCCESS") {
                  dispatch(
                    payNows({ ...payment, orderId: paymentStatus.ORDERID })
                  ).then((res) => {
                    window.location.href = "/ordersuccess";
                  });
                  // navigate("/ordersuccess")
                  // window.location.reload();
                }
              },
              notifyMerchant: function notifyMerchant(eventName, data) {},
            },
          };

          if (window.Paytm && window.Paytm.CheckoutJS) {
            window.Paytm.CheckoutJS.init(config)
              .then(function onSuccess() {
                window.Paytm.CheckoutJS.invoke();
              })
              .catch(function onError(error) {
                setPaymentError("Payment Failed...");
                setTimeout(() => {
                  setPaymentError("");
                }, 5000);
              });
          } else {
          }
          // }
        })
        .catch((err) => {
          setCheckoutError(err.response.data.error.message);

          if (err.response.data.error.message === "Phone Number Not Verified") {
            navigate("/phoneOtp");
          }
        });
    }
  };

  const [selectedAddressId, setSelectedAddressId] = React.useState(null);

  const handlePlaceOrder = () => {
    if (!selectedAddressId) {
      setError("Address is Required");
    } else {
      dispatch(checkOuts({ addressId: selectedAddressId, currency: "INR" }))
        .then((res) => {
          //

          let url = "";
          if (res.data.data) url = res.data.data.url;

          if (url) {
            localStorage.setItem("orderId", res.data.data.orderId);
            window.open(url, "_blank");
          }
        })
        .catch((res) => {
          //
        });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div style={{ paddingTop: "36px" }} className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active"> Checkout Page</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-margin">
        <div className="container">
          <div className="row mb-n4">
            <div className="col-lg-6 col-12 mb-4">
              <form action="#">
                <div className="checkbox-form">
                  <h3 className="title">Billing Details</h3>

                  {/*  */}

                  <div className="row">
                    {Array.isArray(Address) && Address.length !== 0 ? (
                      <div className="myaccount-table  table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl   text-center">
                        <table className="table  table-bordered">
                          <thead className="thead-light">
                            <tr>
                              <th>S.No</th>
                              <th>Address Type</th>
                              <th>Address</th>
                              <th>Primary</th>
                              <th>Select Address</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Address?.map((data, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td style={{ textTransform: "capitalize" }}>
                                  {data?.addressType}
                                </td>
                                <td style={{ width: "30%" }}>
                                  {data?.street +
                                    " ," +
                                    data?.landmark +
                                    " ," +
                                    data?.city +
                                    " " +
                                    data?.district +
                                    ", " +
                                    data?.state +
                                    ", " +
                                    data?.zipcode}
                                </td>
                                <td>{data?.primary}</td>

                                <td>
                                  {" "}
                                  <input
                                    type="radio"
                                    name="addressId"
                                    value={data?.id}
                                    onChange={(event) => {
                                      setSelectedAddressId((pre) => {
                                        return event.target.value;
                                      });
                                    }}
                                    required
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="alert alert-secondary" role="alert">
                        NO ADDRESS FOUND{" "}
                        <strong>
                          <Link to="/address">(Add Address?)</Link>
                        </strong>
                      </div>
                    )}
                  </div>

                  {/*  */}
                </div>
              </form>
              <button className="btn btn-primary btn-hover-secondary rounded-0 w-20">
                {" "}
                <Link to="/cartaddress">Add Address</Link>
              </button>
            </div>
            {Array.isArray(getCart?.cart) && getCart?.cart?.length != 0 ? (
              <div className="col-lg-6 col-12 mb-4">
                <div className="your-order-area border">
                  <h3 className="title">Your order</h3>
                  <div className="your-order-table table-responsive">
                    <table className="table">
                      <thead>
                        <tr className="cart-product-head">
                          <th className="cart-product-name text-start">
                            Product
                          </th>
                          <th className="cart-product-total text-end">Total</th>
                        </tr>
                      </thead>

                      <tbody>
                        {getCart?.cart.map((C, i) => (
                          <tr key={i} className="cart_item">
                            <td
                              className="cart-product-name text-start ps-0"
                              style={{ textTransform: "capitalize" }}
                            >
                              {" "}
                              {C.productName}{" "}
                              <strong className="product-quantity">
                                {" "}
                                × {C.units}
                              </strong>
                            </td>
                            <td className="cart-product-total text-end pe-0">
                              <span className="amount"> ₹ {C.totalPrice}</span>
                            </td>
                          </tr>
                        ))}

                        <tr className="cart_item">
                          <td
                            className="cart-product-name text-start ps-0"
                            style={{ textTransform: "capitalize" }}
                          >
                            {" "}
                            Shipping Fee
                          </td>
                          <td className="cart-product-total text-end pe-0">
                            <span className="amount"> ₹ 70 </span>
                          </td>
                        </tr>
                        <tr className="cart_item">
                          <td
                            className="cart-product-name text-start ps-0"
                            style={{ textTransform: "capitalize" }}
                          >
                            {" "}
                            Actual Price :
                          </td>
                          <td className="cart-product-total text-end pe-0">
                            <span className="amount"> ₹{getCart?.price} </span>
                          </td>
                        </tr>
                        <tr className="cart_item">
                          <td
                            className="cart-product-name text-start ps-0"
                            style={{ textTransform: "capitalize" }}
                          >
                            {" "}
                            Discount Amount:
                          </td>
                          <td className="cart-product-total text-end pe-0">
                            <span className="amount">
                              {" "}
                              ₹{getCart?.discount}{" "}
                            </span>
                          </td>
                        </tr>
                        <tr className="cart_item">
                          <td
                            className="cart-product-name text-start ps-0"
                            style={{ textTransform: "capitalize" }}
                          >
                            {" "}
                            Inclusive GST:
                          </td>
                          <td className="cart-product-total text-end pe-0">
                            <span className="amount"> ₹{getCart?.gst}</span>
                          </td>
                        </tr>
                      </tbody>

                      <tfoot>
                        <tr className="order-total">
                          <th className="text-start ps-0">Order Total</th>
                          <td className="text-end pe-0">
                            <strong>
                              <span className="amount">
                                ₹{getCart?.totalPrice}
                              </span>
                            </strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                  <div className="payment-accordion-order-button">
                    {error ? (
                      <div
                        className="error-text mt-20"
                        style={{
                          backgroundColor: "#edc5c5",
                          padding: "10px",
                          color: "#fd0000",
                        }}
                      >
                        {error}
                      </div>
                    ) : (
                      false
                    )}
                    <div
                      className="order-button-payment"
                      style={{ margin: "13px" }}
                    >
                      {paymentError ? (
                        <div
                          className="error-text mt-20 py-2 "
                          style={{
                            backgroundColor: "#edc5c5",
                            padding: "10px",
                            color: "#fd0000",
                            padding: "10px",
                          }}
                        >
                          Payment Failed
                        </div>
                      ) : (
                        <button
                          className="btn btn-primary btn-hover-secondary rounded-0 w-100"
                          onClick={handlePlaceOrder}
                          type="submit"
                        >
                          Place Order
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
