import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPasswords } from "../Redux/Action";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    e.currentTarget.reset();
    dispatch(resetPasswords({ email: email }))
      .then(() => {
        navigate("/verifypassword/" + email);
        setTimeout(5000);
      })
      .catch((err) => setError(err.response.data.error.message));
  };
  return (
    <div>
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active"> Forgot password</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-margin">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 m-auto">
              <div className="login-wrapper">
                <div className="section-content text-center mb-5">
                  <h2 className="title mb-2">Password Reset</h2>
                  <p className="desc-content">
                    Please enter details below to reset your password
                  </p>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="single-input-item mb-3">
                    <input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email or Username"
                      required
                    />
                  </div>

                  {error ? (
                    <span
                      className="mb-1"
                      style={{
                        color: "red",
                        width: "299px",
                        background: "blanchedalmond",
                        fontSize: "14px",
                        display: "block",
                        padding: "15px",
                        fontWeight: "500",
                      }}
                    >
                      {error}
                    </span>
                  ) : (
                    false
                  )}
                  <br />

                  <div className="single-input-item mb-3">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <button className="btn btn btn-dark btn-hover-primary rounded-0">
                        Next
                      </button>

                      <div className="lost-password">
                        <Link style={{ textDecoration: "none" }} to="/login">
                          Login Account !
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
