import { AiOutlineHome } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addCart, addWishList } from "../Redux/ActionType";
import { CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { AiOutlineStar } from "react-icons/ai";
import { FaStar } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import plus from "../Assets/plus.png";
import minus from "../Assets/minus.png";

import { AiOutlineEye } from "react-icons/ai";

import {
  getCategoryProducts,
  getSingleProduct,
  addCarts,
  addWishLists,
  authenticateLogin,
} from "../Redux/Action";

const Token = localStorage.getItem("AuthTok");
const CategoryShop = () => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [load, setLoad] = useState(true);
  const [loader, setLoader] = useState(false);
  const [passworderror, setPassworderror] = useState("");
  const [change, setChange] = useState(0);
  const [changeVariant, setChangeVariant] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(false);
  const [render1, setRender1] = useState(false);

  //userlogin form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.password.length > 3) {
      dispatch(authenticateLogin(user)).then((res) => {
        if (res.data.data === "Wrong Email/Password. Try Again!") {
          setPassworderror(res.data.data);
        } else {
          window.location.href = "/Products";
        }
      });
    } else {
      setPassworderror("password must be  greater than 3 letters");
    }
  };

  const review = useSelector(({ getReviews }) => getReviews.payload);

  function numberWithCommas(x) {
    return x.toString().split(",")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  }
  function formatAsPercent(num) {
    return new Intl.NumberFormat("default", {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(num / 100);
  }

  //userLogin handleChange
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const [catid, setCatid] = useState({
    categoryId: id + "",
  });
  const dispatch = useDispatch();

  const catProduct = useSelector(
    ({ getCategoryProducts }) => getCategoryProducts.payload
  );

  const Product = useSelector(
    ({ getSingleProduct }) => getSingleProduct.payload
  );

  const carts = useSelector(({ addCarts }) => addCarts.payload);
  const wish = useSelector(({ addWishLists }) => addWishLists.payload);
  const Truncate = (string, number) => {
    if (!string) {
      return null;
    }
    if (string.length <= number) {
      return string;
    }
    return string.slice(0, number) + "...";
  };

  const [cartId, getCartId] = useState({
    variantColor: "",
    variantId: "",
    units: 0,
  });
  const handleClick = () => {
    cartId.variantId = Product[changeVariant].id;
    cartId.units = quantity;
    cartId.variantColor = JSON.parse(Product[changeVariant].variantColor)[
      change
    ];
    dispatch(addCarts(cartId)).then((res) => {
      setRender1(true);
    });
  };

  useEffect(() => {}, [Product]);
  const product = useSelector(({ getProducts }) => getProducts.payload);

  useEffect(() => {
    dispatch(getCategoryProducts(catid)).then((res) => setLoad(false));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //   const [proid, setProId] = useState({
  //     productId: 0,
  //   });
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#ff3e3e",
  };

  const SingleProduct = (proId) => {
    dispatch(getSingleProduct({ productId: proId })).then((res) =>
      setLoad(false)
    );
  };
  return load ? (
    <div className="row">
      <div className="col-lg-12">
        <div
          className="sweet-loading"
          style={{ margin: "193px", display: "flex" }}
        >
          <ClipLoader cssOverride={override} />
        </div>
      </div>
    </div>
  ) : (
    <div>
      <ToastContainer />
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active"> Category Products</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-margin mt-0 position-relative">
        <div className="container" style={{ marginTop: "80px" }}>
          <div className="row mb-lg-8 mb-6 "></div>
          <div className="row">
            {Array?.isArray(catProduct) && catProduct?.length != 0 ? (
              catProduct
                .filter((F) => F?.discountPrice > 1)
                .map((pro, i) => (
                  <div key={i} className="col-lg-3 col-sm-6 col-md-6 ">
                    <div className="product-carousel arrow-outside-container">
                      <div className="product-wrapper">
                        <div className="product mb-6">
                          <div className="thumb">
                            <Link
                              to={"/productDetails/" + pro.id}
                              className="image d-block"
                            >
                              <img
                                className="fit-image"
                                src={
                                  process.env.REACT_APP_URL + pro.productImage
                                }
                                style={{ background: "##fff" }}
                                alt="#"
                              />
                            </Link>

                            <span className="badges">
                              <span className="sale">
                                {" "}
                                {formatAsPercent(
                                  ((pro.actualPrice - pro.discountPrice) /
                                    pro.actualPrice) *
                                    100
                                )}{" "}
                                OFF
                              </span>
                            </span>
                            <div className="add-cart-btn">
                              <a
                                href="#"
                                className="btn btn-whited btn-hover-primary text-capitalize add-to-cart"
                                data-bs-toggle="modal"
                                onClick={() => SingleProduct(pro?.id)}
                                data-bs-target="#quick-view"
                              >
                                Quick View
                              </a>
                            </div>
                          </div>
                          <div className="content">
                            <h5
                              className="title"
                              style={{ textTransform: "capitalize" }}
                            >
                              <Link to={"/productDetails/" + pro?.id}>
                                {pro?.productName}
                              </Link>
                            </h5>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <span className="price">
                                <span className="new">
                                  {" "}
                                  ₹{numberWithCommas(pro?.discountPrice)}
                                </span>
                                <span className="old">
                                  {" "}
                                  ₹{numberWithCommas(pro?.actualPrice)}
                                </span>
                              </span>

                              <div className="">
                                <a
                                  title=""
                                  className=""
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    dispatch(
                                      addWishLists({
                                        productId: pro?.id,
                                      })
                                    ).then((res) => {
                                      toast(res.data);
                                      setRender(!render);
                                      //  window.location.reload();
                                    });
                                  }}
                                >
                                  {/* {pro?.favourites == 0 ? (
                                    <i
                                      className="bx bx-heart"
                                      style={{ fontSize: "26px" }}
                                    ></i>
                                  ) : (
                                    <i
                                      style={{
                                        fontSize: "26px",
                                        color: "#FE036A",
                                      }}
                                      className="bx bxs-heart"
                                    ></i>
                                  )} */}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <h3 className="container text-center">"No Product Found"</h3>
            )}
          </div>
        </div>
      </div>

      {/* model */}
      <div
        className="modalquickview modal fade"
        id="quick-view"
        tabIndex="-1"
        aria-labelledby="quick-view"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {loader ? (
              <div
                className="sweet-loading"
                style={{ margin: "193px", display: "flex" }}
              >
                <ClipLoader loading={loading} cssOverride={override} />
              </div>
            ) : (
              <div className="container">
                <button className="btn close" data-bs-dismiss="modal">
                  ×
                </button>

                {Array.isArray(Product) && Product.length > 0 ? (
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="modal-product-carousel">
                        <div className="swiper-container">
                          <div className="swiper-wrapper">
                            <a className="swiper-slide" href="#">
                              <img
                                className="w-100"
                                src={
                                  process.env.REACT_APP_URL +
                                  JSON?.parse(
                                    Product[changeVariant]?.variantImage
                                  )[change]
                                }
                                alt="Product"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12 overflow-hidden position-relative">
                      <div className="product-summery position-relative">
                        <h5
                          className="product-head mb-3"
                          style={{ textTransform: "capitalize" }}
                        >
                          <a
                            href={
                              "/productDetails/" +
                              Product[changeVariant]?.productId
                            }
                          >
                            {Product[changeVariant].productName}
                          </a>
                        </h5>

                        <div className="price-box mb-2">
                          <span className="regular-price">
                            {" "}
                            ₹
                            {numberWithCommas(
                              JSON?.parse(
                                Product[changeVariant]?.discountPrice
                              )[change]
                            )}
                          </span>
                          <span className="old-price">
                            <del>
                              {" "}
                              ₹{" "}
                              {numberWithCommas(
                                JSON?.parse(
                                  Product[changeVariant]?.actualPrice
                                )[change]
                              )}
                            </del>
                          </span>
                        </div>
                        {Array.isArray(catProduct) && catProduct?.length != 0
                          ? catProduct
                              .filter(
                                (F) => F?.id === catProduct[changeVariant].id
                              )
                              .map((pro, i) => (
                                <p key={i} style={{ textAlign: "justify" }}>
                                  {" "}
                                  <b style={{ fontWeight: "600" }}>
                                    Description :{" "}
                                  </b>
                                  {Truncate(pro?.productDescription, 240)}
                                </p>
                              ))
                          : "n"}

                        {/* <div className="quantity d-flex align-items-center mb-5">
                                                        <span className="me-2"><strong>Qty: </strong></span>
                                                        <div className="cart-plus-minus">
                                                            <input className="cart-plus-minus-box" onChange={(e) =>
                                                                setQuantity(e?.target.value)
                                                            } value={quantity} type="text" />
                                                            <div className="dec qtybutton" onClick={() => {
                                                                return quantity > 1
                                                                    ? setQuantity(quantity - 1)
                                                                    : false;
                                                            }}></div>
                                                            <div className="inc qtybutton" onClick={() => {
                                                                setQuantity(quantity + 1);
                                                            }}></div>
                                                        </div>
                                                    </div> */}

                        <div className="quantity d-flex align-items-center mb-5">
                          <span className="me-2">
                            <strong>Qty: </strong>
                          </span>
                          <div className="cart-plus-minus">
                            <input
                              className="cart-plus-minus-box"
                              onChange={(e) => setQuantity(e?.target.value)}
                              value={quantity}
                              type="text"
                              disabled
                            />
                            <div
                              className="dec qtybutton"
                              onClick={() => {
                                return quantity > 1
                                  ? setQuantity(quantity - 1)
                                  : false;
                              }}
                            >
                              <img
                                src={minus}
                                alt=""
                                height="12px"
                                width="12px"
                              />
                            </div>
                            <div
                              className="inc qtybutton"
                              onClick={() => {
                                setQuantity(quantity + 1);
                              }}
                            >
                              <img
                                src={plus}
                                alt=""
                                height="12px"
                                width="12px"
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          {carts ? (
                            <div
                              style={{
                                marginTop: "20px",
                                marginBottom: "-20px",
                              }}
                              className="alert alert-warning alert-dismissible fade show mb-5"
                              role="alert"
                              mt-3
                            >
                              {carts === "Already Added to Cart" ||
                              carts === "Added to Cart" ? (
                                <Link to={"/cart"}>
                                  {" "}
                                  {carts} -{" "}
                                  <span style={{ textDecoration: "underline" }}>
                                    {" "}
                                    Go to Cart
                                  </span>{" "}
                                </Link>
                              ) : (
                                carts
                              )}
                              <button
                                type="button"
                                className="btn-close"
                                //   onClick={()=>dispatch({type:addCart.add.success,data:false})}
                                data-dismiss="alert"
                              ></button>
                            </div>
                          ) : (
                            false
                          )}
                        </div>
                        <div>
                          {wish ? (
                            <div
                              style={{
                                marginTop: "20px",
                                marginBottom: "-20px",
                              }}
                              className="alert alert-warning alert-dismissible fade show mb-5"
                              role="alert"
                              mt-3
                            >
                              {wish}
                              <button
                                type="button"
                                className="btn-close"
                                data-dismiss="alert1"
                                //   onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                                aria-label="Close"
                              ></button>
                            </div>
                          ) : (
                            false
                          )}
                        </div>

                        <div className="cart-btn mb-4">
                          <div className="add-to_cart">
                            <a
                              className="btn btn-dark btn-hover-primary"
                              onClick={() => {
                                cartId.variantId = Product[changeVariant].id;
                                cartId.units = quantity;
                                cartId.variantColor = JSON.parse(
                                  Product[changeVariant].variantColor
                                )[change];
                                dispatch(addCarts(cartId)).then((res) => {
                                  toast(res.data.data);
                                });
                              }}
                            >
                              Add to cart
                            </a>
                          </div>
                        </div>
                        {/* <div className="actions border-bottom mb-4 pb-4">
                                                    <a
                                                        title="Wishlist"
                                                        className="action wishlist"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            dispatch(
                                                                addWishLists({
                                                                    productId: Product[changeVariant]?.id,
                                                                })
                                                            ).then((res) => {
                                                                toast(res.data);
                                                                setRender(!render)
                                                            });
                                                        }}
                                                    >
                                                        {Product[changeVariant]?.favourites == 0 ? (
                                                            <i
                                                                className="bx bx-heart"
                                                                style={{ fontSize: "26px" }}
                                                            ></i>
                                                        ) : (
                                                            <i
                                                                style={{ fontSize: "26px", color: "#FE036A" }}
                                                                className="bx bxs-heart"
                                                            ></i>
                                                        )}
                                                        Wishlist
                                                    </a>
                                                </div> */}
                        <span>
                          <a
                            href={
                              "/productDetails/" +
                              Product[changeVariant]?.productId
                            }
                            style={{
                              fontSize: "18px",
                              textDecoration: "underline",
                            }}
                          >
                            View More Details
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  false
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* model end */}
    </div>
  );
};

export default CategoryShop;
