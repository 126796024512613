import React from 'react'

const ReturnRefund = () => {
  return (
    <div>
      <div className="container ">
      <div className="row">
          <div className="col">

        <h3 className='text-center py-5'>Return &Refund Process</h3>
        <p>Although we make every effort to fulfill all the orders placed by any chance you found an issue or defected product don‘t worry we are happy to help. You can initiate a return request from the Bhumi Doris account or mail us at xxxxxx with your Order ID and clear description of returning the product with a photo attached to start the refund procedure.</p>
        <ul>
          <li style={{ listStyle: "inside" }}> Keep the receipts and tags on! No take-backs if they’re off </li>
          <li style={{ listStyle: "inside" }}>We usually respond within 48 hours so please wait to hear from us</li>
          <li style={{ listStyle: "inside" }}>  We will initiate a return/exchange pickup; you can expect further communication via email.</li>
          <li style={{ listStyle: "inside" }}>BTW! You’ve got 5 days to exchange the product</li>
          <li style={{ listStyle: "inside" }}>Return shipping costs are the sole responsibility of the buyer.</li>
          <li style={{ listStyle: "inside" }}>Please send the product(s) back to us in its original condition</li>
          <li style={{ listStyle: "inside" }}>Once the product is received from our side, it goes through a quality check to make sure the product is not used or damaged.</li>
          <li style={{ listStyle: "inside" }}>When the product passes the quality test your refund process will be initiated. If Quality got differ from customer 25 % will detect from the bill.</li>
          <li style={{ listStyle: "inside" }}>We will refund the amount within 5-7 working days in your born babies account.</li>
          <li style={{ listStyle: "inside" }}>Once the amount is refunded into your Bhumi Doris account you can redeem against the new purchase</li>
          <li style={{ listStyle: "inside" }}>In case of returns, we will issue a Bhumi Doris credit note that can be redeemed in the next 30 Days on our website</li>
          <li style={{ listStyle: "inside" }}>Return Shipping: To return your product, you should mail your product to:</li>
        </ul>


        <h3  className='text-center py-5'>  RETURN & EXCHANGE POLICY</h3>

        <p>Our products are always packed with love and care so that the products that reach to you are completely safe and not used before. Our aim is to provide you the products that are genuine and 100% satisfies the customers. However, due to some certain reason you are not satisfied with our product we offer easy return and Exchange policy. You can return or exchange our products within 5 days from the date of receiving. Also to be aware that some of our items might not fall under the return policy. Here list of items which fall under the return and non-returnable category.</p>

        <table className='table-tab'>
          <thead>
            <tr style={{border :"1px solid black"}}>
              <th style={{border :"1px black solid"}}>Product</th>
              <th style={{border :"1px black solid"}}>Brand</th>
              <th style={{border :"1px black solid"}}>Returnable/Non Returnable Products</th>
              <th style={{border :"1px black solid"}}>Return Policy</th>
            </tr>
          </thead>
          <tr>
            <td style={{border :"1px black solid"}}>  CLOTHING</td>
            <td style={{border: "1px solid black"}}></td>
            <td style={{borderRight :"1px black solid"}}>Returnable</td>
            <td  style={{border :"1px black solid"}}>5 days from the date of delivery</td>
          </tr>
          <tr style={{border :"1px black solid"}}>BEDDING  <td style={{border: "1px solid black"}}></td> <td style={{border: "1px solid black"}}> Returnable</td>5 days from the date of delivery</tr>
         

          <tr style={{border :"1px black solid "}}>FOOTWEAR <td style={{border: "1px solid black"}}></td> <td style={{border: "1px solid black"}}>Returnable</td>5 days from the date of delivery</tr>
          <tr style={{border :"1px black solid"}}>CRADLE <td style={{border: "1px solid black"}}></td> <td style={{border: "1px solid black"}}> Returnable</td>5 days from the date of delivery</tr>
          <tr style={{border :"1px black solid"}}>WALKER<td style={{border: "1px solid black"}}></td>Returnable  <td style={{border: "1px solid black"}}>5 days from the date of delivery</td></tr>
          <tr style={{border :"1px black solid"}}>STROLLER <td style={{border: "1px solid black"}}></td>Returnable <td style={{border: "1px solid black"}}>5 days from the date of delivery</td></tr>
          <tr style={{border :"1px black solid"}}>PRAM <td style={{border: "1px solid black"}}></td> Returnable<td style={{border: "1px solid black"}}>5 days from the date of delivery</td> </tr>
          <tr style={{border :"1px black solid"}}>PLAY BIN <td style={{border: "1px solid black"}}></td>Returnable <td style={{border: "1px solid black"}}>5 days from the date of delivery</td></tr>
          <tr style={{border :"1px black solid"}}>BOUNCER <td style={{border: "1px solid black"}}></td> Returnable<td style={{border: "1px solid black"}}>5 days from the date of delivery</td></tr>
          <tr style={{border :"1px black solid"}}>CAR SEAT <td style={{border: "1px solid black"}}></td> Returnable<td style={{border: "1px solid black"}}>5 days from the date of delivery</td></tr>
          <tr style={{border :"1px black solid"}}>COT <td style={{border: "1px solid black"}}></td>Returnable <td style={{border: "1px solid black"}}>5 days from the date of delivery</td></tr>
          <tr style={{border :"1px black solid"}}>RIDE ON <td style={{border: "1px solid black"}}></td>Returnable <td style={{border: "1px solid black"}}>5 days from the date of delivery</td></tr>
          <tr style={{border :"1px black solid"}}>BATHTUB <td style={{border: "1px solid black"}}></td>Returnable <td style={{border: "1px solid black"}}>5 days from the date of delivery</td></tr>
          <tr style={{border :"1px black solid"}}>SLING BAG <td style={{border: "1px solid black"}}></td>Returnable <td style={{border: "1px solid black"}}>5 days from the date of delivery</td></tr>
          <tr><td style={{border :"1px black solid"}}>TOYS</td>
   <td style={{border: "1px solid black"}}></td>
          <td style={{border: "1px solid black"}}>Non-Returnable</td>
          <td style={{border: "1px solid black"}} ></td>
          </tr>
          <tr style={{border :"1px black solid"}}>BABY DIAPERS <td style={{border: "1px solid black"}}></td><td style={{border: "1px solid black"}}>Non-Returnable</td></tr>
          <tr style={{border :"1px black solid"}}>BABY WIPES<td style={{border: "1px solid black"}}></td><td style={{border: "1px solid black"}}>Non-Returnable</td>
          </tr>
          <tr style={{border :"1px black solid"}}>DIAPER RASH CREAM<td style={{border: "1px solid black"}}></td><td style={{border: "1px solid black"}}>Non-Returnable</td>
          </tr>
          <tr style={{border :"1px black solid"}}>BOTTLES & ACCESSORIES<td style={{border: "1px solid black"}}></td><td style={{border: "1px solid black"}}>Non-Returnable</td>
          </tr>
          <tr style={{border :"1px black solid"}}>BREAST FEEDING <td style={{border: "1px solid black"}}></td><td style={{border: "1px solid black"}}>Non-Returnable</td></tr>
          <tr style={{border :"1px black solid"}}>BABY FOOD<td style={{border: "1px solid black"}}> </td><td style={{border: "1px solid black"}}>Non-Returnable</td>
          </tr>
          <tr style={{border :"1px black solid"}}>MATERNITY ACCESSORIES <td style={{border: "1px solid black"}}></td> <td style={{border: "1px solid black"}}>Non-Returnable</td></tr>
          <tr style={{border :"1px black solid"}}>GROOMING <td style={{border: "1px solid black"}}></td><td style={{border: "1px solid black"}}>Non-Returnable</td></tr>
          <tr style={{border :"1px black solid"}}>SOAPS,SHAMPOOS&BODY WASH'S <td style={{border: "1px solid black"}}></td><td style={{border: "1px solid black"}}>Non-Returnable</td></tr>
          <tr style={{border :"1px black solid"}}>BABY CREAM & ONINTMENTS <td style={{border: "1px solid black"}}></td><td style={{border: "1px solid black"}}>Non-Returnable</td></tr>
          <tr style={{border :"1px black solid"}}>LOTIONS,OILS&POWDER ORAL CARE <td style={{border: "1px solid black"}}></td> <td style={{border: "1px solid black"}}>Non-Returnable</td></tr>
          <tr style={{border :"1px black solid"}}>COMBO PACK <td style={{border: "1px solid black"}}></td><td style={{border: "1px solid black"}}>Non-Returnable</td></tr>
          <tr style={{border :"1px black solid"}}>HEALTH & BABY CARE <td style={{border: "1px solid black"}}></td> <td style={{border: "1px solid black"}}>Non-Returnable</td></tr>
          <tr style={{border :"1px black solid"}}>BABY PACIFIERS <td style={{border: "1px solid black"}}></td> <td style={{border: "1px solid black"}}>Non-Returnable</td></tr>
          <tr style={{border :"1px black solid"}}>THERMOMETER <td style={{border: "1px solid black"}}></td> <td style={{border: "1px solid black"}}>Non-Returnable</td></tr>
          <tr style={{border :"1px black solid"}}>CLEANSER & DETERGENT <td style={{border: "1px solid black"}}></td> <td style={{border: "1px solid black"}}>Non-Returnable</td></tr>
          <tr style={{border :"1px black solid"}}>MOSQUITO REPELLENTS & CARE <td style={{border: "1px solid black"}}></td> <td style={{border: "1px solid black"}}>Non-Returnable</td></tr>
          <tr style={{border :"1px black solid"}}>TEETHERS & SOOTHERS <td style={{border: "1px solid black"}}></td> <td style={{border: "1px solid black"}}>Non-Returnable</td></tr>
          <tr style={{border :"1px black solid"}}>MATERNITY INNER,LINGERIE,UPDOMEN BELT & THERMALS <td style={{border: "1px solid black"}}></td>
          <td style={{border: "1px solid black"}}>Non-Returnable</td> </tr>
          <tr style={{border :"1px black solid"}}>GIFT CARDS & OFFERS <td style={{border: "1px solid black"}}></td>
          <td style={{border: "1px solid black"}}>Non-Returnable</td></tr>
          <tr style={{border :"1px black solid"}}>MATERNITY PERSONAL CARE COSMETICS <td style={{border: "1px solid black"}}></td>
          <td style={{border: "1px solid black"}}>Non-Returnable</td></tr>
          <tr style={{border :"1px black solid"}}>STATIONARY(BOOKS & CD'S) <td style={{border: "1px solid black"}}></td><td style={{border: "1px solid black"}}>Non-Returnable</td></tr>

         



        </table>

        <h3 className='text-center py-5'>Return &Refund Process</h3>
        <p>Any items purchased during a flash/clearance sale cannot be exchanged or returned.</p>

        <h3 className='text-center py-5 '>
          MODIFY / CANCEL</h3>
        <p>The order amount will be refunded or a credit note will be provided if the order is cancelled. You may modify/cancel your order as long as it has not been shipped. To do so, please get in touch with our team by calling +91 9842757058 or email us at xxx with your order details as soon as possible as orders are sometimes shipped on the same day.</p>
</div>
</div>
      </div>

   


    </div>
  )
}

export default ReturnRefund