import homeStyle from "./Home.module.css";
//images
import plus from "../Assets/plus.png";
import minus from "../Assets/minus.png";

import modalPlaceholder from "../Assets/about.jpeg";
//icons

//package imports
import React, { useEffect, useState, CSSProperties } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
//
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
//redux imports
import { addCart, addWishList, welcomeMessage } from "../Redux/ActionType";
import {
  getWishLists,
  hotDeals,
  getCategories,
  getSingleProduct,
  getRelatedProducts,
  addCarts,
  addWishLists,
  getBanner,
  getWelcomeMessages,
  getReviews,
} from "../Redux/Action";

// cus components

import { CustomModal } from "../helperComponents/Modal";

const Token = localStorage.getItem("AuthTok");

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //redux store selectors
  const category = useSelector(({ getCategories }) => getCategories.payload);
  const deal = useSelector(({ hotDeals }) => hotDeals.payload);
  const Product = useSelector(
    ({ getSingleProduct }) => getSingleProduct.payload
  );
  const rproduct = useSelector(
    ({ getRelatedProducts }) => getRelatedProducts.payload
  );
  const banner = useSelector(({ getBanner }) => getBanner.payload);

  const welcomeInfo = useSelector(
    ({ getWelcomeMessages }) => getWelcomeMessages.payload
  );

  const wish = useSelector(({ addWishLists }) => addWishLists.payload);
  const carts = useSelector(({ addCarts }) => addCarts.payload);

  // constants and variables
  const [load, setLoad] = useState(true);
  const [loader, setLoader] = useState(true);
  const [changeVariant, setChangeVariant] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [change, setChange] = useState(0);
  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(false);
  const [cartId, getCartId] = useState({
    variantColor: "",
    variantId: "",
    units: 0,
  });
  const [isShow, setIsShow] = React.useState(true);

  //Utilits
  const Truncate = (string, number) => {
    if (!string) {
      return null;
    }
    if (string.length <= number) {
      return string;
    }
    return string.slice(0, number) + "...";
  };
  const SingleProduct = (proId) => {
    dispatch(getSingleProduct({ productId: proId })).then((res) =>
      setLoader(false)
    );
  };
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#ff3e3e",
  };
  const catshop = (id) => {
    navigate("/categoryShop/" + id);
  };
  function numberWithCommas(x) {
    return x.toString().split(",")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  }
  function formatAsPercent(num) {
    return new Intl.NumberFormat("default", {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(num / 100);
  }
  const bannerWelcome = () => {
    setIsShow(!isShow);
  };
  const Loader = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div
            className="sweet-loading"
            style={{ margin: "193px", display: "flex" }}
          ></div>
        </div>
      </div>
    );
  };

  //Effects
  useEffect(() => {
    dispatch(getCategories()).then((res) => setLoad(false));

    dispatch(getSingleProduct()).then((res) => {
      setLoad(false);
      setRender(!render);
    });

    dispatch(getRelatedProducts()).then((res) => {
      setLoad(false);
      setRender(!render);
    });

    dispatch(getBanner({}));

    dispatch(getReviews({}), 1000);

    dispatch(hotDeals()).then((res) => {
      setLoad(false);
      setRender(!render);
    });

    // dispatch(getWelcomeMessages()).then((res) => {
    //   setLoad(false);
    // });
  }, []);
  useEffect(() => {
    dispatch(getWishLists())
      .then((res) => setLoad(false))
      .catch((res) => setLoad(false));
  }, [render]);
  useEffect(() => {
    setTimeout(() => {
      window.$(".qtybutton").on("click", function () {
        var $button = window.$(this);
        var oldValue = $button.parent().find("input").val();
        if ($button.hasClass("inc")) {
          var newVal = parseFloat(oldValue) + 1;
        } else {
          // Don't allow decrementing below zero
          if (oldValue > 1) {
            var newVal = parseFloat(oldValue) - 1;
          } else {
            newVal = 1;
          }
        }
        $button.parent().find("input").val(newVal);
      });
      // var swiper = new window.$.Swiper(".hero-slider.swiper-container", {
      //   loop: true,
      //   speed: 1150,
      //   spaceBetween: 30,
      //   slidesPerView: 1,
      //   effect: "fade",
      //   pagination: true,
      //   navigation: true,

      //   // Navigation arrows
      //   navigation: {
      //     nextEl: ".hero-slider .home-slider-next",
      //     prevEl: ".hero-slider .home-slider-prev",
      //   },
      //   pagination: {
      //     el: ".hero-slider .swiper-pagination",
      //     type: "bullets",
      //     clickable: "true",
      //   },
      //   // Responsive breakpoints
      // });

      window.$(".qtybutton").on("click", function () {
        var $button = window.$(this);
        var oldValue = $button.parent().find("input").val();
        if ($button.hasClass("inc")) {
          var newVal = parseFloat(oldValue) + 1;
        } else {
          // Don't allow decrementing below zero
          if (oldValue > 1) {
            var newVal = parseFloat(oldValue) - 1;
          } else {
            newVal = 1;
          }
        }
        $button.parent().find("input").val(newVal);
      });
      window.$(".top-close-btn").on("click", function () {
        window.$(".header-top").addClass("active");
      });
    }, 1000);
  }, []);
  useEffect(() => {
    window.$(".top-close-btn").on(
      "click",
      function () {
        window.$(".header-top").addClass("active");
      },
      100
    );
  }, []);
  useEffect(() => {
    window.setTimeout(function () {
      dispatch({ type: addCart.add.success });
      window
        .$(".alert")
        .fadeTo(500, 0)
        .slideUp(500, function () {
          window.$(this).remove();
        });
    }, 6000);

    if (deal?.error?.message == "unAuthurised") {
      navigate("/login");
    }

    window.setTimeout(function () {
      dispatch({ type: addWishList.wishList.success });
      window
        .$(".alert1")
        .fadeTo(500, 0)
        .slideUp(500, function () {
          window.$(this).remove();
        });
    }, 6000);
  }, [carts, wish]);

  const [modalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    let modalShownStatus = sessionStorage.getItem("isModalShown");
    if (modalShownStatus) setModalOpen(false);
  }, []);

  return load ? (
    <Loader />
  ) : (
    <div>
      <ToastContainer />
      <CustomModal
        bannerImg={process.env.REACT_APP_URL + welcomeInfo?.image}
        isOpen={modalOpen}
        toggle={() => {
          sessionStorage.setItem("isModalShown", "true");
          setModalOpen((pre) => !pre);
        }}
      />

      {/* welcome message offers in top */}
      {/* {isShow ? (
        <div className="header-top bg-primary " data-bs-toggle="modal">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="header-top-msg-wrapper text-center">
                  <p className="header-top-message text-center ">
                    {" "}
                    <strong style={{ verticalAlign: "bottom", color: "black" }}>
                      {welcome?.message}
                    </strong>{" "}
                  </p>
                  <div
                    className="header-top-close-btn py-2 "
                    style={{ verticalAlign: "bottom" }}
                  >
                    <button
                      className="top-close-btn "
                      onClick={bannerWelcome}
                      data-bs-dismiss="modal"
                    >
                      <i className="pe-7s-close"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )} */}

      {/* Hero carousal start  - react-owl-carousel
       */}
      <div className="carousel-inner">
        <div className="carousel-item active"></div>
      </div>

      <OwlCarousel
        className="owl-theme"
        nav={false}
        mergeFit={true}
        autoplay={true}
        items={1}
        loop
        margin={10}
        autoplayHoverPause={true}
        autoplayTimeout={1800}
      >
        {Array.isArray(banner) && banner.length !== 0 ? (
          banner?.map((Banner, i) => (
            <div key={i + 1} className="item">
              <img
                src={process.env.REACT_APP_URL + Banner?.bannerImage}
                alt=""
              />
            </div>
          ))
        ) : (
          // false
          <div className="sweet-loading" style={{ margin: "168px" }}>
            <ClipLoader loading={loading} cssOverride={override} />
            {banner?.data}
          </div>
        )}
      </OwlCarousel>
      {/* Hero carousal end */}

      {/* category start*/}
      <div className="section section-padding">
        <div className="container">
          {/* title */}
          <div
            className="section-title text-center"
            style={{ marginBottom: "90px" }}
          >
            <h2
              style={{ width: "fit-content", margin: " auto" }}
              className={`title ${homeStyle.bD_sectionTitle}`}
              id="demo"
            >
              Category
            </h2>
          </div>

          {/* list start */}
          <div className={`row g-10 ${homeStyle.mobileOnlyCenter}`}>
            {/* list items to iterate */}
            {Array?.isArray(category) && category?.length !== 0 ? (
              category?.map((data, i) => (
                <div key={i + 1} className="col-8 col-sm-6 col-md-4 col-lg-3 ">
                  <div className="single-product-banner">
                    <div className="banner-img" style={{ cursor: "pointer" }}>
                      <span onClick={() => catshop(data?.id)}>
                        <img
                          src={process.env.REACT_APP_URL + data?.categoryImage}
                          style={{
                            background: "##fff",
                            marginBottom: "10px",
                            borderRadius: "50%",
                            border: "1px solid rgba(0,196,204,0.1)",
                            padding: "3px",
                          }}
                          loading="lazy"
                          alt={data?.categoryName}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            //   currentTarget.src = img;
                          }}
                        />
                      </span>
                    </div>
                    <div className="single-banner-content">
                      <h4
                        className="title"
                        style={{
                          textTransform: "capitalize",
                          cursor: "pointer",
                        }}
                      >
                        <Link to={`/categoryShop/${data?.id}`}>
                          {data?.categoryName}
                        </Link>
                      </h4>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h5 className="container text-center">No Category Found</h5>
            )}
          </div>
        </div>
      </div>
      {/* category end*/}

      {/* hot deals start */}
      <div className="section section-padding">
        <div className="container">
          {/* title */}
          <div
            className="section-title text-center"
            style={{ marginBottom: "90px" }}
          >
            <h2
              style={{ width: "fit-content", margin: " auto" }}
              className={`title ${homeStyle.bD_sectionTitle}`}
              id="demo"
            >
              Hot Deals
            </h2>
          </div>

          {/* list start */}
          <div className={`row g-10 ${homeStyle.mobileOnlyCenter}`}>
            {/* list items to iterate */}

            {Array?.isArray(deal) && Array.length !== 0 ? (
              deal.map((data, index) => (
                <div
                  key={index + 1}
                  id="hotdeal"
                  className="col-8 col-sm-6 col-md-4 col-lg-3  "
                >
                  <div className="product-wrapper">
                    <div className="product">
                      <div className="thumb">
                        <Link
                          to={"/productDetails/" + data?.id}
                          className="image"
                        >
                          <img
                            style={{
                              border: "1px solid rgba(0,196,204,0.1)",
                              padding: "3px",
                            }}
                            className="fit-image"
                            src={process.env.REACT_APP_URL + data?.productImage}
                            alt="Product"
                            loading="lazy"
                          />
                        </Link>

                        <span className="badges">
                          <span className="sale">
                            {" "}
                            {formatAsPercent(
                              ((data.actualPrice - data.discountPrice) /
                                data.actualPrice) *
                                100
                            )}{" "}
                            OFF
                          </span>
                        </span>

                        <div className="add-cart-btn">
                          <a
                            href="#"
                            className="btn btn-whited btn-hover-primary text-capitalize add-to-cart"
                            data-bs-toggle="modal"
                            onClick={() => SingleProduct(data?.id)}
                            data-bs-target="#quick-view"
                          >
                            {" "}
                            Quick View
                          </a>
                        </div>
                      </div>
                      <div className="content">
                        <h5
                          className="title"
                          style={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          <Link to={"/productDetails/" + data?.id}>
                            {Truncate(data?.productName, 40)}
                          </Link>
                        </h5>
                        <div
                          style={{
                            display: "flex",

                            justifyContent: "space-evenly",
                          }}
                        >
                          <span className="price">
                            <span className="new">
                              {" "}
                              ₹{numberWithCommas(data?.discountPrice)}
                            </span>
                            <span className="old">
                              {" "}
                              ₹{numberWithCommas(data?.actualPrice)}
                            </span>
                          </span>

                          <div className="">
                            <a
                              title=""
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                dispatch(
                                  addWishLists({
                                    productId: data?.id,
                                  })
                                ).then((res) => {
                                  window.location.href = "/";
                                  setRender(!render);
                                });
                              }}
                            >
                              {data?.favourites == 0 ? (
                                <i
                                  className="bx bx-heart"
                                  style={{ fontSize: "26px" }}
                                ></i>
                              ) : (
                                <i
                                  style={{
                                    fontSize: "26px",
                                    color: "#FE036A",
                                  }}
                                  className="bx bxs-heart"
                                ></i>
                              )}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h5 className="container text-center">No Hot Deals Found</h5>
            )}
          </div>
        </div>
      </div>
      {/* hot deals end */}

      {/* recommand start */}

      {Token ? (
        <div className="section section-padding">
          <div className="container">
            {/* title */}
            <div
              className="section-title text-center"
              style={{ marginBottom: "90px" }}
            >
              <h2
                style={{ width: "fit-content", margin: " auto" }}
                className={`title ${homeStyle.bD_sectionTitle}`}
                id="demo"
              >
                Recommended for You
              </h2>
            </div>

            {/* list start */}
            <div className={`row g-10 ${homeStyle.mobileOnlyCenter}`}>
              {/* list items to iterate */}
              {Array?.isArray(rproduct) && Array.length !== 0 ? (
                rproduct
                  .filter((data) => data?.discountPrice >= 1)
                  .map((data, index) => (
                    <div
                      key={index + 1}
                      className="col-8 col-sm-6 col-md-4 col-lg-3   "
                    >
                      <div className="product-wrapper">
                        <div className="product">
                          <div className="thumb">
                            <Link
                              to={"/productDetails/" + data?.id}
                              className="image"
                            >
                              <img
                                className="fit-image"
                                src={
                                  process.env.REACT_APP_URL + data?.productImage
                                }
                                alt={data?.productName}
                                loading="lazy"
                                style={{
                                  border: "1px solid rgba(0,196,204,0.1)",
                                  padding: "3px",
                                }}
                              />
                              <img
                                className="second-image fit-image"
                                src={
                                  process.env.REACT_APP_URL + data?.productImage
                                }
                                alt="Product"
                              />
                            </Link>

                            <span className="badges">
                              <span className="sale">
                                {" "}
                                {formatAsPercent(
                                  ((data.actualPrice - data.discountPrice) /
                                    data.actualPrice) *
                                    100
                                )}{" "}
                                OFF
                              </span>
                            </span>
                            <div className="add-cart-btn">
                              <a
                                className="btn btn-whited btn-hover-primary text-capitalize add-to-cart"
                                data-bs-toggle="modal"
                                onClick={() => SingleProduct(data?.id)}
                                data-bs-target="#quick-view"
                              >
                                {" "}
                                Quick View
                              </a>
                            </div>
                          </div>
                          <div className="content">
                            <h5
                              className="title"
                              style={{ textTransform: "capitalize" }}
                            >
                              <Link to={"/productDetails/" + data?.id}>
                                {data?.productName}
                              </Link>
                            </h5>
                            <div
                              style={{
                                display: "flex",

                                justifyContent: "space-evenly",
                              }}
                            >
                              <span className="price">
                                <span className="new">
                                  {" "}
                                  ₹{numberWithCommas(data?.discountPrice)}
                                </span>
                                <span className="old">
                                  {" "}
                                  ₹{numberWithCommas(data?.actualPrice)}
                                </span>
                              </span>

                              <div className="">
                                <a
                                  title=""
                                  className=""
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    dispatch(
                                      addWishLists({
                                        productId: data?.id,
                                      })
                                    ).then((res) => {
                                      toast(res.data);
                                      setRender(!render);
                                      window.location.href = "/";
                                    });
                                  }}
                                >
                                  {data?.favourites == 0 ? (
                                    <i
                                      className="bx bx-heart"
                                      style={{ fontSize: "26px" }}
                                    ></i>
                                  ) : (
                                    <i
                                      style={{
                                        fontSize: "26px",
                                        color: "#FE036A",
                                      }}
                                      className="bx bxs-heart"
                                    ></i>
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <h5 className="container text-center">No Recommended Items</h5>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* modal start */}
      <div
        className="modalquickview modal fade"
        id="quick-view"
        tabIndex="-1"
        aria-labelledby="quick-view"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {loader ? (
              <div
                className="sweet-loading"
                style={{ margin: "193px", display: "flex" }}
              >
                <ClipLoader loading={loading} cssOverride={override} />
              </div>
            ) : (
              <div className="container">
                <button
                  className="btn close"
                  data-bs-dismiss="modal"
                  type="button"
                >
                  ×
                </button>

                {Array.isArray(Product) && Product.length > 0 ? (
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="modal-product-carousel">
                        <div className="swiper-container">
                          <div className="swiper-wrapper">
                            <a className="swiper-slide" href="#">
                              <img
                                className="w-100"
                                src={
                                  process.env.REACT_APP_URL +
                                  JSON?.parse(
                                    Product[changeVariant]?.variantImage
                                  )[change]
                                }
                                loading="lazy"
                                alt="Product"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12 overflow-hidden position-relative">
                      <div className="product-summery position-relative">
                        <h5
                          className="product-head mb-3"
                          style={{ textTransform: "capitalize" }}
                        >
                          <a
                            href={
                              "/productDetails/" +
                              Product[changeVariant]?.productId
                            }
                          >
                            {Product[changeVariant].productName}
                          </a>
                        </h5>
                        <div className="price-box mb-2">
                          <span className="regular-price">
                            {" "}
                            ₹
                            {numberWithCommas(
                              JSON?.parse(
                                Product[changeVariant]?.discountPrice
                              )[change]
                            )}
                          </span>
                          <span className="old-price">
                            <del>
                              {" "}
                              ₹{" "}
                              {numberWithCommas(
                                JSON?.parse(
                                  Product[changeVariant]?.actualPrice
                                )[change]
                              )}
                            </del>
                          </span>
                        </div>
                        {Array.isArray(deal) && deal?.length != 0
                          ? deal
                              .filter(
                                (F) =>
                                  F?.id === Product[changeVariant].productId
                              )
                              .map((pro, index) => (
                                <p
                                  key={index + 1}
                                  className="desc-content mb-5"
                                  style={{
                                    textAlign: "justify",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {" "}
                                  {Truncate(pro?.productDescription, 240)}
                                </p>
                              ))
                          : false}

                        <div className="quantity d-flex align-items-center mb-5">
                          <span className="me-2">
                            <strong>Qty: </strong>
                          </span>
                          <div className="cart-plus-minus">
                            <input
                              className="cart-plus-minus-box"
                              onChange={(e) => setQuantity(e?.target.value)}
                              value={quantity}
                              type="text"
                              disabled
                            />
                            <div
                              className="dec qtybutton"
                              onClick={() => {
                                return quantity > 1
                                  ? setQuantity(quantity - 1)
                                  : false;
                              }}
                            >
                              <img
                                src={minus}
                                alt=""
                                height="12px"
                                width="12px"
                              />
                            </div>
                            <div
                              className="inc qtybutton"
                              onClick={() => {
                                setQuantity(quantity + 1);
                              }}
                            >
                              <img
                                src={plus}
                                alt=""
                                height="12px"
                                width="12px"
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          {carts ? (
                            <div
                              style={{
                                marginTop: "20px",
                                marginBottom: "-20px",
                              }}
                              className="alert alert-warning alert-dismissible fade show mb-5"
                              role="alert"
                              mt-3
                            >
                              {carts === "Already Added to Cart" ||
                              carts === "Added to Cart" ? (
                                <Link to="/cart">
                                  {" "}
                                  {carts} -{" "}
                                  <span style={{ textDecoration: "underline" }}>
                                    {" "}
                                    Go to Cart
                                  </span>{" "}
                                </Link>
                              ) : (
                                carts
                              )}
                              <button
                                type="button"
                                className="btn-close"
                                //   onClick={()=>dispatch({type:addCart.add.success,data:false})}
                                data-dismiss="alert"
                              ></button>
                            </div>
                          ) : (
                            false
                          )}
                        </div>
                        <div>
                          {wish ? (
                            <div
                              style={{
                                marginTop: "20px",
                                marginBottom: "-20px",
                              }}
                              className="alert alert-warning alert-dismissible fade show mb-5"
                              role="alert"
                              mt-3
                            >
                              {wish}
                              <button
                                type="button"
                                className="btn-close"
                                data-dismiss="alert1"
                                //   onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                                aria-label="Close"
                              ></button>
                            </div>
                          ) : (
                            false
                          )}
                        </div>

                        <div className="cart-btn mb-4">
                          <div className="add-to_cart">
                            <a
                              className="btn btn-dark btn-hover-primary"
                              onClick={() => {
                                cartId.variantId = Product[changeVariant].id;
                                cartId.units = quantity;
                                cartId.variantColor = JSON.parse(
                                  Product[changeVariant].variantColor
                                )[change];
                                dispatch(addCarts(cartId)).then((res) => {
                                  toast(res.data.data);
                                });
                              }}
                            >
                              Add to cart
                            </a>
                          </div>
                        </div>

                        <span>
                          <a
                            href={
                              "/productDetails/" +
                              Product[changeVariant]?.productId
                            }
                            style={{
                              fontSize: "18px",
                              textDecoration: "underline",
                            }}
                          >
                            View More Details
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  false
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <Modal /> */}
    </div>
  );
};
export default Home;
