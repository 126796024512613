import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getWishLists, addWishLists } from "../Redux/Action";
import { CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { AiOutlineDelete } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import css2 from "../Assets/wishlist.svg";
import swal from "sweetalert2";

//

const WishList = () => {
  const Swal = require("sweetalert2");
  const [load, setLoad] = useState(true);
  const [render, setRender] = useState(true);
  const dispatch = useDispatch();
  const wish = useSelector(({ getWishLists }) => getWishLists.payload);
  const Token = localStorage.getItem("AuthTok");
  useEffect(() => {
    dispatch(getWishLists())
      .then((res) => setLoad(false))
      .catch((res) => setLoad(false));
  }, [render]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "#ff3e3e",
  };
  return load ? (
    <div className="row">
      <div className="col-lg-12">
        <div
          className="sweet-loading"
          style={{ margin: "193px", display: "flex" }}
        >
          <ClipLoader cssOverride={override} />
        </div>
      </div>
    </div>
  ) : (
    <div>
      {" "}
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active"> Wishlist </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className="section section-margin"
        style={{
          marginTop: "99px",
          marginBottom: "80px",
        }}
      >
        <div className="container">
          {Array?.isArray(wish) && wish?.length > 0 ? (
            <div className="row">
              <div className="col-12">
                <div className="wishlist-table table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="pro-thumbnail">S No</th>
                        <th className="pro-thumbnail">Image</th>
                        <th className="pro-title">Product</th>
                        <th className="pro-price">Price</th>

                        <th className="pro-remove">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(wish) && wish.length !== 0 ? (
                        wish?.map((pro, i) => (
                          <tr key={i}>
                            <td className="product-subtotal">
                              <a style={{ color: "black", maxWidth: "50px" }}>
                                {i + 1}
                              </a>
                            </td>
                            <td className="pro-thumbnail">
                              {" "}
                              <Link to="/product">
                                <img
                                  src={
                                    process.env.REACT_APP_URL +
                                    pro?.productImage
                                  }
                                  alt={pro?.productName}
                                  width="68"
                                  height="84"
                                />
                              </Link>
                            </td>
                            <td className="pro-title">
                              {" "}
                              <a
                                className="title"
                                href="/product"
                                style={{ textTransform: "capitalize" }}
                              >
                                {pro?.productName}
                              </a>
                            </td>
                            <td className="pro-price">
                              <span> ₹{pro?.discountPrice} </span>
                            </td>

                            <td className="pro-remove">
                              {" "}
                              <a
                                onClick={() => {
                                  setLoad(true);
                                  dispatch(
                                    addWishLists({ productId: pro.id })
                                  ).then(
                                    (res) => setRender(!render),
                                    setLoad(false)
                                  );
                                }}
                              >
                                <AiOutlineDelete
                                  style={{ height: "28px", width: "40px" }}
                                />
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <center>
                          <h3 className="mt-5 mb-5 pb-5 pt-5">
                            "No Product Found!"
                          </h3>{" "}
                        </center>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <center>
              <img
                src={css2}
                className="mt-5 mb-5  pt-5"
                style={{ width: "25%" }}
              />
              <h3 className="mb-5 pb-3" style={{ textTransform: "capitalize" }}>
                {" "}
                your Wishlist is empty
              </h3>{" "}
              <td colspan="6" style={{ textAlign: "left" }}>
                <Link
                  to="/product"
                  className="btn btn-primary btn-hover-dark"
                  style={{
                    fontSize: "15px",
                    outline: "0",
                    boxShadow: "0px 0px 0px 0px",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Continue shopping
                </Link>
              </td>
            </center>
          )}
        </div>
      </div>
    </div>
  );
};

export default WishList;
