import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="container">
      <div className="row">
          <div className="col">
      
<h3 className='text-center py-5'>PRIVACY POLICY</h3>

<p>Bhumi Doris understands that the privacy of your personal information is very important to you, we value the trust you place in us. That's why we insist upon the customer information privacy and highest standards secure transactions. Please read the following statement to learn about our information gathering and dissemination practices. </p>
<p>This Privacy Policy describes the information, we collect from you and how we use that information from www.BhumiDoris.com. By visiting and/or using our website, you agree that you have read and accepted the Privacy Policy and the User Agreement of the Website and you express consent to us using and you disclosing your personal information in accordance with this Privacy Policy. If you do not agree please do not use or access our Website.
</p>
<p>By subscribing to or using any of our products or services, you agree that you have read, understood, and are bound by the Terms and Conditions, regardless of how you subscribe to or use our products or services. If you do not want to be bound by the terms, you must not subscribe to or use our services.</p>

<h6>PRIVACY GUARANTEE</h6>
<p>Bhumi Doris will endeavor to take all reasonable steps to maintain and protect your personal information we hold. We undertake not to sell or rent your personal information to third parties for their marketing purposes without your explicit consent.</p>

<h6>COLLECTION OF YOUR INFORMATION</h6>
<p>When you use our website, we collect and store your personal information. Our primary goal in doing so is to provide a safe, efficient, smooth and customized experience. This allows us to provide services and features to meet your needs and to customize our website to make your experience safer and easier. Importantly, we only collect personal information about you that we consider necessary for achieving this purpose.</p>
<p>In general, you can browse the website without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us. To fully use our website, you will need to register using our online registration form, where you may be required to provide us with your contact and identity information, billing information, shipping information and other personal information as indicated on the registration form.</p>
<p>
This information may include the Uniform Resource Locator (URL that you just came from (whether this URL is on our website or not), which URL you next go to (whether this URL is on our website or not), your computer browser information, and your IP address. We use data collection devices such as "cookies" on certain pages of the website to help analyze our web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small files placed on your hard drive that assist us in providing our services. We offer certain features that are only available through the use of a "cookie". We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are "session cookies," meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on the website and you may be required to re-enter your password more frequently during a session. We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems as permitted by applicable law in the India.
</p>
<p>Additionally, you may encounter "cookies" or other similar devices on certain pages of the website that are placed by third parties. For example, if you view a web page created by a user, there may be a "cookie" placed within that web page. We do not control the use of cookies by third parties.</p>


<h6>USE AND DISCLOSURE OF YOUR INFORMATION</h6>
<p>We use your personal information to facilitate the services you have placed on Bhumi Doris. We use your personal information in the file we maintain about you, and other information we obtain from your current and past activities on the website to: collect fees owed; measure consumer interest in the services provided by us, inform you about online and offline offers, products, services, and updates; customize your experience; and also detect and protect us against fraud and other criminal activity; enforce our User Agreement and as otherwise described to you at the time of collection. You agree that we may use personal information about you to improve our marketing and promotional efforts, to analyze website usage, improve the website's content and product offerings, and customize the website's content, layout, and services. These uses improve the website and better tailor it to meet your needs, so as to provide you with a smooth, efficient, safe and customized experience while using the website.</p>

<p>Bhumi Doris will not use or disclose any personal information about you without your consent, unless we consider it is necessary: To comply with our Terms of Use To comply with the legal disclosure requirements in response to a verified requests by Courts of law or other law enforcement bodies or Government officials etc. relating to any breach of law, infringement, fraud, legal enquiry, criminal investigation or other alleged illegal activity To protect the rights and/or property of Bhumi Doris, network users, subscribers and members or any other individual or entity To reduce or avoid a threat to anyone's health, safety or well-being To provide services to you</p>
<h6>CREDIT CARD INFORMATION</h6>
<p>Bhumi Doris has contracted the services of a banking partner to process credit/debit card transactions made online. Our banking partners' secured server encrypts your credit/debit card information so that it may be safely transmitted over the Web. Your credit/debit card information will not be released by Bhumi Doris or its banking partner to any other party, without your express consent. However, such information may be disclosed if so required under any law for the time being in force or if required under any order of a Court of law.</p>
<h6>LINKS TO NON- BHUMI DORIS WEBSITES</h6>
<p>Our website may provide links to third-party websites for your convenience and information. If you access those links, you will leave our website. We do not control those sites or their 'privacy policy' practices, which may differ from our practices. We do not endorse or make any representations about third-party websites. The personal data you choose to provide to or that is collected by these third parties is not covered by our Privacy Statement. We encourage you to review the privacy policy of any company before submitting your personal information.</p>


<h6>CHILDREN'S PRIVACY</h6>
<p>Children under the age of 13 years are not permitted to use this website. We strongly recommend that children between the ages of 13 and 18 ask for their parent's or guardian's permission before viewing our website.</p>
 
 <h6>STORAGE AND SECURITY OF YOUR INFORMATION</h6>
 <p>Bhumi Doris will endeavor to take all reasonable steps to maintain the accuracy and security of any of your personal information we hold. When you place orders or access your account information, we offer the use of a secure server. Furthermore, all of the customer data we collect is protected against unauthorized access. To prevent unauthorized access, maintain data accuracy, and ensure correct use of information, we will employ reasonable and current Internet security methods and technologies.</p>
 
 <h6>QUESTIONS AND CONCERNS</h6>
 <p>You may contact Bhumi Doris if you have any questions or concerns relating to our Privacy Policy or any other aspect of the website.</p>
 <h6>AMENDMENT OF POLICY</h6>

 <p className='py-3'>Bhumi Doris reserves its right to amend this Privacy Policy at any time, and for whatsoever reason it may deem fit, appropriate and necessary in the interest of one or more. No individual notice of such changes will be provided but they will appear on the website in the form of a revised and updated version of the Privacy Policy. We recommend you to refer to this Policy on a regular basis so you are aware of any changes</p>
 </div>
    </div>
    </div>
        </div> 
  )
}

export default PrivacyPolicy