import React from "react";
import orderSuccess from "../Assets/success.svg";
import { Link, useNavigate } from "react-router-dom";

const OrderSuccess = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active">Order Success</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <center>
        <img
          src={orderSuccess}
          className="py-2"
          style={{ width: "30%", margin: "90px" }}
          alt="order success"
        />
        <div
          className="single-product-cart "
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "70px",
          }}
        >
          <a
            className="btn btn-primary btn-hover-dark"
            style={{ marginRight: "31px" }}
            onClick={() => navigate("/orders")}
          >
            Track Orders <span></span>
          </a>
          <a
            className="btn btn-primary btn-hover-dark"
            onClick={() => navigate("/product")}
          >
            Keep Shopping <span></span>
          </a>
        </div>
      </center>
    </div>
  );
};

export default OrderSuccess;
