import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyPasswords } from "../Redux/Action";
import Swal from "sweetalert2/dist/sweetalert2.js";

const VerifyPassword = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: id,
    code: "",
    password: "",
  });
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.password != pass) {
      setError("New Password and Confirm Password Doesn't Match");
    } else {
      dispatch(verifyPasswords(data))
        .then((res) =>
          Swal.fire({
            icon: "success",
            timer: 4000,
            buttons: false,
            showConfirmButton: false,
            text: res.data.data,
          }).then(() => {
            navigate("/login", { replace: true });
          })
        )
        .catch((err) => {
          Swal.fire({
            icon: "error",
            timer: 4000,
            buttons: false,
            showConfirmButton: false,
            text: err.response.data.error.message,
          });
          var errMsg = err.response.data.error.message;
          if (errMsg === "Code Expired") {
            navigate("/forget");
            var errMsg = "Code Expired, Try Again !";
            localStorage.setItem("Error", errMsg);
          } else {
            setError(err.response.data.error.message);
          }
        });
    }
  };
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active"> Verify password</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-margin">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 m-auto">
              <div className="login-wrapper">
                <form onSubmit={handleSubmit}>
                  <div className="single-input-item mb-3">
                    <input
                      onChange={handleChange}
                      name="code"
                      required={true}
                      type="name"
                      placeholder="Verify Code"
                    />
                  </div>
                  <div className="single-input-item mb-3">
                    <input
                      required
                      type="password"
                      onChange={handleChange}
                      name="password"
                      pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%]).{8,16}$"
                      title="Must contain at least one number, uppercase, lowercase, special symbol among @#$%, and at least 8 to 16 characters"
                      placeholder="Enter password"
                    />
                  </div>
                  <div className="single-input-item mb-3">
                    <input
                      required
                      title="must match the password"
                      type="password"
                      onChange={(e) => setPass(e.target.value)}
                      placeholder="Enter Verify password"
                    />
                  </div>
                  <span
                    className="mb-1"
                    style={{
                      color: "red",
                      color: "red",
                      width: "299px",
                      background: "blanchedalmond",
                      fontSize: "14px",
                      display: "block",
                    }}
                  >
                    {" "}
                    {error}
                  </span>
                  <br />

                  <div className="single-input-item mb-3">
                    <button className="btn btn btn-dark btn-hover-primary rounded-0">
                      Next
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyPassword;
