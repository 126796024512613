import { AiOutlineHome } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { getUserAddress, deleteUserAddress } from "../Redux/Action";
import { getProfiles } from "../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import {
  getCategories,
  getCartCounts,
  getOrders,
  logoutUser,
  myReviews,
  deleteReviews,
} from "../Redux/Action";
import { Link, useNavigate } from "react-router-dom";
import orderimage from "../Assets/orders.svg";
import reviews from "../Assets/reviews found.svg";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ClipLoader from "react-spinners/ClipLoader";

const MyAccount = () => {
  const [error, setError] = useState("");
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [render, setRender] = useState(false);
  const order = useSelector(({ getOrders }) => getOrders.payload);

  const myReview = useSelector(({ myReviews }) => myReviews.payload);

  const logout = () => {
    dispatch(logoutUser());
    localStorage.removeItem("AuthTok");

    window.location.href = "/";
  };
  const handlereview = (G) => {
    dispatch(
      deleteReviews({
        action: "delete",
        id: G.id,
        rating: G.rating,
        review: G.review,
      })
    );
    setRender(!render);
  };

  useEffect(() => {
    dispatch(myReviews()).then((res) => setLoad(false));
  }, [render]);

  useEffect(() => {
    dispatch(getOrders())
      .then((res) => setLoad(false))
      .catch((res) => setLoad(false));
  }, []);

  useEffect(() => {
    dispatch(getUserAddress()).then((res) => setLoad(false));
  }, []);

  useEffect(() => {
    dispatch(getProfiles()).then((res) => setLoad(false));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Address = useSelector(({ getUserAddress }) => getUserAddress.payload);
  const profileList = useSelector(({ getProfiles }) => getProfiles.payload);

  // const override: CSSProperties = {
  //   display: "block",
  //   margin: "0 auto",
  //   borderColor: "#ff3e3e",
  // };
  return (
    <div>
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active"> My Account Page</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-margin">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="myaccount-page-wrapper">
                <div className="row">
                  <div className="col-lg-3 col-md-4">
                    <div className="myaccount-tab-menu nav" role="tablist">
                      <a href="#orders" className="active" data-bs-toggle="tab">
                        <i className="fa fa-cart-arrow-down"></i> Orders
                      </a>
                      <a href="#download" data-bs-toggle="tab">
                        <i className="fa fa-cloud-download"></i> Review
                      </a>
                      {/* <a href="#payment-method" data-bs-toggle="tab"><i className="fa fa-credit-card"></i> Payment Method</a> */}
                      <a href="#address-edit" data-bs-toggle="tab">
                        <i className="fa fa-map-marker"></i>Address
                      </a>
                      <a href="#account-info" data-bs-toggle="tab">
                        <i className="fa fa-user"></i> Account Details
                      </a>
                    </div>
                  </div>

                  <div className="col-lg-9 col-md-8">
                    <div className="tab-content" id="myaccountContent">
                      <div
                        className="tab-pane fade show active"
                        id="orders"
                        role="tabpanel"
                      >
                        <div className="myaccount-content">
                          <h3 className="title">Orders</h3>
                          <div className="myaccount-table table-responsive text-center">
                            <table className="table table-bordered">
                              <thead className="thead-light">
                                {Array.isArray(order) && order.length !== 0 ? (
                                  <tr>
                                    <th>S.No</th>
                                    <th>Order Id</th>
                                    <th>Image</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                  </tr>
                                ) : (
                                  false
                                )}
                              </thead>
                              <tbody>
                                {Array.isArray(order) && order.length !== 0 ? (
                                  order?.map((data, i) => (
                                    <tr key={i}>
                                      <td>{i + 1}</td>
                                      <td>
                                        {" "}
                                        <Link
                                          to={"/orderDetails/" + data.orderId}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {data.orderId}
                                        </Link>
                                      </td>
                                      <td>
                                        <Link
                                          to={"/orderDetails/" + data.orderId}
                                        >
                                          <img
                                            src={
                                              process.env.REACT_APP_URL +
                                              data.variantImages[0]
                                            }
                                            alt=""
                                            style={{ height: "10vh" }}
                                          />
                                        </Link>
                                      </td>
                                      <td>
                                        {" "}
                                        <Link
                                          to={"/orderDetails/" + data?.orderId}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {data?.timestamp}
                                        </Link>
                                      </td>
                                      <td>
                                        {" "}
                                        <Link
                                          to={"/orderDetails/" + data.orderId}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {data?.orderStatus}
                                        </Link>
                                      </td>
                                      <td>
                                        <Link
                                          to={"/orderdetails/" + data.orderId}
                                          style={{ color: "white" }}
                                          className="btn btn btn-dark btn-hover-primary btn-sm rounded-0"
                                        >
                                          Track
                                        </Link>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <center>
                                    <img
                                      src={orderimage}
                                      className="mt-5 mb-5 pb-5 pt-5"
                                      style={{ width: "25%" }}
                                    />
                                    <h3> No Orders Found</h3>{" "}
                                  </center>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="download"
                        role="tabpanel"
                      >
                        <div className="myaccount-content">
                          <h3 className="title">Review</h3>
                          <div className="myaccount-table table-responsive text-center">
                            <table className="table table-bordered">
                              {Array.isArray(myReview) ? (
                                <thead className="thead-light">
                                  <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>Image</th>
                                    <th>Price </th>
                                    <th>Review</th>
                                    <th>Rating</th>
                                  </tr>
                                </thead>
                              ) : (
                                <h3 className="pt-5 pb-5">
                                  {" "}
                                  <center>
                                    <img
                                      src={reviews}
                                      className="mt-5 mb-5 pb-5 pt-5"
                                      style={{ width: "25%" }}
                                    />
                                    <h3> No Reviews Found</h3>{" "}
                                  </center>
                                </h3>
                              )}
                              <tbody>
                                {Array.isArray(myReview) &&
                                myReview.length !== 0
                                  ? myReview?.map((data, i) => (
                                      <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>
                                          <h5>
                                            <a
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {data.productName}
                                            </a>
                                          </h5>
                                        </td>
                                        <td>
                                          <a>
                                            <img
                                              src={
                                                process.env.REACT_APP_URL +
                                                data.variantImage
                                              }
                                              alt=""
                                              style={{ maxHeight: "100px" }}
                                            />
                                          </a>
                                        </td>
                                        <td>
                                          <h5>
                                            <a
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              ₹ {data?.actualPrice}
                                            </a>
                                          </h5>
                                        </td>
                                        <td>
                                          <h5>
                                            <a
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {data?.review}
                                            </a>
                                          </h5>
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            color: "#071C1F",
                                            fontWeight: "700",
                                          }}
                                        >
                                          {data?.rating}
                                        </td>
                                      </tr>
                                    ))
                                  : false}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade "
                        id="address-edit"
                        role="tabpanel"
                      >
                        <div className="myaccount-content">
                          <h3 className="title">Dashboard</h3>
                          <p>
                            The following addresses will be used on the checkout
                            page by default.
                            <br /> Add user address here &nbsp;{" "}
                            <a
                              className="btn btn btn-dark btn-hover-primary rounded-0"
                              href="/address"
                              style={{
                                marginBottom: "20px",
                                marginTop: "20px",
                                marginLeft: "10px",
                              }}
                            >
                              {" "}
                              Add Address{" "}
                            </a>
                          </p>
                        </div>
                        {Array.isArray(Address) && Address.length !== 0 ? (
                          Address?.map((data, i) => (
                            <div
                              key={i}
                              className="myaccount-table table-responsive text-center"
                            >
                              <table className="table table-bordered">
                                {Array.isArray(myReview) ? (
                                  <thead className="thead-light">
                                    <tr>
                                      <th>S.No</th>
                                      <th>Address Type</th>
                                      <th>Address</th>
                                      <th>Primary</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                ) : (
                                  false
                                )}
                                <tbody>
                                  <tr>
                                    <td style={{ verticalAlign: "middle" }}>
                                      {i + 1}
                                    </td>
                                    <td style={{ verticalAlign: "middle" }}>
                                      {data?.addressType}
                                    </td>
                                    <td>
                                      {data?.city +
                                        " ," +
                                        data?.district +
                                        " ," +
                                        data?.landmark +
                                        " " +
                                        data?.state +
                                        ", " +
                                        data?.street +
                                        ", " +
                                        data?.zipcode}
                                    </td>
                                    <td style={{ verticalAlign: "middle" }}>
                                      {data?.primary}
                                    </td>
                                    <td style={{ verticalAlign: "middle" }}>
                                      {" "}
                                      <Link
                                        to={"/updateAddress/" + data?.id}
                                        style={{
                                          cursor: "pointer",
                                          margin: "7px",
                                        }}
                                      >
                                        {" "}
                                        <span
                                          style={{
                                            fontSize: "20px",
                                            color: "black",
                                          }}
                                          className="fa fa-edit"
                                        ></span>
                                      </Link>
                                      <a
                                        onClick={() => {
                                          dispatch(
                                            deleteUserAddress({
                                              addressId: data.id,
                                              action: "delete",
                                            })
                                          )
                                            .then((res) =>
                                              Swal.fire({
                                                icon: "success",
                                                timer: 4000,
                                                buttons: false,
                                                showConfirmButton: false,
                                                text: res.data.data,
                                              })
                                            )

                                            .catch((err) => {
                                              Swal.fire({
                                                icon: "error",
                                                timer: 4000,
                                                buttons: false,
                                                showConfirmButton: false,
                                                text: err.response.data.error
                                                  .message,
                                              });
                                            });
                                        }}
                                      >
                                        {" "}
                                        <span
                                          style={{
                                            fontSize: "20px",
                                            color: "black",
                                          }}
                                          className="fa fa-trash"
                                        ></span>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ))
                        ) : (
                          <div className="alert alert-secondary" role="alert">
                            NO ADDRESS FOUND
                          </div>
                        )}
                      </div>

                      <div
                        className="tab-pane fade"
                        id="account-info"
                        role="tabpanel"
                      >
                        <div className="myaccount-content">
                          <h3 className="title">Account Details</h3>
                          <div className="myaccount-table table-responsive text-center">
                            <table className="table table-bordered">
                              <thead className="thead-light">
                                <tr>
                                  <th>User name</th>

                                  <th>Email</th>
                                  <th>Phone</th>
                                  <th>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td>{profileList?.userName}</td>

                                  <td>{profileList?.email}</td>
                                  <td>{profileList?.phone}</td>
                                  <td>
                                    {" "}
                                    <span className="badge bg-success">
                                      <Link
                                        to={"/updateProfile/" + profileList?.id}
                                        style={{ color: "white" }}
                                      >
                                        Edit
                                      </Link>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
