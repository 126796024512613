import React, { useState, useEffect } from "react";
import { getCarts, updateCarts, removeCarts } from "../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import { CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import cartimage from "../Assets/shopping_cart.svg";
import plus from "../Assets/plus.png";
import minus from "../Assets/minus.png";

const Cart = () => {
  const [count, setCount] = useState(1);
  const [load, setLoad] = useState(true);
  const [quantity, setQuantity] = useState("");
  const getCart = useSelector(({ getCarts }) => getCarts.payload);
  const [render, setRender] = useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const dispatch = useDispatch();
  useEffect(() => {}, [getCart]);

  let navigate = useNavigate();

  const catshop = (id) => {
    navigate("/categoryShop/" + id);
  };

  const Increment = (C) => {
    dispatch(updateCarts({ units: "sub", cartId: C.id }));
    setCount(count + 1);
    setRender(!render);
  };

  const Decrement = (C) => {
    dispatch(updateCarts({ units: "add", cartId: C.id }));
    setCount(count - 1);
    setRender(!render);
  };
  useEffect(() => {
    dispatch(getCarts())
      .then((res) => setLoad(false))
      .catch((res) => setLoad(false));
  }, [render]);

  useEffect(() => {
    if (getCart?.cart) {
      setQuantity(getCart?.cart?.map((A, i) => <span key={i}>{A.units}</span>));
    }
  }, [getCart]);

  function Update(id) {
    window.location.href = `/productdetails/` + id;
  }

  const handleClickplus = (i, C) => {
    dispatch(updateCarts({ units: "add", cartId: C.id }));
    quantity[i] = quantity[i] + 1;
    setRender(!render);
  };
  const handleClickminus = (i, C) => {
    dispatch(updateCarts({ units: "sub", cartId: C.id }));
    quantity[i] = quantity[i] - 1;
    setRender(!render);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //   useEffect(() => {
  //     setTimeout(() => {
  //       /*----------------------------------------*/
  //       /*  Cart Plus Minus Button
  //             /*----------------------------------------*/

  //
  //       window.$(".qtybutton").on("click", function () {
  //         var $button = window.$(this);
  //         var oldValue = $button.parent().find("input").val();
  //         if ($button.hasClass("inc")) {
  //           var newVal = parseFloat(oldValue) + 1;
  //         } else {
  //           // Don't allow decrementing below zero
  //           let index = $button
  //
  //           if (oldValue > 1) {
  //             var newVal = parseFloat(oldValue) - 1;
  //           } else {
  //             newVal = 1;
  //           }
  //         }
  //         $button.parent().find("input").val(newVal);
  //       });
  //     }, 100);

  //     window.$(function () {
  //       window.$(".increment").click(function () {
  //         var valueElement = window.$(
  //           "#" + window.$(this).siblings("input").attr("id")
  //         );

  //         if (window.$(this).hasClass("plus")) {
  //           valueElement.val(Math.max(parseInt(valueElement.val()) + 1));
  //         } else if (valueElement.val() > 0) {
  //           // Stops the value going into negatives
  //           valueElement.val(Math.max(parseInt(valueElement.val()) - 1));
  //         }

  //         return false;
  //       });
  //     });
  //   }, []);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#ff3e3e",
  };
  return load ? (
    <div className="row">
      <div className="col-lg-12">
        <div
          className="sweet-loading"
          style={{ margin: "193px", display: "flex" }}
        >
          <ClipLoader cssOverride={override} />
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active"> Shopping Cart</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-margin">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {Array?.isArray(getCart?.cart) && getCart?.cart?.length != 0 ? (
                <div className="cart-table table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="pro-thumbnail">S.No</th>
                        <th className="pro-thumbnail">Image</th>
                        <th className="pro-title">Product</th>
                        <th className="pro-price">Price</th>
                        <th className="pro-quantity">Quantity</th>
                        <th className="pro-subtotal">Total</th>
                        <th className="pro-remove">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array?.isArray(getCart?.cart) &&
                      getCart?.cart?.length != 0 ? (
                        getCart?.cart.map((C, i) => (
                          <tr key={i}>
                            <td className="pro-thumbnail">
                              <a style={{ color: "black" }}>{i + 1}</a>
                            </td>

                            <td className="pro-thumbnail">
                              {" "}
                              <Link to={"/productDetails/" + C.productId}>
                                <img
                                  src={
                                    process.env.REACT_APP_URL + C?.variantImage
                                  }
                                  width="68"
                                  height="84"
                                  alt="Image-HasTech"
                                />
                              </Link>
                            </td>
                            <td
                              className="pro-title"
                              style={{ textTransform: "capitalize" }}
                            >
                              <Link to={"/productDetails/" + C.productId}>
                                {C?.productName}
                              </Link>
                            </td>
                            <td className="pro-price">
                              {" "}
                              <span> ₹ {C?.singleProductPrice}</span>
                            </td>
                            <td>
                              <div
                                className="row "
                                style={{
                                  justifyContent: "space-around",
                                  margin: "0",
                                }}
                              >
                                <span
                                  className="badge bg-light text-dark "
                                  style={{
                                    cursor: "pointer",
                                    width: "fit-content",
                                  }}
                                  onClick={() => handleClickminus(i, C)}
                                >
                                  -
                                </span>
                                <span
                                  className="badge bg-light text-dark "
                                  style={{ width: "fit-content" }}
                                >
                                  {C.units}
                                </span>
                                <span
                                  className="badge bg-light text-dark "
                                  style={{
                                    cursor: "pointer",
                                    width: "fit-content",
                                  }}
                                  onClick={() => handleClickplus(i, C)}
                                >
                                  +
                                </span>
                              </div>
                            </td>
                            {/* <td>
                              <div className="quantity d-flex align-items-center mb-5">
                                <div className="cart-plus-minus">
                                  <input
                                    className="cart-plus-minus-box"
                                    value={C.units}
                                    type="text"
                                  />
                                  

                                  
                                  <div className="decs qtybutton" onClick={() => handleClickminus(i, C)} data-index={i} data-params={C}  style={{padding:"30px"}} >
                                  </div>
                                      
                                  <div className="incs qtybutton" onClick={() => handleClickplus(i, C)} style={{padding:"30px"}}> 
                                    <a
                                      className="inc qtybutton"
                                      onClick={() => handleClickplus(i, C)}
                                    ></a>
                                  </div>
                                </div>
                              </div>
                            </td> */}
                            <td className="pro-subtotal">
                              <span> ₹ {C?.totalPrice}</span>
                            </td>
                            <td>
                              <a
                                onClick={() => {
                                  setLoad(true);
                                  dispatch(removeCarts({ cartId: C?.id })).then(
                                    (res) => setLoad(false)
                                  );
                                }}
                              >
                                <AiOutlineDelete
                                  style={{ height: "24px", width: "24px" }}
                                />
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <center>
                          <h3 className="mt-5 mb-5 pb-5 pt-5">
                            "No Product Found!"
                          </h3>{" "}
                        </center>
                      )}
                      <tr className="pro-subtotal">
                        <td colspan="6" style={{ textAlign: "left" }}>
                          <Link
                            to="/product"
                            className="btn btn-primary btn-hover-dark"
                            style={{
                              fontSize: "15px",
                              outline: "0",
                              boxShadow: "0px 0px 0px 0px",
                              fontWeight: "600",
                            }}
                          >
                            {" "}
                            Continue shopping
                          </Link>
                        </td>
                        <td colspan="6" style={{ textAlign: "" }}>
                          <Link
                            to="/checkout"
                            className="btn btn-primary btn-hover-dark"
                            style={{
                              fontSize: "15px",
                              outline: "0",
                              boxShadow: "0px 0px 0px 0px",
                              fontWeight: "600",
                            }}
                          >
                            {" "}
                            Checkout
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <center>
                  <img
                    src={cartimage}
                    className="mt-5 mb-5  pt-5"
                    style={{ width: "25%" }}
                  />
                  <h3
                    className="mb-5 pb-3"
                    style={{ textTransform: "capitalize" }}
                  >
                    {" "}
                    your shopping cart is empty
                  </h3>{" "}
                  <tr className="product-name">
                    <td colspan="6" style={{ textAlign: "left" }}>
                      <Link
                        to="/product"
                        className="btn btn-primary btn-hover-dark"
                        style={{
                          fontSize: "15px",
                          outline: "0",
                          boxShadow: "0px 0px 0px 0px",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        Continue shopping
                      </Link>
                    </td>
                  </tr>
                </center>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
