import React, { useEffect, useState, useContext } from "react";
import { ActivePageContext } from "./ActivePage";

import { useNavigate, Link } from "react-router-dom";
import logo from "../Assets/logo.svg";

import { AiOutlineEye } from "react-icons/ai";

import whatsapp from "../Assets/whatsapp-removebg-preview.png";
import paytm from "../Assets/payment1.png";
import facebook from "../Assets/facebook.svg";
import instagram from "../Assets/instagram.svg";
import linkedin from "../Assets/linkedin.svg";

const today = new Date();
const year = today.getFullYear();

const Footer = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window.$(".qtybutton").on("click", function () {
        var $button = window.$(this);
        var oldValue = $button.parent().find("input").val();
        if ($button.hasClass("inc")) {
          var newVal = parseFloat(oldValue) + 1;
        } else {
          // Don't allow decrementing below zero
          if (oldValue > 1) {
            var newVal = parseFloat(oldValue) - 1;
          } else {
            newVal = 1;
          }
        }
        $button.parent().find("input").val(newVal);
      });
      // var swiper = new window.$.Swiper(".hero-slider.swiper-container", {
      //   loop: true,
      //   speed: 1150,
      //   spaceBetween: 30,
      //   slidesPerView: 1,
      //   effect: "fade",
      //   pagination: true,
      //   navigation: true,

      //   // Navigation arrows
      //   navigation: {
      //     nextEl: ".hero-slider .home-slider-next",
      //     prevEl: ".hero-slider .home-slider-prev",
      //   },
      //   pagination: {
      //     el: ".hero-slider .swiper-pagination",
      //     type: "bullets",
      //     clickable: "true",
      //   },
      //   // Responsive breakpoints
      // });
    }, 2000);
  }, []);
  const handleClick = (e) => {
    e.preventDefault();
    navigate(`${"/searchProducts/" + search}`);
  };

  // active page in nav
  const pageName = useContext(ActivePageContext).value;

  return (
    <div>
      <footer className="section footer-section">
        <div
          className="footer-top section-padding"
          style={{ backgroundColor: "rgb(215 215 215)" }}
        >
          <div className="container">
            <div
              className="row mb-n8 
"
            >
              <div className="col-12 col-sm-6 col-lg-2  mb-8">
                <div className="single-footer-widget">
                  <Link className="widget-logo" to="/">
                    <img
                      style={{ width: "180px" }}
                      src={logo}
                      alt="Site Logo"
                    />
                  </Link>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg-2 mb-8">
                <div className="single-footer-widget aos-init aos-animate">
                  <h2 className="widget-title" style={{ color: "black" }}>
                    Information
                  </h2>
                  <ul className="widget-list">
                    <li
                      style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      <Link to={"/about"} style={{ color: "black" }}>
                        About Us
                      </Link>
                    </li>
                    <li
                      style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      <Link to={"/product"} style={{ color: "black" }}>
                        Out Products
                      </Link>
                    </li>
                    <li
                      style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      <Link
                        to={"/termsandcondition"}
                        style={{ color: "black" }}
                      >
                        Terms & Conditions
                      </Link>
                    </li>
                    <li
                      style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      <Link to={"/privacypolicy"} style={{ color: "black" }}>
                        Privacy Policy
                      </Link>
                    </li>
                    <li
                      style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      <Link to="/returnexchange" style={{ color: "black" }}>
                        Shipping Policy
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-2 mb-8">
                <div className="single-footer-widget aos-init aos-animate">
                  <h2 className="widget-title" style={{ color: "black" }}>
                    Help & Support
                  </h2>
                  <ul className="widget-list">
                    <li
                      style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      <Link to={"/contact"} style={{ color: "black" }}>
                        Wholesale Enquires
                      </Link>
                    </li>
                    <li
                      style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      <Link to="/returnrefund" style={{ color: "black" }}>
                        Return & Refund
                      </Link>
                    </li>
                    <li
                      style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      <Link to="/faq" style={{ color: "black" }}>
                        FAQs
                      </Link>
                      to{" "}
                    </li>
                    <li
                      style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      <Link to="/myaccount" style={{ color: "black" }}>
                        Track Order
                      </Link>
                      to{" "}
                    </li>

                    <li
                      style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      <Link to="/contact" style={{ color: "black" }}>
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              {/* keep in touch */}

              <div className="col-12 col-sm-6 col-lg-3 mb-8">
                <div className="single-footer-widget">
                  <h2 className="widget-title" style={{ color: "black" }}>
                    Keep in Touch
                  </h2>
                  <ul className="contact-links">
                    <li
                      style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      <i className="pe-7s-mail" style={{ color: "black" }}></i>
                      <a
                        href="mailto:info@bhumidoris.com"
                        style={{ color: "black" }}
                      >
                        info@bhumidoris.com
                      </a>
                    </li>

                    <li
                      style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      <i className="pe-7s-call" style={{ color: "black" }}></i>
                      <a href="tel: +919842757058" style={{ color: "black" }}>
                        {" "}
                        +91 98427 57058
                      </a>
                    </li>
                  </ul>

                  <div className="widget-social justify-content-start mt-3 ml-2">
                    <a
                      rel="noreferrer"
                      title="Facebook"
                      href="https://www.facebook.com/profile.php?id=100084326950943&mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <img
                        style={{
                          display: "inline-block",
                          width: "100%",
                          height: "100%x",
                        }}
                        src={facebook}
                        alt=""
                      />
                    </a>
                    <a
                      rel="noreferrer"
                      title="Instagram"
                      href="https://www.instagram.com/bhumidoris/?igshid=YmMyMTA2M2Y%3D"
                      target="_blank"
                    >
                      <img
                        style={{
                          display: "inline-block",
                          width: "100%",
                          height: "100%x",
                        }}
                        src={instagram}
                        alt=""
                      />
                    </a>
                    <a
                      rel="noreferrer"
                      title="LinkedIn"
                      href="https://www.linkedin.com/in/bhumi-doris-02509125a"
                      target="_blank"
                    >
                      <img
                        src={linkedin}
                        alt=""
                        style={{
                          display: "inline-block",
                          width: "100%",
                          height: "100%x",
                        }}
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 mb-8">
                <div className="single-footer-widget">
                  <h2 className="widget-title" style={{ color: "black" }}>
                    We Accept
                  </h2>
                  <img style={{ width: "250px" }} src={paytm} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom  bg-primary pt-4 pb-4 ">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 text-center">
                <div className="copyright-content">
                  <p className="mb-0">
                    © {year}{" "}
                    <strong style={{ color: "rgba(68, 0, 255, 0.838)" }}>
                      Bhumi{" "}
                      <span style={{ color: "rgb(237,61,137)" }}> Doris </span>{" "}
                    </strong>{" "}
                    | Design by{" "}
                    <a target="_blank" href="https://nutz.in/" rel="noreferrer">
                      Nutz.{" "}
                    </a>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <a href="#" className="scroll-top show" id="scroll-top">
        <i className="arrow-top pe-7s-angle-up-circle"></i>
        <i className="arrow-bottom pe-7s-angle-up-circle"></i>
      </a>

      <div className="mobile-menu-wrapper">
        <div className="offcanvas-overlay"></div>

        <div className="mobile-menu-inner">
          <div className="offcanvas-btn-close">
            <i className="pe-7s-close"></i>
          </div>

          <div className="mobile-menu-inner-wrapper">
            <div className="search-box-offcanvas">
              <form method="post" onSubmit={handleClick}>
                <input
                  className="search-input-offcanvas"
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  placeholder="Search product..."
                />
                <button
                  className="search-btn  offcanvas-btn-close"
                  type="submit"
                >
                  {" "}
                  <AiOutlineEye style={{ height: "20px", width: "20px" }} />
                </button>
              </form>
            </div>

            <div className="mobile-navigation">
              <nav>
                <ul className="mobile-menu">
                  <li className="has-children">
                    <Link
                      className={`${pageName === "home" ? "active" : ""}`}
                      to={"/"}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="has-children">
                    <Link
                      className={`${pageName === "category" ? "active" : ""}`}
                      to={"/Categories"}
                    >
                      Categories
                    </Link>
                  </li>
                  <li className="has-children">
                    <Link
                      className={`${pageName === "shop" ? "active" : ""}`}
                      to={"/product"}
                    >
                      Shop
                    </Link>
                  </li>

                  <li>
                    <Link
                      className={`${pageName === "about" ? "active" : ""}`}
                      to={"/about"}
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${pageName === "contact" ? "active" : ""}`}
                      to={"/contact"}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* whatsapp btn */}
      <a
        href="https://api.whatsapp.com/send?phone=9842757058"
        target="_blank"
        rel="noreferrer"
        style={{
          padding: "1px 3px 2px 3px",
          backgroundColor: "#fff",
          display: "inline-block !important",
          position: "fixed",
          bottom: "120px",
          borderRadius: "5px",
          right: "10px",
          zIndex: "1000",
        }}
      >
        <img
          style={{
            width: "100px",
            display: "inline-block",
          }}
          src={whatsapp}
          alt=""
        />
      </a>
    </div>
  );
};

export default Footer;
