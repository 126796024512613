import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { payNows } from "../Redux/Action";
import { Link } from "react-router-dom";

// import paymet_Error from "../Assests/Images/paymet_Error.jpg";
import paymet_Error from "../Assets/paymet_Error.jpg";
import paymet_Success from "../Assets/paymet_Success.jpg";

const Loader = () => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div
          className="sweet-loading"
          style={{ margin: "193px", display: "flex" }}
        ></div>
      </div>
    </div>
  );
};

function PaymentStatus() {
  const dispatch = useDispatch();

  const [load, setLoad] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    //
    const payDetails = {
      orderId: localStorage.getItem("orderId"),
    };

    if (payDetails?.orderId) {
      dispatch(payNows(payDetails))
        .then((res) => {
          setPaymentStatus(res.data.data);
        })
        .catch((err) => {
          setPaymentStatus(err.response.data.error.message);
        })
        .finally(function () {
          setLoad(false);
        });
    }

    return () => {
      localStorage.removeItem("orderId");
    };
  }, []);

  return load ? (
    <>
      <Loader />
    </>
  ) : (
    <div className="section section-margin">
      <div className="container">
        <div className="row justify-content-center mb-n4">
          <img
            className="col-6"
            style={{
              minWidth: "400px",
              opacity: "0.9",
            }}
            src={
              paymentStatus === "Order Placed Successfully"
                ? paymet_Success
                : paymet_Error
            }
            alt="paymet_Success_illustartion"
          />
          <h2
            className="text-center col-12 "
            style={{
              color: "whitesmoke",
              textShadow: `3px 3px 2px ${
                paymentStatus === "Order Placed Successfully"
                  ? "#46fa4687"
                  : "#fa464687"
              } `,
            }}
          >
            {paymentStatus}
          </h2>
          <Link
            style={{
              backgroundColor: "#ffbf02",
              fontWeight: "bold",
            }}
            className="default-btn order-btn col-auto"
            to="/"
          >
            Go back to Home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PaymentStatus;
