import { useLocation } from "react-router-dom";
import { createContext } from "react";

export const ActivePageContext = createContext();

export const ActivePathName = { value: "dummy" };

export const ActivePage = () => {
  const path = useLocation().pathname.toLowerCase();

  if (path === "/") return (ActivePathName.value = "home");
  if (path.includes("category") || path.includes("categories"))
    return (ActivePathName.value = "category");
  if (path.includes("product")) return (ActivePathName.value = "shop");
  if (path.includes("about")) return (ActivePathName.value = "about");
  if (path.includes("contact")) return (ActivePathName.value = "contact");

  ActivePathName.value = "dummy";
};
