import React, { useState, useEffect } from "react";
import {
  getUserAddress,
  deleteUserAddress,
  addUserAddress,
} from "../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const Address = () => {
  const dispatch = useDispatch();
  const Swal = require("sweetalert2");
  const [address, setAddress] = useState({
    state: "",
    city: "",
    street: "",
    landmark: "",
    zipcode: "",
    primary: "",
    district: "",
    addressType: "",
  });
  const handleChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addUserAddress(address)).then((res) => {
      Swal.fire({
        icon: "success",
        timer: 4000,
        buttons: false,
        showConfirmButton: false,
        text: "Address Added Succcessfully!",
      })
        .then(() => {
          window.location.href = "";
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            timer: 4000,
            buttons: false,
            text: "Somethong Went Wrong!",
          }).then(() => {
            window.location.href = "/myaccounts";
          });
        });
    });
    //  setRender(true);
  };
  return (
    <div>
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active">Add Address</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-margin">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="myaccount-page-wrapper">
                <div className="row">
                  <div className="col-lg-12 col-md-8">
                    <div className="tab-content" id="myaccountContent">
                      <div className="myaccount-content">
                        <h3 className="title">Account Details</h3>
                        <div className="account-details-form">
                          <form action="#" onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="single-input-item mb-3">
                                  <label
                                    for="first-name"
                                    className="required mb-1"
                                  >
                                    Street{" "}
                                  </label>
                                  <input
                                    type="text"
                                    name="street"
                                    value={address.street}
                                    onChange={handleChange}
                                    id="first-name"
                                    placeholder="Street"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="single-input-item mb-3">
                                  <label
                                    for="last-name"
                                    className="required mb-1"
                                  >
                                    City{" "}
                                  </label>
                                  <input
                                    type="text"
                                    name="city"
                                    value={address.city}
                                    onChange={handleChange}
                                    id="last-name"
                                    placeholder="City"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="single-input-item mb-3">
                                  <label
                                    for="last-name"
                                    className="required mb-1"
                                  >
                                    District
                                  </label>
                                  <input
                                    type="text"
                                    id="last-name"
                                    name="district"
                                    value={address.district}
                                    required
                                    onChange={handleChange}
                                    placeholder="District"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="single-input-item mb-3">
                                  <label
                                    for="first-name"
                                    className="required mb-1"
                                  >
                                    Landmark
                                  </label>
                                  <input
                                    type="text"
                                    id="first-name"
                                    name="landmark"
                                    required
                                    value={address.landmark}
                                    required
                                    onChange={handleChange}
                                    placeholder="Landmark"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="single-input-item mb-3">
                                  <label
                                    for="last-name"
                                    className="required mb-1"
                                  >
                                    State
                                  </label>
                                  <input
                                    type="text"
                                    id="last-name"
                                    name="state"
                                    value={address.state}
                                    required
                                    onChange={handleChange}
                                    placeholder="State"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="single-input-item mb-3">
                                  <label
                                    for="last-name"
                                    className="required mb-1"
                                  >
                                    zipcode{" "}
                                  </label>
                                  <input
                                    type="number"
                                    name="zipcode"
                                    value={address.zipcode}
                                    onChange={handleChange}
                                    id="last-name"
                                    placeholder="zipcode "
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label for="country">
                                    Address Type
                                    <abbr className="required" title="required">
                                      *
                                    </abbr>
                                  </label>
                                  <select
                                    value={address.addressType}
                                    onChange={handleChange}
                                    className="form-select"
                                    name="addressType"
                                    aria-label="Default select example"
                                    style={{
                                      marginLeft: "0px",
                                      height: "49px",
                                      marginBottom: "15px",
                                    }}
                                    required
                                  >
                                    <option selected> Choose Address </option>
                                    <option value="home">Home </option>
                                    <option value="office">Office</option>
                                    <option value="other">Others</option>
                                  </select>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label for="country">
                                    Primary Address
                                    <abbr className="required" title="required">
                                      *
                                    </abbr>
                                  </label>
                                  <select
                                    required
                                    value={address.primary}
                                    onChange={handleChange}
                                    className="form-select"
                                    name="primary"
                                    aria-label="Default select example"
                                    style={{
                                      marginLeft: "0px",
                                      height: "49px",
                                      marginBottom: "15px",
                                    }}
                                    // style={{maxWidth:"529px",marginLeft:"0px",height:"49px",marginBottom:"15px"}}
                                  >
                                    <option selected> Is Primary</option>
                                    <option value="yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="single-input-item single-item-button">
                              <button
                                className="btn btn btn-dark btn-hover-primary rounded-0"
                                type="submit"
                              >
                                Add Address
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
