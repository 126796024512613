import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useSelector, useDispatch } from "react-redux";
import { faqs } from "../Redux/Action.js";
import { AiOutlineHome } from "react-icons/ai";
import { Link } from "react-router-dom";

const Faq = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [load, setLoad] = useState(true);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(faqs())
      .then((res) => {
        setLoad(false);
      })
      .catch(() => {});
  }, []);

  const faqquest = useSelector(({ faqs }) => faqs.payload);
  return (
    <div>
      <main className="main-content">
        <section
          className="page-header-area pt-10 pb-9"
          style={{ background: "#fff" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="page-header-st3-content text-center text-md-start">
                  <ol className="breadcrumb justify-content-center justify-content-md-start">
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to="/">
                        {" "}
                        <span style={{ fontSize: "22px" }}>
                          <AiOutlineHome />{" "}
                        </span>{" "}
                        Home <span> /</span>{" "}
                      </Link>{" "}
                      FAQ
                    </li>
                    <li
                      className="breadcrumb-item active text-dark"
                      aria-current="page"
                    ></li>
                  </ol>
                  <h2 className="page-header-title">FAQ</h2>
                </div>
              </div>
              <div className="col-md-7"></div>
            </div>
          </div>
        </section>
        <section className="faq-area">
          <div className="liton__wishlist-area m-10">
            <div className="container">
              <div className="row margin4" style={{ display: "flex" }}>
                {Array.isArray(faqquest) && faqquest.length !== 0 ? (
                  faqquest?.map((F, i) => (
                    <div key={i} className="col-md-6 offset-md-3">
                      <div
                        className="accordion accordion-flush p-1"
                        id="faqlist"
                      >
                        <Accordion
                          className="mt-3"
                          expanded={expanded === i}
                          onChange={handleChange(i)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh"
                          >
                            <Typography sx={{ width: "100%", flexShrink: 0 }}>
                              {/* {info.title} */}
                              <span>{F.title} </span>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>{F.answer}</Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <h3 className="text-center">No FAQ's Found</h3>{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* <!--== End Faq Area Wrapper ==--> */}
      </main>
    </div>
  );
};

export default Faq;
