import React, { useEffect, useState } from "react";

import cusStyle from "../Components/customStyle.module.css";

export const QuantitySettingBtn = ({ getQuantity }) => {
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    getQuantity(quantity);
  }, [quantity, getQuantity]);

  const quantityHandler = (changeName) => {
    if (changeName === "+") setQuantity((pre) => pre + 1);
    if (changeName === "-") setQuantity((pre) => (pre !== 1 ? pre - 1 : pre));
  };

  return (
    <div className={`${cusStyle.add_quant_container}`}>
      <span
        onClick={() => {
          quantityHandler("-");
        }}
        className={`${cusStyle.add_quant_dec}`}
      >
        -
      </span>
      <span className={`${cusStyle.add_quant_value}`}>{quantity}</span>
      <span
        onClick={() => {
          quantityHandler("+");
        }}
        className={`${cusStyle.add_quant_inc}`}
      >
        +
      </span>
    </div>
  );
};
