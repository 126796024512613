import React from "react";
import about from "../Assets/about.jpeg";
import about1 from "../Assets/about1.jpeg";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <div>
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active"> About Page</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-padding bg-milky">
        <div className="container">
          <div className="row align-items-center mb-n6">
            <div className="col-lg-5 mb-6">
              <div className="about-thumb">
                <img className="fit-image" src={about1} alt="About Image" />
              </div>
            </div>

            <div className="col-lg-7 mb-6">
              <div className="about-content">
                <h2 className="about-title">About Us</h2>
                <p style={{ textAlign: "justify", fontSize: "initial" }}>
                  In 2017, Bhumi Doris recognized leading clothing
                  manufacturers, wholesale suppliers, and exporters from South
                  India. Engaged in manufacturing, supplying and exporting a
                  wide range of garment to our clients across India & Middle
                  East Countries. In terms of quality, product range, service,
                  and reliability, Bhumi Doris products have a long tradition of
                  excellence.
                </p>
                <p style={{ textAlign: "justify", fontSize: "initial" }}>
                  {" "}
                  Our company manufactures all types of Apparel / Clothing and
                  Garments both knitted and woven for Women, Kids & Babies
                  products ranging from fashion styles to basic designs. The
                  wide variety of products we offer caters to all ages. All of
                  our products are of the highest quality and comply with the
                  current market demand. We ensure that our products comply with
                  all fixed standards in order to ensure their high durability.
                </p>
                <p style={{ textAlign: "justify", fontSize: "initial" }}>
                  Our vertical integration of manufacturing processes allows us
                  to tailor products to meet customer specifications with
                  reliable quality assurance at an affordable cost. At the same
                  time, we are able to develop innovative products while
                  controlling quality, costs and timely delivery of production.
                  A brilliant mix of technology, design, and manufacturing makes
                  up our team. Customers are our top priority, and we work hard
                  to meet their needs. We are a well-organized garment
                  manufacturer in Tamilnadu, India. Quality is controlled at
                  every stage of production by conducting systematic testing and
                  continuous monitoring of results.
                </p>
                <p style={{ textAlign: "justify", fontSize: "initial" }}>
                  Bhumi Doris aims to become one of the largest manufacturers
                  and exporters of ethically manufactured fair trade apparels
                  that are eco-friendly, sustainable, transparent, and follow
                  ethical labor practices. Eliminating eco-damaging processes is
                  our mission.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
