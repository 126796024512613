import { Modal, ModalBody } from "reactstrap";

export function CustomModal({ bannerImg, ...args }) {
  return (
    <div>
      <Modal centered size={"lg"} {...args}>
        <ModalBody>
          <img
            style={{ display: "block", width: "100%" }}
            src={bannerImg}
            alt="welcome banner img"
          />
        </ModalBody>
      </Modal>
    </div>
  );
}
