import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div>
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active"> Error 404 Page</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-margin">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="error_form">
                <h1 className="title">404</h1>
                <h2 className="sub-title">Opps! PAGE NOT BE FOUND</h2>
                <p>
                  Sorry but the page you are looking for does not exist, have
                  been
                  <br /> removed, name changed or is temporarily unavailable.
                </p>

                <Link
                  to={"/"}
                  className="btn btn-primary btn-hover-dark rounded-0"
                >
                  Back to home page
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
