import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import { ScrollToTop } from "../Common/Utils";
import Home from "../Components/Home";

import {
  ActivePageContext,
  ActivePage,
  ActivePathName,
} from "../Common/ActivePage";
import Categories from "../Components/Categories";
// import CategoriesProducts from "../Components/CategoriesProducts";
import Product from "../Components/Product";
import About from "../Components/About";
import Contact from "../Components/Contact";
import ForgotPassword from "../Authentication/ForgotPassword";
import VerifyPassword from "../Authentication/VerifyPassword";
import Login from "../Authentication/Login";
import Register from "../Authentication/Register";
import Faq from "../Components/Faq";
import Wishlist from "../Components/Wishlist";
import Cart from "../Components/Cart";
import Checkout from "../Components/Checkout";
import ProductDetails from "../Components/ProductDetails";
import MyAccount from "../Components/MyAccount";
import Orderdetails from "../Components/Orderdetails";
import ReviewTotal from "../Components/ReviewTotal";
import Address from "../Components/Address";
import UpdateProfile from "../Components/UpdateProfile";
import NotFound from "../Components/NotFound";
import SearchProducts from "../Components/SearchProducts";
import CategoryShop from "../Components/CategoryShop";
import OrderSuccess from "../Components/OrderSuccess";
import Orders from "../Components/Orders";
import UpdateAddress from "../Components/UpdateAddress";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import ReturnExchange from "../Components/ReturnExchange";
import ReturnRefund from "../Components/ReturnRefund";
import ShippingPolicy from "../Components/ShippingPolicy";
import TermsAndCondition from "../Components/TermsAndCondition";
import CartAddress from "../Components/CartAddress";
import PaymentStatus from "../Components/PaymentStatus";

const MainRoutes = () => {
  return (
    <div>
      <Router>
        <ActivePage />
        <ScrollToTop />
        <ActivePageContext.Provider value={ActivePathName}>
          <Header />
          <Routes>
            <Route exact path={"/"} element={<Home />} />
            <Route path={"categories"} element={<Categories />} />
            <Route path={"product"} element={<Product />} />
            <Route path={"productdetails/:id"} element={<ProductDetails />} />
            <Route path={"about"} element={<About />} />
            <Route path={"faq"} element={<Faq />} />
            <Route path={"cart"} element={<Cart />} />
            <Route path={"checkout"} element={<Checkout />} />
            <Route path={"category"} element={<Categories />} />
            <Route path={"categoryshop/:id"} element={<CategoryShop />} />
            <Route path={"wishlist"} element={<Wishlist />} />
            <Route path={"orderdetails"} element={<Orderdetails />} />
            <Route path={"ordersuccess"} element={<OrderSuccess />} />
            <Route path={"orders"} element={<Orders />} />
            <Route path={"reviewtotal"} element={<ReviewTotal />} />
            <Route path={"address"} element={<Address />} />
            <Route path={"cartaddress"} element={<CartAddress />} />
            <Route path={"updateprofile/:id"} element={<UpdateProfile />} />
            <Route path={"updateaddress/:id"} element={<UpdateAddress />} />
            <Route path={"checkout"} element={<Checkout />} />
            <Route path="/paymentstatus" element={<PaymentStatus />} />
            <Route path={"category"} element={<Categories />} />
            <Route path={"searchproducts/:id"} element={<SearchProducts />} />
            <Route path={"wishlist"} element={<Wishlist />} />
            <Route path={"orderdetails/:id"} element={<Orderdetails />} />
            <Route path={"reviewtotal"} element={<ReviewTotal />} />
            <Route path={"reviewtotal"} element={<ReviewTotal />} />
            <Route path={"contact"} element={<Contact />} />
            <Route path={"privacypolicy"} element={<PrivacyPolicy />} />
            <Route path={"returnexchange"} element={<ReturnExchange />} />
            <Route path={"returnrefund"} element={<ReturnRefund />} />
            <Route path={"shippingpolicy"} element={<ShippingPolicy />} />
            <Route path={"termsandcondition"} element={<TermsAndCondition />} />
            <Route path={"myaccount"} element={<MyAccount />} />
            <Route path={"login"} element={<Login />} />
            <Route path={"register"} element={<Register />} />
            <Route path={"forgotpassword"} element={<ForgotPassword />} />
            <Route path={"verifypassword/:id"} element={<VerifyPassword />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </ActivePageContext.Provider>
      </Router>
    </div>
  );
};

export default MainRoutes;
