import { AiOutlineHome } from "react-icons/ai";

import React, { useState, useEffect } from "react";
import { myReviews, deleteReviews } from "../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import reviews from "../Assets/reviews found.svg";

// import ReviewModal from "./ReviewModal";

const ReviewTotal = () => {
  const navigate = useNavigate();

  const [load, setLoad] = useState(true);
  const [show, setShow] = useState(true);
  const [render, setRender] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(myReviews())
      .then((res) => {
        setLoad(false);
      })
      .catch(() => {});
  }, [render]);

  const myReview = useSelector(({ myReviews }) => myReviews.payload);
  const handlereview = (G) => {
    dispatch(
      deleteReviews({
        action: "delete",
        id: G.id,
        rating: G.rating,
        review: G.review,
      })
    );
    setRender(!render);
  };

  return (
    <div>
      <div className="section">
        <div className="breadcrumb-area bg-primary">
          <div className="container">
            <div className="breadcrumb-content">
              <ul>
                <li>
                  <Link to={"/"}>
                    <i className="fa fa-home"></i>{" "}
                  </Link>
                </li>
                <li className="active"> Order tracking</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <section className="my-account-area section-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-8  py-5">
              <div className="tab-content" id="nav-tabContent">
                <div className="myaccount-content ">
                  <h3>Orders</h3>
                  <div className="myaccount-table table-responsive text-center">
                    <table className="table table-bordered">
                      {Array.isArray(myReview) ? (
                        <thead className="thead-light">
                          <tr>
                            <th>S.No</th>
                            <th>Produc Name</th>
                            <th>Product Image</th>

                            <th>Review</th>
                            <th>Rating</th>
                          </tr>
                        </thead>
                      ) : (
                        <h3 className="pt-5 pb-5">
                          {" "}
                          <center>
                            <img
                              src={reviews}
                              className="mt-5 mb-5 pb-5 pt-5"
                              style={{ width: "25%" }}
                            />
                            <h3> No Reviews Found</h3>{" "}
                          </center>
                        </h3>
                      )}
                      <tbody>
                        {Array.isArray(myReview) && myReview.length !== 0
                          ? myReview?.map((data, i) => (
                              <tr key={i}>
                                <td style={{ verticalAlign: "middle" }}>
                                  {i + 1}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  <h5>
                                    <a style={{ textTransform: "capitalize" }}>
                                      {data.productName}
                                    </a>
                                  </h5>
                                </td>
                                <td>
                                  <a>
                                    <img
                                      src={
                                        process.env.REACT_APP_URL +
                                        data.variantImage
                                      }
                                      alt=""
                                      style={{ maxHeight: "100px" }}
                                    />
                                  </a>
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  <h5>
                                    <a style={{ textTransform: "capitalize" }}>
                                      {data?.review}
                                    </a>
                                  </h5>
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    color: "#071C1F",
                                    fontWeight: "700",
                                  }}
                                >
                                  {data?.rating}
                                </td>
                              </tr>
                            ))
                          : false}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "105px",
        }}
        className="py-5"
      >
        <Link to={"/product"} className="btn btn-primary btn-hover-dark">
          Keep Shopping
        </Link>
      </div>
    </div>
  );
};

export default ReviewTotal;
